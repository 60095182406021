import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FormRow,
  AddItemLocation,
  AddItemLocationContainer,
  LocationContainer,
  AddItemCategory,
  AddItemCategoryContainer,
  CategoryContainer,
  AddItemType,
  AddItemTypeContainer,
  TypeContainer,
  AddItemKitchen,
  AddItemKitchenContainer,
  KitchenContainer,
  FormRowSelect,
  LinkedAccountsSection, // Import the new component
} from "../../components";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Profile";

const Profile = () => {
  const {
    user,
    planId,
    subscription,
    displayAlert,
    updateUser,
    isLoading,
    getItemLocations,
    getItemCategories,
    getItemTypes,
    getItemKitchens,
    createCheckoutSession,
    accessStripePortal,
    verifyStripeSession,
    checkSubscription,
    getLinkedAccounts,
  } = useAppContext();

  const { t, i18n } = useTranslation();
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [lastName, setLastName] = useState(user?.lastName);
  const [language, setLanguage] = useState(user?.language);

  const [selectedPlan, setSelectedPlan] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get("session_id");
  const subscriptionUpdated = query.get("subscription_updated");

  useEffect(() => {
    if (sessionId) {
      verifyStripeSession(sessionId);
      navigate("/profile");
    }
  }, [sessionId]);

  useEffect(() => {
    if (subscriptionUpdated) {
      checkSubscription();
      navigate("/profile");
    }
  }, [subscriptionUpdated]);

  // Fetch linked accounts if user is admin
  useEffect(() => {
    if (user?.isAdmin) {
      getLinkedAccounts();
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !lastName) {
      displayAlert();
      return;
    }

    updateUser({ name, email, lastName, language });
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language);
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
  };

  useEffect(() => {
    getItemLocations();
    getItemCategories();
    getItemTypes();
    getItemKitchens();
  }, []);

  const handleCreateCheckout = () => {
    createCheckoutSession(selectedPlan);
  };

  const handlePortalAccess = () => {
    accessStripePortal();
  };

  const plans = [
    { name: "Basic", stripePriceId: "price_1PBKkb2Lb6tX2weRgJ0uS74y" },
    { name: "Standard", stripePriceId: "price_1PBKkV2Lb6tX2weR8aBKvWMg" },
  ];

  const getPlanName = (planId) => {
    const plan = plans.find((p) => p.stripePriceId === planId);
    return plan ? t(plan.name) : t("No Plan Selected");
  };

  const languageOptions = [
    { label: t("English"), value: "en" },
    { label: t("Français"), value: "fr" },
  ];

  // First, add a check to determine if the current user is a linked account
  const isLinkedAccount =
    user?.parentAccount !== null && user?.parentAccount !== undefined;

  return (
    <Wrapper>
      {!isLinkedAccount && (
        <form
          className="form"
          style={{ marginTop: "0.75rem" }}
          onSubmit={handleSubmit}
        >
          <h3>{t("Profile")}</h3>
          <div className="form-center">
            <FormRow
              type="text"
              name="name"
              value={name}
              handleChange={(e) => setName(e.target.value)}
              labelText={t("Name")}
            />
            <FormRow
              type="email"
              name="email"
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
              labelText={t("Email")}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormRowSelect
                name="language"
                labelText={t("Language")}
                value={language}
                handleChange={handleLanguageChange}
                list={languageOptions.map((option) => option.value)}
                options={languageOptions}
              />
              <button
                style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? t("Please Wait...") : t("Save Changes")}
              </button>
            </div>
          </div>
        </form>
      )}

      {/* Modify the subscription section to be conditional */}
      {!isLinkedAccount && (
        <section
          className="form subscription-section"
          style={{ marginTop: "0.75rem" }}
        >
          <div className="section-header">
            <h4>{t("Subscription Details")}</h4>
          </div>

          <div className="subscription-info">
            <div className="subscription-status">
              <div className="status-item">
                <span className="status-label">{t("Plan")}</span>
                <span className="status-value">{getPlanName(planId)}</span>
              </div>
              <div className="status-item">
                <span className="status-label">{t("Status")}</span>
                <span className={`status-value status-${subscription}`}>
                  {t(subscription)}
                </span>
              </div>
            </div>

            {subscription === "active" ? (
              <div className="subscription-actions">
                <button
                  className="btn btn-green subscription-btn"
                  onClick={handlePortalAccess}
                >
                  {t("Manage Subscription")}
                </button>
              </div>
            ) : (
              <div className="plan-selection">
                <h5>{t("Select a Plan")}</h5>
                <div className="plan-options">
                  {plans.map((plan) => (
                    <div
                      key={plan.stripePriceId}
                      className={`plan-option ${
                        selectedPlan === plan.stripePriceId ? "selected" : ""
                      }`}
                      onClick={() =>
                        !isLoading && setSelectedPlan(plan.stripePriceId)
                      }
                    >
                      <input
                        type="radio"
                        id={plan.stripePriceId}
                        name="plan"
                        value={plan.stripePriceId}
                        checked={selectedPlan === plan.stripePriceId}
                        onChange={() => setSelectedPlan(plan.stripePriceId)}
                        disabled={isLoading}
                        className="plan-radio"
                      />
                      <label
                        htmlFor={plan.stripePriceId}
                        className="plan-label"
                      >
                        {t(plan.name)}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="subscription-actions">
                  <button
                    className="btn btn-primary subscription-btn"
                    onClick={handleCreateCheckout}
                    disabled={!selectedPlan}
                  >
                    {t("Choose Plan")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      {/* Linked Accounts Section - Only visible to admin users */}
      {user?.isAdmin && <LinkedAccountsSection />}

      <div className="profile-location">
        <h3>{t("Item Locations")}</h3>
        <LocationContainer />
        <div className="profile-location-add">
          <AddItemLocationContainer />
          <AddItemLocation />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Item Categories")}</h3>
        <CategoryContainer />
        <div className="profile-location-add">
          <AddItemCategoryContainer />
          <AddItemCategory />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Item Types")}</h3>
        <TypeContainer />
        <div className="profile-location-add">
          <AddItemTypeContainer />
          <AddItemType />
        </div>
      </div>
      <div className="profile-location">
        <h3>{t("Kitchens")}</h3>
        <KitchenContainer />
        <div className="profile-location-add">
          <AddItemKitchenContainer />
          <AddItemKitchen />
        </div>
      </div>
    </Wrapper>
  );
};

export default Profile;

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 1rem;

  .alternatives-title {
    font-size: 1rem;
    color: var(--grey-600);
    margin: 0 0 0.75rem;
  }

  .alternatives-list {
    background: var(--grey-50);
    border: 1px dashed var(--grey-300);
    border-radius: 8px;
    padding: 0.75rem;
  }
`;

export default Wrapper;

import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const PriceHistory = ({ priceHistory, onClose }) => {
  const { t } = useTranslation();
  const [visibleEntries, setVisibleEntries] = useState(10);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setVisibleEntries((prev) => prev + 10);
    }
  };

  return (
    <div className="price-history-overlay">
      <div className="price-history-modal">
        <div
          style={{
            position: "relative",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        >
          <h3 style={{ marginRight: "1rem" }}>{t("Price History")}</h3>
          <button
            className="btn btn-danger"
            type="button"
            style={{
              position: "absolute",
              right: 0,
              top: "1.25rem",
              transform: "translateY(-50%)",
              width: "2rem",
              height: "2rem",
              padding: "0.5rem",
            }}
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>
        <div className="table-container" onScroll={handleScroll}>
          <table>
            <thead>
              <tr>
                <th>{t("Price")}</th>
                <th>{t("Date")}</th>
              </tr>
            </thead>
            <tbody>
              {priceHistory
                .slice()
                .reverse()
                .slice(0, visibleEntries)
                .map((entry, index) => (
                  <tr key={index}>
                    <td>${entry.price.toFixed(2)}</td>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PriceHistory;

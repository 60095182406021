import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Alternative";
import { FaTimes } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Alternative = ({ item }) => {
  const { t } = useTranslation();
  const { items, editItemId, removeItemAlternative, setEditItem, providers } =
    useAppContext();

  const actualItem = items.find((globalItem) => globalItem._id === item._id);
  const displayItem = actualItem || item;
  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );
  const providerName = selectedProvider ? selectedProvider.providerName : "";
  const currentEditingItem = items.find((item) => item._id === editItemId);

  return (
    <Wrapper>
      <div className={`alternative-item ${item.isBackordered ? "active" : ""}`}>
        <div className="alternative-content">
          <div className="alternative-info">
            <h5>{displayItem.itemName}</h5>
            <div className="alternative-details">
              <span>{displayItem.itemSupplier}</span>
              {providerName && <span>{providerName}</span>}
            </div>
          </div>

          <div className="alternative-actions">
            {/* {actualItem && (
              <button
                type="button"
                className="alt-btn edit-btn"
                onClick={() => setEditItem(item._id)}
              >
                <MdEdit />
              </button>
            )} */}

            {!actualItem && (
              <span className="unavailable-msg">{t("Unavailable")}</span>
            )}

            {currentEditingItem &&
              currentEditingItem.selectedAlternatives &&
              currentEditingItem.selectedAlternatives.length > 0 && (
                <button
                  type="button"
                  className="alt-btn remove-btn"
                  onClick={() => removeItemAlternative(item)}
                >
                  <FaTimes />
                </button>
              )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Alternative;

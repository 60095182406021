import React from "react";
import { useAppContext } from "../context/appContext";

const PermissionGuard = ({ permission, fallback = null, children }) => {
  const { user } = useAppContext();

  // Admin always has all permissions
  if (user?.isAdmin) {
    return <>{children}</>;
  }

  // Check the requested permission
  if (user?.permissions && user?.permissions[permission]) {
    return <>{children}</>;
  }

  return fallback;
};

export default PermissionGuard;

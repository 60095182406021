import styled from "styled-components";

const Wrapper = styled.div`
  background: var(--white);
  border-radius: 10px;
  box-shadow: var(--shadow-2);
  padding: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--shadow-3);
  }
  //menus
  .menu-header {
    display: flex;
    flex-direction: column; /* Stack title and content vertically */
    gap: 0.25rem;
    cursor: pointer;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-header h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .menu-header h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Allow up to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 1.4; /* Adjust for better line spacing */
  }
  .menu-header h2,
  .menu-header h4 {
    margin: 0;
    white-space: nowrap; /* Prevent text from wrapping unnecessarily */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if content overflows */
  }
  .menu-content {
    display: flex;
    align-items: flex-end; /* Align the button with the bottom of the description */
    justify-content: space-between;
    gap: 0.5rem;
  }
  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    background: var(--green-light);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin: 0.5rem;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:hover {
      background: var(--green-dark);
    }
  }

  @media (max-width: 600px) {
    .menu-content {
      align-items: flex-end; /* Keep button aligned with last line of description */
    }

    .menu-header h2 {
      font-size: 1.25rem;
    }

    .menu-header h4 {
      font-size: 0.9rem;
      -webkit-line-clamp: 2;
    }
  }
`;

export default Wrapper;

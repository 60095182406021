import { useAppContext } from "../../context/appContext";
import { useState, useLayoutEffect, useMemo, useRef, useEffect } from "react";
import Wrapper from "../../assets/wrappers/ResetQuantities";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import { FaTimes, FaUndo, FaTrash } from "react-icons/fa";

const ResetQuantities = ({ filteredItems }) => {
  const { t } = useTranslation();
  const {
    isResettingQuantities,
    resetItemQuantities,
    toggleResetQuantities,
    itemBackup,
    getItemBackup,
    restoreItemQuantities,
  } = useAppContext();
  const [resetItemQuantity, setResetItemQuantity] = useState(true);
  const [resetInventoryQuantity, setResetInventoryQuantity] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (Object.keys(itemBackup).length === 0) {
      getItemBackup();
    }
  }, []);

  const itemsToReset = useMemo(() => {
    return filteredItems.filter((item) => {
      if (resetItemQuantity && resetInventoryQuantity) {
        return item.itemQuantity !== 0 || item.itemInventoryQuantity !== 0;
      } else if (resetItemQuantity) {
        return item.itemQuantity !== 0;
      } else if (resetInventoryQuantity) {
        return item.itemInventoryQuantity !== 0;
      }
      return false;
    });
  }, [filteredItems, resetItemQuantity, resetInventoryQuantity]);

  const handleResetQuantities = () => {
    if (itemsToReset.length > 0) {
      resetItemQuantities(
        itemsToReset,
        resetItemQuantity,
        resetInventoryQuantity
      );
      toggleResetQuantities();
    }
  };

  const handleRestoreClick = () => {
    restoreItemQuantities();
  };

  // Handle checkbox change with proper event stop
  const handleInventoryCheckboxChange = (e) => {
    e.stopPropagation(); // Stop event propagation
    setResetInventoryQuantity(!resetInventoryQuantity);
  };

  const handleOrderCheckboxChange = (e) => {
    e.stopPropagation(); // Stop event propagation
    setResetItemQuantity(!resetItemQuantity);
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 45);
    }

    const tl = gsap.timeline();

    if (isResettingQuantities) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 0 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isResettingQuantities, contentHeight]);

  if (!isVisible && !isResettingQuantities) {
    return null;
  }

  return (
    <Wrapper>
      <div ref={contentRef} className="reset-quantities-container">
        <div className="reset-header">
          <div className="title-container">
            <FaTrash className="title-icon" />
            <h4 className="reset-title">{t("Reset Quantities")}</h4>
          </div>
          <button
            className="btn delete-btn close-reset"
            onClick={toggleResetQuantities}
            title={t("Close")}
          >
            <FaTimes />
          </button>
        </div>

        <div className="reset-section">
          <h5 className="section-title">{t("Reset Options")}</h5>

          <div className="export-checks-container">
            <div
              className={`check-option ${
                resetInventoryQuantity ? "selected" : ""
              }`}
              onClick={() => setResetInventoryQuantity(!resetInventoryQuantity)}
            >
              <label
                className="checkbox-label"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  checked={resetInventoryQuantity}
                  onChange={handleInventoryCheckboxChange}
                />
                <span>{t("Reset Inventory Quantity")}</span>
              </label>
            </div>

            <div
              className={`check-option ${resetItemQuantity ? "selected" : ""}`}
              onClick={() => setResetItemQuantity(!resetItemQuantity)}
            >
              <label
                className="checkbox-label"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  checked={resetItemQuantity}
                  onChange={handleOrderCheckboxChange}
                />
                <span>{t("Reset Order Quantity")}</span>
              </label>
            </div>
          </div>
        </div>

        {itemBackup && itemBackup.updatedAt ? (
          <div className="backup-section">
            <div className="section-header">
              <h5 className="section-title">{t("Item Quantities Backup")}</h5>
              <span className="backup-date">
                {new Date(itemBackup.updatedAt).toLocaleString(undefined, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>

            <div className="backup-details">
              <div className="backup-info">
                <span className="info-label">
                  {t("Items in backup")}:
                  <span className="info-value">
                    {itemBackup.itemCount || 0}
                  </span>
                </span>
              </div>

              <button
                className="btn restore-button"
                onClick={handleRestoreClick}
                title={t("Restore from backup")}
              >
                <FaUndo /> {t("Restore Quantities")}
              </button>
            </div>
          </div>
        ) : (
          <p className="no-backup-message">{t("No backup data available.")}</p>
        )}

        <div className="export-confirm-container">
          <div className="item-count">
            <span>
              {t("Reset {{count}} items", {
                count: itemsToReset.length,
              })}
            </span>
          </div>
          <button
            className="btn btn-danger export-button"
            onClick={handleResetQuantities}
            disabled={
              itemsToReset.length === 0 ||
              (!resetItemQuantity && !resetInventoryQuantity)
            }
          >
            <FaTrash /> {t("Reset Quantities")}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ResetQuantities;

import { FormRow, FormRowSelect } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import IngredientList from "./IngredientList";
import MenuItemIngredientList from "./MenuItemIngredientList";

const AddMenuItem = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAddingMenuItem,
    isEditingMenuItem,
    editMenuItemId,
    displayAlert,
    menuItemName,
    menuItemPrice,
    menuItemNotes,
    menuItemOrders,
    menuItemError,
    menuItemImage,
    items,
    menuItems,
    alternativePairs,
    providers,
    menuItemIngredients,
    addRegularIngredient,
    addMenuItemIngredient,
    removeIngredientField,
    updateIngredientField,
    // searchMenuIngredients,
    // searchIngredients,
    // menuIngredients,
    itemAmountTypeOptions,
    handleChange,
    clearValues,
    createMenuItem,
    editMenuItem,
    deleteMenuItem,
  } = useAppContext();

  const { id: menuId } = useParams();
  const [currentDisplayValues, setCurrentDisplayValues] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState(null);

  const [localSearch, setLocalSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [menuItemLocalSearch, setMenuItemLocalSearch] = useState("");
  const [menuItemDebouncedSearch, setMenuItemDebouncedSearch] = useState("");

  const handleItemSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearch(value);
    setMenuItemLocalSearch("");
    setMenuItemDebouncedSearch("");
    setActiveMenuItemIndex(null);
  };

  const handleMenuItemSearchChange = (e) => {
    const value = e.target.value;
    setMenuItemLocalSearch(value);
    setLocalSearch("");
    setDebouncedSearch("");
    setActiveItemIndex(null);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearch(localSearch);
    }, 250);
    return () => clearTimeout(delay);
  }, [localSearch]);

  useEffect(() => {
    const delay = setTimeout(() => {
      setMenuItemDebouncedSearch(menuItemLocalSearch);
    }, 250);
    return () => clearTimeout(delay);
  }, [menuItemLocalSearch]);

  const filteredItems = useMemo(() => {
    if (debouncedSearch.length < 1) return [];
    return items.filter((it) =>
      it.itemName.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [items, debouncedSearch]);

  const filteredMenuItems = useMemo(() => {
    if (menuItemDebouncedSearch.length < 1) return [];
    return menuItems.filter((mi) =>
      mi.menuItemName
        .toLowerCase()
        .includes(menuItemDebouncedSearch.toLowerCase())
    );
  }, [menuItems, menuItemDebouncedSearch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!menuItemName) {
      displayAlert();
      return;
    }
    if (isEditingMenuItem) {
      editMenuItem(menuId);
      return;
    }
    createMenuItem(menuId);
  };

  const defaultIngredientAmountTypeOptions = ["ml", "L", "g", "Kg", "Oz", "lb"];
  const defaultMenuItemIngredientAmountTypeOptions = [
    "ml",
    "L",
    "g",
    "Kg",
    "Oz",
    "lb",
    "portion",
  ];
  const convertToBaseUnit = (quantity, type) => {
    switch (type) {
      case "L":
        return quantity * 1000;
      case "ml":
        return quantity;
      case "Kg":
        return quantity * 1000;
      case "g":
        return quantity;
      case "Oz":
        return quantity * 28.35;
      case "lb":
        return quantity * 453.592;
      case "unit":
        return quantity;
      default:
        return quantity;
    }
  };

  const calculateIngredientPrice = (ingredient) => {
    const item = items.find((item) => item._id === ingredient.item);

    if (!item) {
      return "Select Item";
    }

    if (item.itemAmountType === "Other") {
      return "Cannot calculate price for 'Other' type";
    }

    if (item.itemAmount === 0) {
      return "Set an itemAmount First";
    }

    if (item.price === 0) {
      return "No item price";
    }

    const ingredientBaseQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );
    const itemBaseAmount = convertToBaseUnit(
      item.itemAmount,
      item.itemAmountType
    );

    if (
      ingredientBaseQuantity === undefined ||
      itemBaseAmount === undefined ||
      itemBaseAmount === 0
    ) {
      return `Error: Ingredient or item amount type is '${ingredient.ingredientQType}' or '${item.itemAmountType}'`;
    }

    const baseCost = (ingredientBaseQuantity / itemBaseAmount) * item.price;
    const wasteFactor = 1 + ingredient.wastePercentage / 100;
    const costWithWaste = baseCost * wasteFactor;

    return costWithWaste.toFixed(3) + "$";
  };

  const handleMenuItemInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const getItemNameFromId = (itemId) => {
    const foundItem = items.find((item) => item._id === itemId);
    return foundItem ? foundItem.itemName : "";
  };

  const isItemValid = (inputValue) => {
    return items.some((item) => item.itemName === inputValue);
  };
  const isMenuItemValid = (inputValue) => {
    return menuItems.some((menuItem) => menuItem.menuItemName === inputValue);
  };

  const isBaseItemTypeLocked = (ingredientId) => {
    const item = items.find((item) => item._id === ingredientId);
    return item?.itemAmountType === "unit" || item?.itemAmountType === "Other";
  };

  const areAllIngredientsValid = () => {
    return menuItemIngredients.every((ingredient) => {
      if (ingredient.isMenuItem) {
        return menuItems.some(
          (menuItem) => menuItem._id === ingredient.menuItem
        );
      } else {
        return items.some((item) => item._id === ingredient.item);
      }
    });
  };

  const handleRegularIngredientChange = (index, field, value) => {
    const regularIngredients = menuItemIngredients.filter(
      (ing) => !ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        !ing.isMenuItem &&
        regularIngredients.findIndex((_, ri) => ri === index) ===
          regularIngredients.indexOf(ing)
    );

    const ingredient = menuItemIngredients[actualIndex];
    const item = items.find((item) => item._id === ingredient.item);
    let updatedField = { [field]: value };

    if (field === "ingredientQType" && item && item.itemAmountType === "unit") {
      updatedField = { [field]: "unit" };
    }

    updateIngredientField(actualIndex, updatedField);
  };

  const handleMenuItemIngredientChange = (index, field, value) => {
    const menuItemsIngredients = menuItemIngredients.filter(
      (ing) => ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        ing.isMenuItem &&
        menuItemsIngredients.findIndex((_, mi) => mi === index) ===
          menuItemsIngredients.indexOf(ing)
    );

    updateIngredientField(actualIndex, { [field]: value });
  };

  const handleSelectRegularIngredient = (itemId, index) => {
    const regularIngredients = menuItemIngredients.filter(
      (ing) => !ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        !ing.isMenuItem &&
        regularIngredients.findIndex((_, ri) => ri === index) ===
          regularIngredients.indexOf(ing)
    );

    const newIngredient = items.find((ing) => ing._id === itemId);
    if (newIngredient) {
      const updatedField = {
        item: itemId,
        menuItem: null,
        name: newIngredient.itemName,
        isMenuItem: false,
        ingredientQType:
          newIngredient.itemAmountType === "unit"
            ? "unit"
            : newIngredient.itemAmountType === "Other"
            ? "Other"
            : "ml",
      };
      updateIngredientField(actualIndex, updatedField);

      const updatedDisplayValues = [...currentDisplayValues];
      updatedDisplayValues[actualIndex] = newIngredient.itemName;
      setCurrentDisplayValues(updatedDisplayValues);
      setLocalSearch("");
      setDebouncedSearch("");
      setActiveItemIndex(null);
    }
  };

  const handleSelectMenuItemIngredient = (menuItemId, index) => {
    const menuItemsIngredients = menuItemIngredients.filter(
      (ing) => ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        ing.isMenuItem &&
        menuItemsIngredients.findIndex((_, mi) => mi === index) ===
          menuItemsIngredients.indexOf(ing)
    );

    const newMenuItem = menuItems.find((mi) => mi._id === menuItemId);
    if (newMenuItem) {
      const updatedField = {
        menuItem: menuItemId,
        item: null,
        name: newMenuItem.menuItemName,
        isMenuItem: true,
        ingredientQuantity: 1,
        ingredientQType: "unit",
        wastePercentage: 0,
      };
      updateIngredientField(actualIndex, updatedField);
      setMenuItemLocalSearch("");
      setMenuItemDebouncedSearch("");
      setActiveMenuItemIndex(null);
    }
  };

  const handleRegularIngredientUpdate = (index, updatedField) => {
    const regularIngredients = menuItemIngredients.filter(
      (ing) => !ing.isMenuItem
    );
    const menuItemsIngredients = menuItemIngredients.filter(
      (ing) => ing.isMenuItem
    );

    // Get the actual index in the full array
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        !ing.isMenuItem &&
        regularIngredients.findIndex((_, ri) => ri === index) ===
          regularIngredients.indexOf(ing)
    );

    updateIngredientField(actualIndex, {
      ...updatedField,
      isMenuItem: false,
    });
  };

  const handleMenuItemIngredientUpdate = (index, updatedField) => {
    const menuItemsIngredients = menuItemIngredients.filter(
      (ing) => ing.isMenuItem
    );

    // Get the actual index in the full array
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        ing.isMenuItem &&
        menuItemsIngredients.findIndex((_, mi) => mi === index) ===
          menuItemsIngredients.indexOf(ing)
    );

    updateIngredientField(actualIndex, {
      ...updatedField,
      isMenuItem: true,
    });
  };

  const handleRegularIngredientRemove = (index) => {
    const regularIngredients = menuItemIngredients.filter(
      (ing) => !ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        !ing.isMenuItem &&
        regularIngredients.findIndex((_, ri) => ri === index) ===
          regularIngredients.indexOf(ing)
    );
    removeIngredientField(actualIndex);
  };

  const handleMenuItemIngredientRemove = (index) => {
    const menuItemsIngredients = menuItemIngredients.filter(
      (ing) => ing.isMenuItem
    );
    const actualIndex = menuItemIngredients.findIndex(
      (ing, i) =>
        ing.isMenuItem &&
        menuItemsIngredients.findIndex((_, mi) => mi === index) ===
          menuItemsIngredients.indexOf(ing)
    );
    removeIngredientField(actualIndex);
  };

  const handleClearValues = (e) => {
    e.preventDefault();
    // Reset all search states
    setLocalSearch("");
    setDebouncedSearch("");
    setMenuItemLocalSearch("");
    setMenuItemDebouncedSearch("");
    setActiveItemIndex(null);
    setActiveMenuItemIndex(null);
    clearValues();
  };

  useEffect(() => {
    const displayValues = menuItemIngredients.map((ing) =>
      getItemNameFromId(ing.item)
    );
    setCurrentDisplayValues(displayValues);
  }, [menuItemIngredients]);

  return (
    <Wrapper style={{ overflow: "visible" }}>
      {isAddingMenuItem && (
        <div className="edit-backdrop" onClick={handleClearValues}></div>
      )}
      <form
        className={`${isAddingMenuItem ? "form_active form_fixed" : "form"}`}
      >
        <div className="addItem-group">
          <h3>
            {isEditingMenuItem ? t("edit menu item") : t("add menu item")}
          </h3>

          <button
            className="btn btn-danger"
            type="button"
            style={{
              width: "2rem",
              height: "2rem",
              padding: "0.5rem 0.5rem",
              alignSelf: "flex-start",
            }}
            onClick={handleClearValues}
          >
            <FaTimes />
          </button>
        </div>
        <div className="form-center">
          <FormRow
            type="text"
            labelText={t("Name")}
            name="menuItemName"
            value={menuItemName}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="number"
            labelText={t("Dish cost")}
            name="menuItemPrice"
            value={menuItemPrice}
            handleChange={handleMenuItemInput}
          />

          <FormRow
            type="number"
            labelText={t("Number of Orders")}
            name="menuItemOrders"
            value={menuItemOrders}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="number"
            labelText={t("Error Percentage")}
            name="menuItemError"
            value={menuItemError}
            handleChange={handleMenuItemInput}
          />
          <FormRow
            type="text"
            labelText={t("Instructions")}
            name="menuItemNotes"
            value={menuItemNotes}
            handleChange={handleMenuItemInput}
          />
          {/* <FormRow
            type="text"
            labelText="Image Link"
            name="menuItemImage"
            value={menuItemImage}
            handleChange={handleMenuItemInput}
          /> */}
        </div>
        <h3 style={{ marginLeft: "0rem", margin: "0.5rem" }}>
          {t("ingredients")}
        </h3>
        <IngredientList
          menuItemIngredients={menuItemIngredients.filter(
            (ing) => !ing.isMenuItem
          )}
          items={items}
          menuItems={menuItems}
          alternativePairs={alternativePairs}
          providers={providers}
          defaultIngredientAmountTypeOptions={
            defaultIngredientAmountTypeOptions
          }
          currentDisplayValues={currentDisplayValues}
          activeIngredientIndex={activeItemIndex}
          setActiveIngredientIndex={setActiveItemIndex}
          filteredMenuIngredients={filteredItems}
          handleSearchChange={handleItemSearchChange}
          updateIngredientField={handleRegularIngredientUpdate}
          handleIngredientChange={handleRegularIngredientChange}
          handleSelectIngredient={handleSelectRegularIngredient}
          removeIngredientField={handleRegularIngredientRemove}
          calculateIngredientPrice={calculateIngredientPrice}
          isItemValid={isItemValid}
          isBaseItemTypeLocked={isBaseItemTypeLocked}
        />
        <MenuItemIngredientList
          menuItemIngredients={menuItemIngredients.filter(
            (ing) => ing.isMenuItem
          )}
          items={items}
          menuItems={menuItems}
          defaultIngredientAmountTypeOptions={
            defaultMenuItemIngredientAmountTypeOptions
          }
          activeIngredientIndex={activeMenuItemIndex}
          setActiveIngredientIndex={setActiveMenuItemIndex}
          filteredMenuIngredients={filteredMenuItems}
          handleSearchChange={handleMenuItemSearchChange}
          updateIngredientField={handleMenuItemIngredientUpdate}
          removeIngredientField={handleMenuItemIngredientRemove}
          handleIngredientChange={handleMenuItemIngredientChange}
          handleSelectIngredient={handleSelectMenuItemIngredient}
          isMenuItemValid={isMenuItemValid}
          currentEditMenuItemId={editMenuItemId} // Add this prop
        />
        <button
          type="button"
          className="btn edit-btn"
          onClick={addRegularIngredient}
          style={{ marginTop: "1rem" }}
        >
          {t("Add Item")}
        </button>
        <button
          type="button"
          className="btn edit-btn"
          onClick={addMenuItemIngredient}
          style={{ marginTop: "1rem", marginLeft: "1rem" }}
        >
          {t("Add MenuItem")}
        </button>
        <div
          className="btn-container"
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-block delete-btn"
            onClick={() => deleteMenuItem(editMenuItemId, menuId)}
          >
            {t("Delete")}
          </button>
          <button
            type="submit"
            className="btn btn-block submit-btn"
            onClick={handleSubmit}
            disabled={isLoading || !areAllIngredientsValid()}
          >
            {t("submit")}
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddMenuItem;

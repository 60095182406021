import styled from "styled-components";

const Wrapper = styled.article`
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .alternative-item {
    background: var(--white);
    border-radius: 8px;
    box-shadow: var(--shadow-1);
    transition: all 0.2s;

    &:hover {
      box-shadow: var(--shadow-2);
    }

    &.active {
      background-color: var(--grey-50);
    }
  }

  .alternative-content {
    padding: 0.6rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .alternative-info {
    flex: 1;
    min-width: 0; /* Enables text truncation */

    h5 {
      margin: 0 0 0.2rem;
      font-size: 0.9rem;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .alternative-details {
    display: flex;
    gap: 0.75rem;
    font-size: 0.8rem;

    span:first-child {
      color: var(--primary-500);
      font-weight: 500;
    }

    span:last-child {
      color: var(--green-dark);
    }
  }

  .alternative-actions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .alt-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    height: 1.85rem;
    width: 1.85rem;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
  }

  .edit-btn {
    background: var(--grey-100);

    &:hover {
      background: var(--grey-200);
      color: var(--grey-700);
    }
  }

  .add-btn {
    background: var(--green-light);
    color: var(--white);
    &:hover {
      background: var(--green-dark);
      color: var(--white);
    }
  }

  .remove-btn {
    background: var(--red-light);
    color: var(--white);

    &:hover {
      background: var(--red-dark);
      color: var(--white);
    }
  }

  .unavailable-msg {
    font-size: 0.8rem;
    font-style: italic;
    color: var(--red-dark);
    background: var(--red-50);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }
`;

export default Wrapper;

import { useState } from "react";
import { FormRow, FormRowSelect } from "./index";
import { useAppContext } from "../context/appContext";
import { useTranslation } from "react-i18next";
import Wrapper from "../assets/wrappers/LinkedAccounts";
import {
  FaUserPlus,
  FaEdit,
  FaTrash,
  FaCheck,
  FaTimes,
  FaUserShield,
  FaExclamationTriangle,
  FaInfoCircle,
  FaLanguage,
} from "react-icons/fa";

const LinkedAccountsSection = () => {
  const {
    isLoading,
    linkedAccounts,
    createLinkedAccount,
    updateLinkedAccount,
    deleteLinkedAccount,
    displayAlert,
  } = useAppContext();

  const { t } = useTranslation();

  // Linked accounts state
  const [showLinkedAccountForm, setShowLinkedAccountForm] = useState(false);
  const [linkedAccountName, setLinkedAccountName] = useState("");
  const [linkedAccountEmail, setLinkedAccountEmail] = useState("");
  const [linkedAccountPassword, setLinkedAccountPassword] = useState("");
  const [editingLinkedAccountId, setEditingLinkedAccountId] = useState(null);
  const [linkedAccountLanguage, setLinkedAccountLanguage] = useState("en"); // Default to English

  // Add language options
  const languageOptions = [
    { label: t("English"), value: "en" },
    { label: t("Français"), value: "fr" },
  ];

  // Add confirmation modal state
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState(null);

  // Define max accounts limit
  const MAX_LINKED_ACCOUNTS = 3;
  const remainingAccounts = MAX_LINKED_ACCOUNTS - (linkedAccounts?.length || 0);
  const canAddMoreAccounts = remainingAccounts > 0;

  const [linkedAccountPermissions, setLinkedAccountPermissions] = useState({
    // Dashboard permissions
    viewDashboard: true,

    // Order permissions
    viewOrders: true,
    editOrders: false,

    // Item permissions
    editItems: false,
    updateItemQuantities: true,
    viewItemPrices: false,

    // Provider permissions
    viewProviders: true,
    editProviders: false,

    // Menu permissions
    viewMenus: true,
    editMenus: false,

    // Configuration permissions
    editItemCategories: false,
    editItemLocations: false,
    editItemTypes: false,
    editKitchens: false,

    // Admin permissions - always false for linked accounts
    adminSettings: false,
  });

  // Group permissions by category for compact display
  const permissionCategories = [
    {
      name: "Dashboard",
      permissions: ["viewDashboard"],
    },
    {
      name: "Orders",
      permissions: ["viewOrders", "editOrders"],
    },
    {
      name: "Items",
      permissions: [
        //   "viewItems",
        "editItems",
        "updateItemQuantities",
        "viewItemPrices",
      ],
    },
    {
      name: "Providers",
      permissions: ["viewProviders", "editProviders"],
    },
    {
      name: "Menus",
      permissions: ["viewMenus", "editMenus"],
    },
    {
      name: "Configuration",
      permissions: [
        "editItemCategories",
        "editItemLocations",
        "editItemTypes",
        "editKitchens",
      ],
    },
  ];

  // Add these helper functions to improve UI clarity:
  const getPermissionDisplayName = (permission) => {
    const displayNames = {
      // Dashboard permissions
      viewDashboard: t("View Dashboard"),

      // Order permissions
      viewOrders: t("View Orders"),
      editOrders: t("Edit Orders"),

      // Item permissions
      editItems: t("Edit Items"),
      updateItemQuantities: t("Update Quantities"),
      viewItemPrices: t("View Prices"),

      // Provider permissions
      viewProviders: t("View Providers"),
      editProviders: t("Edit Providers"),

      // Menu permissions
      viewMenus: t("View Menus"),
      editMenus: t("Edit Menus"),

      // Configuration permissions
      editItemCategories: t("Edit Categories"),
      editItemLocations: t("Edit Locations"),
      editItemTypes: t("Edit Types"),
      editKitchens: t("Edit Kitchens"),
    };

    return displayNames[permission] || t(permission);
  };

  // Add tooltips to explain permission dependencies
  const getPermissionTooltip = (permission) => {
    const tooltips = {
      // Dashboard permissions
      viewDashboard: t(
        "Access to view dashboard (requires View Orders permission)"
      ),

      // Items
      editItems: t(
        "Edit item details (automatically enables View Prices and Update Quantities)"
      ),
      updateItemQuantities: t(
        "Update order and inventory quantities for items"
      ),
      viewItemPrices: t("View item prices in lists and forms"),

      // Orders
      viewOrders: t("Access to view orders"),
      editOrders: t("Edit and create orders (requires View Orders permission)"),

      // Menus
      viewMenus: t("Access to view menus"),
      editMenus: t("Edit and create menus (requires View Menus permission)"),

      // Providers
      viewProviders: t("Access to view providers"),
      editProviders: t(
        "Edit and create providers (requires View Providers permission)"
      ),
    };

    return tooltips[permission] || getPermissionDisplayName(permission);
  };

  // Helper function to check if a permission is dependent on another
  const isDependentPermission = (permission) => {
    return (
      // Both viewItemPrices and updateItemQuantities are dependent on editItems
      (linkedAccountPermissions.editItems &&
        (permission === "viewItemPrices" ||
          permission === "updateItemQuantities")) ||
      // Order dependencies
      (permission === "editOrders" &&
        !linkedAccountPermissions.viewOrders === false) ||
      // Dashboard depends on viewOrders
      (permission === "viewDashboard" &&
        !linkedAccountPermissions.viewOrders) ||
      // Menu dependencies
      (permission === "editMenus" &&
        !linkedAccountPermissions.viewMenus === false) ||
      // Provider dependencies
      (permission === "editProviders" &&
        !linkedAccountPermissions.viewProviders === false)
    );
  };

  // Helper function to get language display name
  const getLanguageDisplayName = (langCode) => {
    const language = languageOptions.find(
      (option) => option.value === langCode
    );
    return language ? language.label : langCode;
  };

  const handleLinkedAccountSubmit = (e) => {
    e.preventDefault();

    if (
      !linkedAccountName ||
      !linkedAccountEmail ||
      (!editingLinkedAccountId && !linkedAccountPassword)
    ) {
      displayAlert();
      return;
    }

    const accountData = {
      name: linkedAccountName,
      email: linkedAccountEmail,
      lastName: linkedAccountName, // Using name as lastName for simplicity
      permissions: linkedAccountPermissions,
      language: linkedAccountLanguage, // Add language to account data
    };

    if (!editingLinkedAccountId) {
      // Creating new account
      accountData.password = linkedAccountPassword;
      createLinkedAccount(accountData);
    } else {
      // Updating existing account
      updateLinkedAccount(editingLinkedAccountId, accountData);
      setEditingLinkedAccountId(null);
    }

    // Reset form
    setLinkedAccountName("");
    setLinkedAccountEmail("");
    setLinkedAccountPassword("");
    setLinkedAccountLanguage("en"); // Reset language
    resetPermissions();
    setShowLinkedAccountForm(false);
  };

  const resetPermissions = () => {
    setLinkedAccountPermissions({
      // Dashboard permissions
      viewDashboard: true,

      // Order permissions
      viewOrders: true,
      editOrders: false,

      // Item permissions
      //viewItems: true,
      editItems: false,
      updateItemQuantities: true,
      viewItemPrices: false,

      // Provider permissions
      viewProviders: true,
      editProviders: false,

      // Menu permissions
      viewMenus: true,
      editMenus: false,

      // Configuration permissions
      editItemCategories: false,
      editItemLocations: false,
      editItemTypes: false,
      editKitchens: false,

      // Admin permissions
      adminSettings: false,
    });
  };

  const handleEditLinkedAccount = (account) => {
    setLinkedAccountName(account.name);
    setLinkedAccountEmail(account.email);
    setLinkedAccountPermissions(account.permissions || {}); // Add a fallback for older accounts
    setLinkedAccountLanguage(account.language || "en"); // Set language with fallback
    setEditingLinkedAccountId(account._id);
    setShowLinkedAccountForm(true);
  };

  // Modified delete handler to show the modal instead of using window.confirm
  const handleDeleteLinkedAccount = (id, name) => {
    setAccountToDelete({ id, name });
    setShowDeleteModal(true);
  };

  // New function to confirm deletion
  const confirmDeleteAccount = () => {
    if (accountToDelete) {
      deleteLinkedAccount(accountToDelete.id);
      setShowDeleteModal(false);
      setAccountToDelete(null);
    }
  };

  // New function to cancel deletion
  const cancelDeleteAccount = () => {
    setShowDeleteModal(false);
    setAccountToDelete(null);
  };

  // Modify the handleTogglePermission function:
  const handleTogglePermission = (permission) => {
    // First, get the new permission value
    const newValue = !linkedAccountPermissions[permission];

    // Create a new permissions object to modify
    const updatedPermissions = {
      ...linkedAccountPermissions,
      [permission]: newValue,
    };

    // Handle permission dependencies
    if (permission === "editItems") {
      if (newValue) {
        // If turning on editItems, also turn on both viewItemPrices AND updateItemQuantities
        updatedPermissions.viewItemPrices = true;
        updatedPermissions.updateItemQuantities = true;
      }
    }

    // Orders dependencies
    if (permission === "editOrders" && newValue) {
      updatedPermissions.viewOrders = true;
    }
    if (permission === "viewOrders" && !newValue) {
      updatedPermissions.editOrders = false;
      // NEW: If we turn off viewOrders, also turn off viewDashboard
      updatedPermissions.viewDashboard = false;
    }
    // NEW: If we turn on viewOrders, don't automatically turn on viewDashboard
    // This allows viewDashboard to be toggled independently as long as viewOrders is on

    // Menus dependencies
    if (permission === "editMenus" && newValue) {
      updatedPermissions.viewMenus = true;
    }
    if (permission === "viewMenus" && !newValue) {
      updatedPermissions.editMenus = false;
    }

    // Providers dependencies
    if (permission === "editProviders" && newValue) {
      updatedPermissions.viewProviders = true;
    }
    if (permission === "viewProviders" && !newValue) {
      updatedPermissions.editProviders = false;
    }

    // NEW: Dashboard dependencies
    if (permission === "viewDashboard" && newValue) {
      // If turning on viewDashboard, ensure viewOrders is also on
      updatedPermissions.viewOrders = true;
    }

    setLinkedAccountPermissions(updatedPermissions);
  };

  const handleCancel = () => {
    setShowLinkedAccountForm(false);
    setEditingLinkedAccountId(null);
    setLinkedAccountName("");
    setLinkedAccountEmail("");
    setLinkedAccountPassword("");
    resetPermissions();
  };

  return (
    <Wrapper>
      <div className="section-header">
        <div className="title-container">
          <h4 className="section-title">{t("Linked Accounts")}</h4>
          <div className="accounts-limit">
            <FaInfoCircle className="info-icon" />
            <span>
              {t("{{remaining}}/{{total}} accounts", {
                remaining: remainingAccounts < 0 ? 0 : remainingAccounts,
                total: MAX_LINKED_ACCOUNTS,
              })}
            </span>
          </div>
        </div>
        {!showLinkedAccountForm && (
          <button
            className="btn add-btn"
            onClick={() => setShowLinkedAccountForm(true)}
            disabled={!canAddMoreAccounts}
            title={
              !canAddMoreAccounts
                ? t("Maximum linked accounts limit reached")
                : ""
            }
          >
            <FaUserPlus /> {t("Add Account")}
          </button>
        )}
      </div>

      {/* Show account limit reached message if needed */}
      {!canAddMoreAccounts && !showLinkedAccountForm && (
        <div className="limit-message">
          <FaExclamationTriangle className="warning-icon" />
          <p>
            {t(
              "You have reached the maximum limit of {{limit}} linked accounts.",
              { limit: MAX_LINKED_ACCOUNTS }
            )}
          </p>
        </div>
      )}

      {/* List of linked accounts */}
      {linkedAccounts && linkedAccounts.length > 0 ? (
        <div className="accounts-list">
          {linkedAccounts.map((account) => (
            <div key={account._id} className="account-card">
              <div className="account-info">
                <div className="account-header">
                  <div className="account-identity">
                    <FaUserShield className="account-icon" />
                    <div>
                      <h5>{account.name}</h5>
                      <div className="account-details">
                        <span className="account-email">{account.email}</span>
                        {account.language && (
                          <span
                            className="account-language"
                            title={t("Selected Language")}
                          >
                            <FaLanguage />
                            {getLanguageDisplayName(account.language)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="account-actions">
                    <button
                      className="icon-btn edit-btn"
                      onClick={() => handleEditLinkedAccount(account)}
                      title={t("Edit")}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="icon-btn delete-btn"
                      onClick={() =>
                        handleDeleteLinkedAccount(account._id, account.name)
                      }
                      title={t("Delete")}
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>

                <div className="account-permissions">
                  {permissionCategories.map((category) => (
                    <div key={category.name} className="permission-category">
                      <div className="category-name">{t(category.name)}</div>
                      <div className="permission-pills">
                        {category.permissions.map((permission) => (
                          <span
                            key={permission}
                            className={`permission-pill ${
                              account.permissions?.[permission]
                                ? "active"
                                : "inactive"
                            }`}
                            title={getPermissionTooltip(permission)}
                          >
                            {account.permissions?.[permission] ? (
                              <FaCheck className="pill-icon" />
                            ) : (
                              <FaTimes className="pill-icon" />
                            )}
                            {getPermissionDisplayName(permission)}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-state">
          <p>{t("No linked accounts yet")}</p>
        </div>
      )}

      {/* Account Form */}
      {showLinkedAccountForm &&
        (editingLinkedAccountId || canAddMoreAccounts) && (
          <div className="account-form">
            <h5 className="form-title">
              {editingLinkedAccountId
                ? t("Edit Linked Account")
                : t("Create Linked Account")}
            </h5>

            <form onSubmit={handleLinkedAccountSubmit}>
              {/* Account details in a flex layout */}
              <div className="account-details">
                <FormRow
                  type="text"
                  name="linkedAccountName"
                  value={linkedAccountName}
                  handleChange={(e) => setLinkedAccountName(e.target.value)}
                  labelText={t("Name")}
                />
                <FormRow
                  type="email"
                  name="linkedAccountEmail"
                  value={linkedAccountEmail}
                  handleChange={(e) => setLinkedAccountEmail(e.target.value)}
                  labelText={t("Email")}
                />
                {!editingLinkedAccountId && (
                  <FormRow
                    type="password"
                    name="linkedAccountPassword"
                    value={linkedAccountPassword}
                    handleChange={(e) =>
                      setLinkedAccountPassword(e.target.value)
                    }
                    labelText={t("Password")}
                  />
                )}
                <FormRowSelect
                  name="linkedAccountLanguage"
                  labelText={t("Language")}
                  value={linkedAccountLanguage}
                  handleChange={(e) => setLinkedAccountLanguage(e.target.value)}
                  list={languageOptions.map((option) => option.value)}
                  options={languageOptions}
                />
              </div>

              {/* Permissions panel below account details */}
              <div className="permissions-panel">
                <h5 className="permissions-title">{t("Permissions")}</h5>

                <div className="permissions-grid">
                  {permissionCategories.map((category) => (
                    <div key={category.name} className="permission-group">
                      <h6 className="group-title">{t(category.name)}</h6>
                      <div className="permission-options">
                        {category.permissions.map((permission) => {
                          const isDependent = isDependentPermission(permission);

                          return (
                            <div key={permission} className="permission-toggle">
                              <input
                                type="checkbox"
                                id={permission}
                                checked={linkedAccountPermissions[permission]}
                                onChange={() =>
                                  handleTogglePermission(permission)
                                }
                                disabled={isDependent}
                                className="toggle-input"
                              />
                              <label
                                htmlFor={permission}
                                className="toggle-label"
                                title={getPermissionTooltip(permission)}
                              >
                                <div className="toggle-switch"></div>
                                <span className="toggle-text">
                                  {getPermissionDisplayName(permission)}
                                  {isDependent && (
                                    <span className="dependency-note">
                                      {" "}
                                      ({t("Required")})
                                    </span>
                                  )}
                                </span>
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-actions">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading
                    ? t("Please Wait...")
                    : editingLinkedAccountId
                    ? t("Update Account")
                    : t("Create Account")}
                </button>
              </div>
            </form>
          </div>
        )}

      {/* Custom Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="delete-confirmation-modal">
            <div className="modal-header">
              <FaExclamationTriangle className="warning-icon" />
              <h4>{t("Delete Account")}</h4>
            </div>

            <div className="modal-body">
              <p>
                {t("Are you sure you want to delete the linked account:")}
                <strong> {accountToDelete?.name}</strong>?
              </p>
              <p className="warning-text">
                {t(
                  "This action cannot be undone. The user will lose access to their account."
                )}
              </p>
            </div>

            <div className="modal-actions">
              <button
                className="btn btn-secondary"
                onClick={cancelDeleteAccount}
              >
                {t("Cancel")}
              </button>
              <button className="btn btn-danger" onClick={confirmDeleteAccount}>
                <FaTrash /> {t("Delete Account")}
              </button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default LinkedAccountsSection;

import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const AddItemTypeContainer = () => {
  const { isAddingType, setAddItemType } = useAppContext();
  const { t } = useTranslation();

  return (
    <PermissionGuard permission="editItemTypes">
      <Wrapper>
        <button
          type="button"
          style={{ maxWidth: "10rem", marginLeft: "0.2rem" }}
          className={isAddingType ? `btn btn-danger` : `btn`}
          onClick={setAddItemType}
        >
          {t(isAddingType ? "Close" : "Add")}
        </button>
      </Wrapper>
    </PermissionGuard>
  );
};
export default AddItemTypeContainer;

import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/MenuItem";
import { MdEdit } from "react-icons/md";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const MenuItem = ({ menuItem }) => {
  const { t } = useTranslation();
  const { setEditMenuItem, menuItems, items } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const getIngredientName = (ingredient) => {
    if (ingredient.isMenuItem) {
      const menuIngredient = menuItems.find(
        (mi) => mi._id === ingredient.menuItem
      );
      return menuIngredient ? menuIngredient.menuItemName : "N/A";
    }
    return getItemNameFromId(ingredient.item) || "N/A";
  };

  const getItemNameFromId = (itemId) => {
    const foundItem = items.find((item) => item._id === itemId);
    return foundItem ? foundItem.itemName : "";
  };

  const getItemForIngredient = (ingredientId) => {
    const item = items.find((item) => item._id === ingredientId);
    return item;
  };

  const calculateTotalQuantities = (ingredients, items) => {
    let totalGrams = 0;
    let totalMl = 0;
    let totalUnits = 0;
    let totalPortions = 0;

    const processIngredients = (ings) => {
      ings.forEach((ing) => {
        if (ing.ingredientQType === "portion") {
          totalPortions += ing.ingredientQuantity;
          return;
        }
        // Convert current ingredient to base units WITHOUT waste factor
        const currentIngredientBaseQuantity = convertToBaseUnit(
          ing.ingredientQuantity,
          ing.ingredientQType
        );

        if (ing.isMenuItem) {
          const menuItemIngredient = menuItems.find(
            (mi) => mi._id === ing.menuItem
          );
          if (menuItemIngredient) {
            // Add this menuItem's contribution to totals
            if (["g", "Kg", "lb", "Oz"].includes(ing.ingredientQType)) {
              totalGrams += currentIngredientBaseQuantity;
            } else if (["ml", "L"].includes(ing.ingredientQType)) {
              totalMl += currentIngredientBaseQuantity;
            } else if (ing.ingredientQType === "unit") {
              totalUnits += Math.round(currentIngredientBaseQuantity);
            }
          }
        } else {
          // Regular item ingredient
          if (["g", "Kg", "lb", "Oz"].includes(ing.ingredientQType)) {
            totalGrams += currentIngredientBaseQuantity;
          } else if (["ml", "L"].includes(ing.ingredientQType)) {
            totalMl += currentIngredientBaseQuantity;
          } else if (ing.ingredientQType === "unit") {
            totalUnits += Math.round(currentIngredientBaseQuantity);
          }
        }
      });
    };

    // Start processing all ingredients
    processIngredients(ingredients);

    // Combine ml and g
    const totalMass = totalGrams + totalMl;

    const results = [];
    if (totalPortions > 0) {
      results.push(
        `${totalPortions} ${totalPortions === 1 ? "portion" : "portions"}`
      );
    }
    if (totalMass > 0) {
      if (totalMass >= 1000) {
        results.push(`${(totalMass / 1000).toFixed(2)}Kg`);
      } else {
        results.push(`${totalMass.toFixed(2)}g`);
      }
    }

    if (totalUnits > 0) {
      results.push(
        `${totalUnits} ${t(getPluralForm(totalUnits, "unit", "units"))}`
      );
    }

    return results.join(", ");
  };

  const convertToBaseUnit = (quantity, type) => {
    switch (type) {
      case "L":
        return quantity * 1000;
      case "ml":
        return quantity;
      case "Kg":
        return quantity * 1000;
      case "g":
        return quantity;
      case "Oz":
        return quantity * 28.35;
      case "lb":
        return quantity * 453.592;
      case "unit":
        return quantity;
      default:
        return quantity;
    }
  };

  const computeIngredientCost = (ingredient, item) => {
    if (!item) return 0;

    const ingredientBaseQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );
    const itemBaseAmount = convertToBaseUnit(
      item.itemAmount,
      item.itemAmountType
    );

    const factor = ingredientBaseQuantity / itemBaseAmount;

    return item.price * factor; // No waste factor here
  };

  const getTotalMenuItemCost = (menuItem, visitedIds = new Set()) => {
    if (!menuItem || !menuItem.menuItemIngredients) return 0;

    // Check for circular references
    if (visitedIds.has(menuItem._id)) {
      console.warn(
        `Circular reference detected in menu item: ${menuItem.menuItemName}`
      );
      return 0;
    }

    // Add current menu item to the visited set
    const newVisitedIds = new Set(visitedIds);
    newVisitedIds.add(menuItem._id);

    const totalCost = menuItem.menuItemIngredients.reduce((acc, ingredient) => {
      let cost = 0;
      if (ingredient.isMenuItem) {
        const subMenuItem = menuItems.find(
          (mi) => mi._id === ingredient.menuItem
        );
        if (subMenuItem) {
          // For MenuItem ingredients, calculate cost based on quantity
          cost = computeMenuItemIngredientCost(
            ingredient,
            subMenuItem,
            newVisitedIds
          );
        }
      } else {
        const item = items.find((item) => item._id === ingredient.item);
        if (!item) return acc;

        const ingredientBaseQuantity = convertToBaseUnit(
          ingredient.ingredientQuantity,
          ingredient.ingredientQType
        );
        const itemBaseAmount = convertToBaseUnit(
          item.itemAmount,
          item.itemAmountType
        );

        if (itemBaseAmount === 0) return acc;

        const factor = ingredientBaseQuantity / itemBaseAmount;
        cost = item.price * factor;
      }

      const wasteFactor = 1 + ingredient.wastePercentage / 100;
      return acc + cost * wasteFactor;
    }, 0);

    return totalCost;
  };

  const computeMenuItemIngredientCost = (
    ingredient,
    menuItemIngredient,
    visitedIds = new Set()
  ) => {
    if (!menuItemIngredient || !menuItemIngredient.menuItemIngredients) {
      return 0;
    }

    // Handle portion-based calculations
    if (ingredient.ingredientQType === "portion") {
      const menuItemTotalCost = getTotalMenuItemCost(
        menuItemIngredient,
        visitedIds
      );
      const perOrderCost =
        menuItemIngredient.menuItemOrders > 0
          ? menuItemTotalCost / menuItemIngredient.menuItemOrders
          : 0;

      const wasteFactor = 1 + ingredient.wastePercentage / 100;
      const finalCost =
        perOrderCost * ingredient.ingredientQuantity * wasteFactor;

      return finalCost;
    }

    // Full cost (includes both quantities + units)
    const menuItemTotalCost = getTotalMenuItemCost(
      menuItemIngredient,
      visitedIds
    );

    // All quantity-based ingredients
    const quantityIngredients = menuItemIngredient.menuItemIngredients.filter(
      (ing) => ing.ingredientQType !== "unit"
    );

    // Case 1: No quantity-based ingredients => treat everything as units
    if (quantityIngredients.length === 0) {
      // Only perform cost-per-unit if the current ingredient is also unit-based
      if (ingredient.ingredientQType === "unit") {
        const totalUnits = menuItemIngredient.menuItemIngredients.reduce(
          (sum, ing) => sum + (ing.ingredientQuantity || 0),
          0
        );
        const costPerUnit = menuItemTotalCost / Math.max(totalUnits, 1);

        const wasteFactor = 1 + ingredient.wastePercentage / 100;
        // No error factor here - consistent with MenuItemIngredientList.js
        const finalCost =
          costPerUnit * ingredient.ingredientQuantity * wasteFactor;

        return finalCost;
      }
      return 0;
    }

    // Case 2: There are quantity-based ingredients.
    // If the current ingredient is unit-based, return 0 for partial usage
    if (ingredient.ingredientQType === "unit") {
      return 0;
    }

    // Otherwise, compute fraction of total quantity-based portion
    const totalQuantity = quantityIngredients.reduce(
      (sum, ing) =>
        sum + convertToBaseUnit(ing.ingredientQuantity, ing.ingredientQType),
      0
    );
    const requestedQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );

    const proportion =
      totalQuantity > 0 ? requestedQuantity / totalQuantity : 0;

    // Multiply full cost by that proportion
    const baseCost = menuItemTotalCost * proportion;

    const wasteFactor = 1 + ingredient.wastePercentage / 100;
    // No error factor here - consistent with MenuItemIngredientList.js
    const finalCost = baseCost * wasteFactor;

    return finalCost;
  };

  const totalCost = menuItem.menuItemIngredients.reduce((acc, ingredient) => {
    let cost;
    if (ingredient.isMenuItem) {
      const menuItemIngredient = menuItems.find(
        (mi) => mi._id === ingredient.menuItem
      );
      cost = computeMenuItemIngredientCost(ingredient, menuItemIngredient);
    } else {
      const correspondingItem = getItemForIngredient(ingredient.item);
      cost = computeIngredientCost(ingredient, correspondingItem);
    }

    return acc + (cost || 0);
  }, 0);

  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };

  const dishCost =
    menuItem.menuItemOrders > 0
      ? parseFloat((totalCost / menuItem.menuItemOrders).toFixed(2))
      : 0.0;

  const errorFactor = 1 + menuItem.menuItemError / 100;
  const finalDishCost = (dishCost * errorFactor).toFixed(3);
  const markup = (menuItem.menuItemPrice / finalDishCost).toFixed(3);

  const finalDishCostColor = markup >= 3 ? "green" : "red";

  return (
    <Wrapper>
      <div className="menu-item-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="menu-item-title">
          <div className="title-content">
            <h2>{menuItem.menuItemName}</h2>
            <div className="menu-metrics">
              {menuItem.menuItemPrice ? (
                <>
                  <h3>{menuItem.menuItemPrice}$</h3>
                  <h5 style={{ color: finalDishCostColor }}>
                    {finalDishCost}$ ({markup}x)
                  </h5>
                </>
              ) : null}

              <h4>
                {totalCost.toFixed(3)}$ {t("for")} {menuItem.menuItemOrders}{" "}
                {t(getPluralForm(menuItem.menuItemOrders, "Order", "Orders"))}
              </h4>
              {menuItem.menuItemIngredients &&
                menuItem.menuItemIngredients.length > 0 && (
                  <h4 style={{ color: "var(--primary-500)" }}>
                    {t("Total")}:{" "}
                    {calculateTotalQuantities(
                      menuItem.menuItemIngredients,
                      items
                    )}
                  </h4>
                )}
              <h6>
                {menuItem.menuItemError}% {t("Error")}
              </h6>

              <h4>{menuItem.menuItemNotes}</h4>
            </div>
          </div>
          <PermissionGuard permission="editMenus">
            <button
              type="button"
              className="edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setEditMenuItem(menuItem._id);
              }}
            >
              <MdEdit />
            </button>
          </PermissionGuard>
        </div>

        {isOpen && menuItem.menuItemIngredients.length > 0 && (
          <div className="menu-item-content">
            <h4>{t("Ingredients")}:</h4>
            <div className="ingredient-details">
              {menuItem.menuItemIngredients.map((ingredient, idx) => {
                if (ingredient.isMenuItem) {
                  const menuItemIngredient = menuItems.find(
                    (mi) => mi._id === ingredient.menuItem
                  );
                  if (!menuItemIngredient) return null;

                  const cost = computeMenuItemIngredientCost(
                    ingredient,
                    menuItemIngredient
                  );

                  return (
                    <li key={idx} className="ingredient menuitem-ingredient">
                      <span>{getIngredientName(ingredient)}</span>
                      <span>
                        {ingredient.ingredientQuantity}{" "}
                        {ingredient.ingredientQType}
                      </span>
                      <span>
                        {t("Cost")}: {isNaN(cost) ? "N/A" : cost.toFixed(3)}$
                      </span>
                      <span
                        className={
                          ingredient.wastePercentage > 5
                            ? "waste-high"
                            : "waste-low"
                        }
                      >
                        {t("Waste")}: {ingredient.wastePercentage}%
                      </span>
                    </li>
                  );
                } else {
                  const correspondingItem = getItemForIngredient(
                    ingredient.item
                  );
                  if (!correspondingItem) return null;

                  const cost = computeIngredientCost(
                    ingredient,
                    correspondingItem
                  );

                  return (
                    <li key={idx} className="ingredient">
                      <span>{getIngredientName(ingredient)}</span>
                      <span>
                        {ingredient.ingredientQuantity}{" "}
                        {ingredient.ingredientQType}
                      </span>
                      <span>
                        {t("Cost")}: {isNaN(cost) ? "N/A" : cost.toFixed(3)}$
                      </span>
                      <span
                        className={
                          ingredient.wastePercentage > 5
                            ? "waste-high"
                            : "waste-low"
                        }
                      >
                        {t("Waste")}: {ingredient.wastePercentage}%
                      </span>
                    </li>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default MenuItem;

import {
  FaLocationArrow,
  FaEnvira,
  FaBriefcase,
  FaDollarSign,
  FaCheck,
  FaEdit,
} from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RiLoopRightLine } from "react-icons/ri";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Item";
import ItemInfo from "../ItemInfo";
import PermissionGuard from "../PermissionGuard";
import { useState } from "react";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { useTranslation } from "react-i18next";

const ItemOrder = ({ item }) => {
  const { t } = useTranslation();
  const {
    setEditItem,
    deleteItem,
    updateItemQuantity,
    updateItemInventoryQuantity,
    updateItemStatus,
    itemLocations,
    itemCategories,
    itemTypes,
    itemKitchens,
    alternativePairs,
    includeAlternatives,
    providers,
    toggleAlternatives,
    changeItemProportions,
    toggleInventoryType,
  } = useAppContext();
  const [isEditingInventoryQuantity, setIsEditingInventoryQuantity] =
    useState(false);
  const [editableInventoryQuantity, setEditableInventoryQuantity] = useState(
    item.itemInventoryQuantity
  );

  const handleInventoryQuantityClick = () => {
    setEditableInventoryQuantity(item.itemInventoryQuantity);
    setIsEditingInventoryQuantity(true);
  };

  const handleInventoryQuantityChange = (e) => {
    const value = e.target.validity.valid
      ? e.target.value
      : editableInventoryQuantity;
    setEditableInventoryQuantity(value);
  };

  const handleInventoryQuantitySubmit = () => {
    updateItemInventoryQuantity(item, editableInventoryQuantity);
    setIsEditingInventoryQuantity(false);
  };

  const handleInventoryIncrementDecrement = (change) => {
    const currentQuantity = parseFloat(item.itemInventoryQuantity) || 0;
    const updatedQuantity = Math.max(
      0,
      Math.round((currentQuantity + change) * 100) / 100
    );
    setEditableInventoryQuantity(updatedQuantity);
    updateItemInventoryQuantity(item, updatedQuantity);
  };

  const [isEditingItemQuantity, setIsEditingItemQuantity] = useState(false);
  const [editableItemQuantity, setEditableItemQuantity] = useState(
    item.itemQuantity
  );

  const handleItemQuantityClick = () => {
    setIsEditingItemQuantity(true);
  };

  const handleItemQuantityChange = (e) => {
    setEditableItemQuantity(parseInt(e.target.value, 10) || 0);
  };

  const handleItemQuantitySubmit = () => {
    updateItemQuantity(item, editableItemQuantity);
    setIsEditingItemQuantity(false);
  };

  const handleItemIncrementDecrement = (change) => {
    const currentQuantity = parseInt(item.itemQuantity, 10) || 0;
    const updatedQuantity = Math.max(0, currentQuantity + change);
    setEditableItemQuantity(updatedQuantity);
    updateItemQuantity(item, updatedQuantity);
  };

  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );
  const selectedLocation = itemLocations.find(
    (location) => location._id === item.itemLocation
  );
  const selectedCategory = itemCategories.find(
    (category) => category._id === item.itemCategory
  );
  const selectedType = itemTypes.find((type) => type._id === item.itemType);
  const selectedKitchen = itemKitchens.find(
    (kitchen) => kitchen._id === item.itemKitchen
  );
  const selectedNeededType = itemTypes.find(
    (type) => type._id === item.itemNeededType
  );
  const selectedLeftToOrderType = itemTypes.find(
    (type) => type._id === item.itemLeftToOrderType
  );
  const selectedInventoryType = itemTypes.find(
    (type) => type._id === item.selectedInventoryType
  );
  const selectedProportionsType = itemTypes.find(
    (type) => type._id === item.itemProportionsType
  );

  const providerName = selectedProvider ? selectedProvider.providerName : "";
  const locationName = selectedLocation ? selectedLocation.locationName : "";
  const categoryName = selectedCategory ? selectedCategory.categoryName : "";
  const typeName = selectedType ? selectedType.typeName : "";
  const kitchenName = selectedKitchen ? selectedKitchen.kitchenName : "";
  const itemNeededName = selectedNeededType ? selectedNeededType.typeName : "";
  const itemLeftToOrderName = selectedLeftToOrderType
    ? selectedLeftToOrderType.typeName
    : "";

  const itemSelectedInventoryType = selectedInventoryType
    ? selectedInventoryType.typeName
    : "";
  const proportionsTypeName = selectedProportionsType
    ? selectedProportionsType.typeName
    : "";

  const isAlternative =
    includeAlternatives &&
    alternativePairs.some((pair) => pair.alternative._id === item._id);

  const hasAlternatives =
    item.selectedAlternatives && item.selectedAlternatives.length > 0;

  let borderColor = "";
  let backgroundColor = "";

  if (includeAlternatives && item.selectedAlternatives.length > 0) {
    borderColor = "var(--primary-500)";
  } else if (isAlternative) {
    borderColor = "var(--red-light)";
  }

  if (item.itemQuantity > 0) {
    backgroundColor = "#A8D5BA";
  }

  const getQuantityColor = (quantity, needed) => {
    return quantity < needed ? "var(--red-light)" : "inherit";
  };

  const toggleAlt = () => {
    const itemId = item._id;
    setEditItem(itemId);
    toggleAlternatives(itemId);
  };

  const handleChangeProportions = () => {
    changeItemProportions(item._id);
  };

  const isDefaultOrOther = (type) => {
    return ["Default", "Other"].includes(type);
  };
  const [tooltip, setTooltip] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });

  const copyToClipboard = (text, field, e) => {
    if (!navigator.clipboard) {
      console.error("Clipboard functionality not supported in this browser");
      return;
    }
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltip(t(`${field} copied!`));
        setTooltipPos({ x: e.pageX, y: e.pageY });
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
          setTooltip("");
        }, 1800);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };
  const tooltipStyle = {
    left: `${tooltipPos.x}px`,
    top: `${tooltipPos.y}px`,
    position: "absolute",
    transform: "translate(-50%, -100%)",
  };

  return (
    <Wrapper>
      <div
        style={{
          borderLeft: borderColor ? `7.5px solid ${borderColor}` : "none",
          background: backgroundColor,
          borderRadius: "10px",
          userSelect: "text",
        }}
      >
        {tooltipVisible && (
          <div
            className={`tooltip ${tooltipVisible ? "visible" : ""}`}
            style={tooltipStyle}
          >
            {tooltip}
          </div>
        )}
        <header>
          <div className="header-container">
            <div className="main-icon">{item.itemQuantity}</div>
            <PermissionGuard permission="editItems">
              {item.customProportions && item.customProportions.length > 0 && (
                <div
                  className="loop-prop"
                  onClick={() => handleChangeProportions(item._id)}
                >
                  <RiLoopRightLine
                    style={{
                      width: "1.5rem",
                      height: "1.55rem",
                      marginBottom: "0.25rem",
                    }}
                  />
                </div>
              )}
            </PermissionGuard>
            <h6>{typeName}</h6>
            <h5 onClick={(e) => copyToClipboard(item.itemName, "Item Name", e)}>
              {item.itemName}
            </h5>
            <h6 className="itemProportions">
              {item.itemProportions &&
                `${item.itemProportions}${
                  item.itemProportionsAmount !== 0
                    ? `x${item.itemProportionsAmount}`
                    : ""
                }${
                  item.itemProportionsAmountType !== "Other"
                    ? item.itemProportionsAmountType
                    : ""
                }`}
            </h6>
            <div className="info23">
              {item.itemSupplier && (
                <div
                  className="info2"
                  onClick={(e) =>
                    copyToClipboard(item.itemSupplier, "Supplier", e)
                  }
                >
                  <p>{item.itemSupplier}</p>
                </div>
              )}
              <div
                className="info3"
                onClick={(e) => copyToClipboard(providerName, "Provider", e)}
              >
                <p>{providerName}</p>
              </div>
              <div className="info3">
                {item.itemCode != "" && (
                  <p
                    style={{ color: "gray" }}
                    onClick={(e) =>
                      copyToClipboard(item.itemCode, "Item Code", e)
                    }
                  >
                    {item.itemCode}
                  </p>
                )}
                {kitchenName !== "Default" && (
                  <div className="info3">
                    <p style={{ color: "black" }}>{kitchenName}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <PermissionGuard permission="editItems">
            <button
              type="button"
              className="edit-btn"
              onClick={() => setEditItem(item._id)}
            >
              <MdEdit />
            </button>
          </PermissionGuard>
        </header>
        <div className="content">
          <div className="content-center">
            <div className="content-info">
              <PermissionGuard
                permission="viewItemPrices"
                fallback={<span></span>}
              >
                <ItemInfo icon={<FaDollarSign />} text={item.price} />
              </PermissionGuard>
              <Tippy content={t("location")}>
                <div>
                  <ItemInfo icon={<FaLocationArrow />} text={locationName} />
                </div>
              </Tippy>
              <Tippy content={t("category")}>
                <div>
                  <ItemInfo icon={<FaEnvira />} text={categoryName} />
                </div>
              </Tippy>
              <Tippy content={t("amount")}>
                <div>
                  <ItemInfo
                    icon={<FaBriefcase />}
                    text={
                      item.itemAmount +
                      (item.itemAmountType && item.itemAmountType !== "Default"
                        ? item.itemAmountType
                        : "")
                    }
                  />
                </div>
              </Tippy>
            </div>
            <div
              className="content-checkbox"
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {/* <div className="checkbox-container">
                <input
                  style={{ marginRight: "0.25rem" }}
                  type="checkbox"
                  onClick={() => updateItemStatus(item, "unity")}
                  defaultChecked={item.isUnity}
                />
                <p> Unity</p>
              </div> */}
              <div
                className="item-inventory-quantity"
                style={{ marginRight: "0.5rem" }}
              >
                <div style={{ display: "flex" }}>
                  <p style={{ textAlign: "center" }}>
                    {t("Inventory")} {`(${itemSelectedInventoryType})`}
                  </p>
                  <PermissionGuard permission="updateItemQuantities">
                    {!isDefaultOrOther(proportionsTypeName) && (
                      <button
                        type="button"
                        onClick={() => toggleInventoryType(item._id)}
                        className="no-btn"
                      >
                        <RiLoopRightLine
                          style={{
                            width: "1rem",
                            height: "1rem",
                          }}
                        />
                      </button>
                    )}
                  </PermissionGuard>
                </div>
                <PermissionGuard
                  permission="updateItemQuantities"
                  fallback={<div></div>}
                >
                  <div style={{ display: "flex" }}>
                    <button
                      style={{ maxHeight: "2rem" }}
                      type="button"
                      className="btn"
                      onClick={() => handleInventoryIncrementDecrement(-1)}
                      disabled={item.itemInventoryQuantity === 0}
                    >
                      -
                    </button>
                    <div className="quantity">
                      {isEditingInventoryQuantity ? (
                        <>
                          <input
                            type="text"
                            pattern="^\d*(\.\d{0,2})?$"
                            min="0"
                            value={editableInventoryQuantity}
                            onChange={handleInventoryQuantityChange}
                            onBlur={handleInventoryQuantitySubmit}
                            autoFocus
                            style={{ maxWidth: "3rem" }}
                          />
                        </>
                      ) : (
                        <div
                          onClick={handleInventoryQuantityClick}
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          {item.itemInventoryQuantity}
                        </div>
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn"
                      style={{ maxHeight: "2rem" }}
                      onClick={() => handleInventoryIncrementDecrement(1)}
                    >
                      +
                    </button>
                  </div>
                </PermissionGuard>
              </div>
            </div>{" "}
            <PermissionGuard permission="editItems">
              <div className="actions">
                {hasAlternatives && (
                  <button type="button" className="btn" onClick={toggleAlt}>
                    Alt
                  </button>
                )}
                {/* <button
                className="btn delete-btn"
                onClick={() => deleteItem(item._id)}
              >
                Delete
              </button> */}
              </div>
            </PermissionGuard>
          </div>
          <div className="item-footer">
            <div className="item-needed-quantity">
              <Tippy content={t("itemNeededAndLeftToOrder")}>
                <div className="item-needed">
                  {`📦${item.itemNeeded}${itemNeededName}${
                    item.itemLeftToOrder
                      ? ` | 🔁${item.itemLeftToOrder}${itemLeftToOrderName}`
                      : ""
                  }`}
                </div>
              </Tippy>
              <div className="item-quantity">
                <PermissionGuard
                  permission="viewItemPrices"
                  fallback={<span></span>}
                >
                  <div className="item-total">
                    Σ {Math.round(item.price * item.itemQuantity * 100) / 100}
                  </div>
                </PermissionGuard>
                <PermissionGuard
                  permission="updateItemQuantities"
                  fallback={<div></div>}
                >
                  <button
                    style={{ maxHeight: "2rem" }}
                    type="button"
                    className="btn"
                    onClick={() => handleItemIncrementDecrement(-1)}
                    disabled={item.itemQuantity === 0}
                  >
                    -
                  </button>
                  <div className="quantity">
                    {isEditingItemQuantity ? (
                      <input
                        type="number"
                        min="0"
                        value={editableItemQuantity}
                        onChange={handleItemQuantityChange}
                        onBlur={handleItemQuantitySubmit}
                        autoFocus
                        style={{ maxWidth: "3rem" }}
                      />
                    ) : (
                      <div
                        onClick={handleItemQuantityClick}
                        style={{
                          color: getQuantityColor(
                            item.itemQuantity,
                            item.itemNeeded
                          ),
                          fontWeight: "600",
                        }}
                      >
                        {item.itemQuantity}
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn"
                    style={{ maxHeight: "2rem" }}
                    onClick={() => handleItemIncrementDecrement(1)}
                  >
                    +
                  </button>
                </PermissionGuard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemOrder;

import { useAppContext } from "../../context/appContext";
import { useEffect } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import Menu from "./Menu";
import Wrapper from "../../assets/wrappers/ProvidersContainer";
import { useTranslation } from "react-i18next";

const MenusContainer = () => {
  const { t } = useTranslation();
  const { getMenus, menus, totalMenus } = useAppContext();

  useEffect(() => {
    if (menus.length < 1) {
      getMenus();
    }
  }, []);

  if (menus.length === 0) {
    return (
      <Wrapper>
        <h2>{t("No menus to display...")}</h2>
      </Wrapper>
    );
  }
  const getPluralForm = (count, single, plural) => {
    return count === 1 ? single : plural;
  };

  return (
    <Wrapper>
      <div className="providers-title">
        <h5 className="providers-title-h5">
          {menus.length}{" "}
          {getPluralForm(menus.length, t("menu found"), t("menus found"))}
        </h5>
      </div>
      <div className={`providers`}>
        {menus.map((menu) => {
          return <Menu key={menu._id} menu={menu} />;
        })}
      </div>
    </Wrapper>
  );
};

export default MenusContainer;

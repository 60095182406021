import { AiFillEdit } from "react-icons/ai";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Location";
import PermissionGuard from "../PermissionGuard";

const ItemType = ({ itemType }) => {
  const { setEditItemType } = useAppContext();

  return (
    <Wrapper>
      <div className="item-location">
        <b>{itemType.typeName}</b>
        <PermissionGuard permission="editItemTypes">
          {itemType.typeName !== "Default" && (
            <button
              className="item-location-button"
              onClick={() => setEditItemType(itemType._id)}
            >
              <AiFillEdit />
            </button>
          )}
        </PermissionGuard>
      </div>
    </Wrapper>
  );
};

export default ItemType;

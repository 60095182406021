import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Alternative";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ItemAlternative = ({ item }) => {
  const { t } = useTranslation();
  const { addItemAlternative, providers } = useAppContext();

  const selectedProvider = providers.find(
    (provider) => provider._id === item.provider
  );
  const providerName = selectedProvider ? selectedProvider.providerName : "";

  return (
    <Wrapper>
      <div className={`alternative-item ${item.isBackordered ? "active" : ""}`}>
        <div className="alternative-content">
          <div className="alternative-info">
            <h5>{item.itemName}</h5>
            <div className="alternative-details">
              <span>{item.itemSupplier}</span>
              {providerName && <span>{providerName}</span>}
            </div>
          </div>

          <div className="alternative-actions">
            <button
              type="button"
              className="alt-btn add-btn"
              onClick={() => addItemAlternative(item)}
              title={t("Add as alternative")}
            >
              <FaPlus />
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemAlternative;

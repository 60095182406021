import { Navigate } from "react-router-dom";
import { useAppContext } from "../context/appContext";

const PermissionRoute = ({ children, permission }) => {
  const { user } = useAppContext();

  if (
    user.isAdmin ||
    permission === null ||
    (user.permissions && user.permissions[permission])
  ) {
    return children;
  }

  return <Navigate to="/stock" />;
};

export default PermissionRoute;

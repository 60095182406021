import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.5rem;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--shadow-2);
  overflow: hidden;

  .auto-order-container {
    padding: 0.75rem;
    padding-bottom: 0;
  }

  .auto-order-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--grey-100);
    padding-bottom: 0.35rem;
  }

  .auto-order-title {
    margin: 0;
    font-size: 1rem;
    color: var(--primary-500);
    font-weight: 500;
  }

  .stats-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .stat-item {
    background: var(--grey-50);
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    border-left: 3px solid var(--primary-500);
    box-shadow: var(--shadow-2);

    &.success {
      border-left-color: var(--green-dark);
    }

    &.warning {
      border-left-color: var(--red-dark);
    }

    &.info {
      border-left-color: var(--primary-500);
    }

    .stat-count {
      font-weight: 600;
      margin-right: 0.35rem;
    }

    .stat-label {
      font-size: 0.9rem;
      color: var(--grey-700);
    }
  }

  .action-container {
    display: flex;
    justify-content: flex-end;
  }

  .auto-order-btn {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--green-dark);

    &:hover {
      background: var(--green-light);
      color: var(--green-dark);
    }

    &:disabled {
      background: var(--grey-300);
      color: var(--grey-500);
      cursor: not-allowed;
    }
  }
`;

export default Wrapper;

import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Menu";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import PermissionGuard from "../PermissionGuard";

const Menu = ({ menu }) => {
  const { t } = useTranslation();
  const { setEditMenu } = useAppContext();
  const navigate = useNavigate();

  const navigateToMenuItem = () => {
    navigate(`/menu/${menu._id}`);
  };

  return (
    <Wrapper>
      <div className="menu-container" onClick={navigateToMenuItem}>
        <div className="menu-header">
          <div className="title-container">
            <h2>{menu.menuName}</h2>
            <PermissionGuard permission="editMenus">
              <button
                type="button"
                className="edit-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setEditMenu(menu._id);
                }}
              >
                <MdEdit />
              </button>
            </PermissionGuard>
          </div>
          <div className="menu-content">
            {menu.menuDescription && <h4>{menu.menuDescription}</h4>}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Menu;

import { useAppContext } from "../../context/appContext";
import { useEffect } from "react";
import Alert from "../Alert";
import Loading from "../Loading";
import MenuItem from "./MenuItem";
import Wrapper from "../../assets/wrappers/ProvidersContainer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MenuItemsContainer = () => {
  const { t } = useTranslation();
  const { getItems, getMenuItems, menuItems, items } = useAppContext();
  const { id: menuId } = useParams();

  useEffect(() => {
    if (items.length < 1) {
      getItems();
    }
    if (menuItems.length < 1) {
      getMenuItems();
    }
  }, []);

  const filteredMenuItems = menuItems.filter((item) => item.menu === menuId);

  if (!filteredMenuItems || filteredMenuItems.length === 0) {
    return (
      <Wrapper>
        <h2>{t("No menu items to display...")}</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {filteredMenuItems.map((menuItem) => (
        <MenuItem key={menuItem._id} menuItem={menuItem} />
      ))}
    </Wrapper>
  );
};

export default MenuItemsContainer;

import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const AddItemKitchenContainer = () => {
  const { isAddingKitchen, setAddItemKitchen } = useAppContext();
  const { t } = useTranslation();
  return (
    <PermissionGuard permission="editItemKitchens">
      <Wrapper>
        <button
          type="button"
          style={{ maxWidth: "10rem", marginLeft: "0.2rem" }}
          className={isAddingKitchen ? `btn btn-danger` : `btn`}
          onClick={setAddItemKitchen}
        >
          {t(isAddingKitchen ? "Close" : "Add")}
        </button>
      </Wrapper>
    </PermissionGuard>
  );
};
export default AddItemKitchenContainer;

import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  SETUP_USER_BEGIN,
  SETUP_USER_SUCCESS,
  SETUP_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_CHANGE,
  CLEAR_VALUES,
  CREATE_ITEM_BEGIN,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_ERROR,
  GET_ITEMS_BEGIN,
  GET_ITEMS_SUCCESS,
  SET_EDIT_ITEM,
  TOGGLE_SUBITEM_DETAILS,
  ADD_CUSTOM_PROPORTION,
  HANDLE_CUSTOM_PROPORTION_CHANGE,
  REMOVE_CUSTOM_PROPORTION,
  CHANGE_PROPORTIONS_BEGIN,
  CHANGE_PROPORTIONS_SUCCESS,
  CHANGE_PROPORTIONS_ERROR,
  TOGGLE_DELETE_CONFIRMATION,
  DELETE_ITEM_BEGIN,
  DELETE_ITEM_SUCCESS,
  EDIT_ITEM_BEGIN,
  EDIT_ITEM_SUCCESS,
  EDIT_ITEM_ERROR,
  CREATE_PROVIDER_BEGIN,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_ERROR,
  GET_PROVIDERS_BEGIN,
  GET_PROVIDERS_SUCCESS,
  SET_EDIT_PROVIDER,
  SET_DELIVERY_DAY,
  DELETE_PROVIDER_BEGIN,
  DELETE_PROVIDER_SUCCESS,
  EDIT_PROVIDER_BEGIN,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_ERROR,
  CLEAR_FILTERS,
  UPDATE_ITEM_QUANTITY_BEGIN,
  UPDATE_ITEM_QUANTITY_SUCCESS,
  UPDATE_ITEM_QUANTITY_ERROR,
  UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN,
  UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS,
  UPDATE_ITEM_INVENTORY_QUANTITY_ERROR,
  TOGGLE_RESET_QUANTITIES,
  TOGGLE_AUTO_ORDER,
  SET_ADD_ITEM,
  SET_ADD_ITEM_LOCATION,
  SET_ADD_PROVIDER,
  /*UPDATE_ITEM_QUANTITY,*/
  CREATE_ITEM_LOCATION_BEGIN,
  CREATE_ITEM_LOCATION_SUCCESS,
  CREATE_ITEM_LOCATION_ERROR,
  GET_ITEM_LOCATIONS_BEGIN,
  GET_ITEM_LOCATIONS_SUCCESS,
  DELETE_ITEM_LOCATION_BEGIN,
  SET_EDIT_ITEM_LOCATION,
  EDIT_ITEM_LOCATION_BEGIN,
  EDIT_ITEM_LOCATION_SUCCESS,
  EDIT_ITEM_LOCATION_ERROR,
  CREATE_ITEM_CATEGORY_BEGIN,
  CREATE_ITEM_CATEGORY_SUCCESS,
  CREATE_ITEM_CATEGORY_ERROR,
  GET_ITEM_CATEGORIES_BEGIN,
  GET_ITEM_CATEGORIES_SUCCESS,
  DELETE_ITEM_CATEGORY_BEGIN,
  SET_ADD_ITEM_CATEGORY,
  SET_EDIT_ITEM_CATEGORY,
  EDIT_ITEM_CATEGORY_BEGIN,
  EDIT_ITEM_CATEGORY_SUCCESS,
  EDIT_ITEM_CATEGORY_ERROR,
  CREATE_ITEM_TYPE_BEGIN,
  CREATE_ITEM_TYPE_SUCCESS,
  CREATE_ITEM_TYPE_ERROR,
  GET_ITEM_TYPES_BEGIN,
  GET_ITEM_TYPES_SUCCESS,
  DELETE_ITEM_TYPE_BEGIN,
  SET_ADD_ITEM_TYPE,
  SET_EDIT_ITEM_TYPE,
  EDIT_ITEM_TYPE_BEGIN,
  EDIT_ITEM_TYPE_SUCCESS,
  EDIT_ITEM_TYPE_ERROR,
  CREATE_ITEM_KITCHEN_BEGIN,
  CREATE_ITEM_KITCHEN_SUCCESS,
  CREATE_ITEM_KITCHEN_ERROR,
  SET_ADD_ITEM_KITCHEN,
  GET_ITEM_KITCHENS_BEGIN,
  GET_ITEM_KITCHENS_SUCCESS,
  DELETE_ITEM_KITCHEN_BEGIN,
  SET_EDIT_ITEM_KITCHEN,
  EDIT_ITEM_KITCHEN_BEGIN,
  EDIT_ITEM_KITCHEN_SUCCESS,
  EDIT_ITEM_KITCHEN_ERROR,
  TOGGLE_ALTERNATIVES,
  GET_ITEM_ALTERNATIVES_BEGIN,
  GET_ITEM_ALTERNATIVES_SUCCESS,
  ADD_ITEM_ALTERNATIVE_BEGIN,
  REMOVE_ITEM_ALTERNATIVE,
  TOGGLE_CONDENSED,
  CHANGE_PAGE,
  CHANGE_TRASHED_PAGE,
  CREATE_MENU_BEGIN,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_ERROR,
  GET_MENUS_BEGIN,
  GET_MENUS_SUCCESS,
  SET_ADD_MENU,
  SET_EDIT_MENU,
  DELETE_MENU_BEGIN,
  EDIT_MENU_BEGIN,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_ERROR,
  //menuItems
  SET_MENU_ITEMS,
  CREATE_MENU_ITEM_BEGIN,
  CREATE_MENU_ITEM_SUCCESS,
  CREATE_MENU_ITEM_ERROR,
  GET_MENU_ITEMS_BEGIN,
  GET_MENU_ITEMS_SUCCESS,
  SET_ADD_MENU_ITEM,
  SET_EDIT_MENU_ITEM,
  DELETE_MENU_ITEM_BEGIN,
  EDIT_MENU_ITEM_BEGIN,
  EDIT_MENU_ITEM_SUCCESS,
  EDIT_MENU_ITEM_ERROR,
  ADD_INGREDIENT_FIELD,
  REMOVE_INGREDIENT_FIELD,
  UPDATE_INGREDIENT_FIELD,
  SEARCH_INGREDIENT_BEGIN,
  SEARCH_INGREDIENT_SUCCESS,
  CLEAR_INGREDIENT_SEARCH_RESULTS,
  SET_EDIT_VIEW,
  EDIT_VIEW,
  RESET_VIEW,
  RESET_ITEMS_QUANTITY_BEGIN,
  RESET_ITEMS_QUANTITY_SUCCESS,
  RESET_ITEMS_QUANTITY_ERROR,
  GET_ITEM_BACKUP_BEGIN,
  GET_ITEM_BACKUP_SUCCESS,
  RESTORE_ITEM_QUANTITIES_BEGIN,
  RESTORE_ITEM_QUANTITIES_SUCCESS,
  RESTORE_ITEM_QUANTITIES_ERROR,
  TOGGLE_EXPORT_DATA,
  //TRASH
  SOFT_DELETE_ITEM_BEGIN,
  SOFT_DELETE_ITEM_SUCCESS,
  RESTORE_ITEM_BEGIN,
  RESTORE_ITEM_SUCCESS,
  GET_TRASHED_ITEMS_BEGIN,
  GET_TRASHED_ITEMS_SUCCESS,
  //orders
  CREATE_ORDER_BEGIN,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_ORDERS_BEGIN,
  GET_ORDERS_SUCCESS,
  DELETE_ORDER_BEGIN,
  SET_EDIT_ORDER,
  UPDATE_ORDER_ITEM_STATUS,
  UPDATE_MISSING_NUMBER,
  EDIT_ORDER_BEGIN,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_ERROR,
  TOGGLE_CREATE_ORDER,
  TOGGLE_SEND_ORDERS,
  SEND_ORDERS_BEGIN,
  SEND_ORDERS_SUCCESS,
  SEND_ORDERS_ERROR,
  SET_OAUTH_TOKEN,
  FETCH_REPORTS_BEGIN,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_ERROR,
  GET_SUBSCRIPTION_BEGIN,
  GET_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_ERROR,
  VERIFY_SESSION_BEGIN,
  VERIFY_SESSION_SUCCESS,
  UPDATE_STRIPE_INFO_BEGIN,
  UPDATE_STRIPE_INFO_SUCCESS,
  UPDATE_STRIPE_INFO_ERROR,
  CHECK_SUBSCRIPTION_SUCCESS,
  CHECK_SUBSCRIPTION_FAILURE,
  //Invoices
  TOGGLE_SCAN_ORDER_INVOICE,
  SCAN_INVOICE_BEGIN,
  SCAN_INVOICE_SUCCESS,
  SCAN_INVOICE_ERROR,
  COMPARE_INVOICE_BEGIN,
  COMPARE_INVOICE_SUCCESS,
  COMPARE_INVOICE_ERROR,
  UPDATE_PRICES_BEGIN,
  UPDATE_PRICES_SUCCESS,
  UPDATE_PRICES_ERROR,
  //UserSettings
  CREATE_USER_SETTINGS_BEGIN,
  CREATE_USER_SETTINGS_SUCCESS,
  CREATE_USER_SETTINGS_ERROR,
  GET_USER_SETTINGS_BEGIN,
  GET_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_BEGIN,
  UPDATE_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_ERROR,
  TOGGLE_EDIT_ERROR_PERCENTAGE,
  //Linked accounts
  CREATE_LINKED_ACCOUNT_BEGIN,
  CREATE_LINKED_ACCOUNT_SUCCESS,
  CREATE_LINKED_ACCOUNT_ERROR,
  GET_LINKED_ACCOUNTS_BEGIN,
  GET_LINKED_ACCOUNTS_SUCCESS,
  UPDATE_LINKED_ACCOUNT_BEGIN,
  UPDATE_LINKED_ACCOUNT_SUCCESS,
  UPDATE_LINKED_ACCOUNT_ERROR,
  DELETE_LINKED_ACCOUNT_BEGIN,
  DELETE_LINKED_ACCOUNT_SUCCESS,
  DELETE_LINKED_ACCOUNT_ERROR,
  //Connection
  ADD_PENDING_CHANGE,
  CLEAR_PENDING_CHANGES,
  SET_CONNECTION_STATUS,
  SET_LANGUAGE,
} from "./actions";

import { initialState } from "./appContext";

const reducer = (state, action) => {
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: "Please provide all values",
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }
  if (action.type === SETUP_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.alertText,
    };
  }
  if (action.type === SETUP_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_SUBSCRIPTION_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === GET_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === UPDATE_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === VERIFY_SESSION_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === VERIFY_SESSION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      subscription: action.payload.status,
      planId: action.payload.planId,
    };
  }
  if (action.type === UPDATE_STRIPE_INFO_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_STRIPE_INFO_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Stripe Info Updated!",
    };
  }
  if (action.type === UPDATE_STRIPE_INFO_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CHECK_SUBSCRIPTION_SUCCESS) {
    return {
      ...state,
      subscription: action.payload.subscriptionStatus,
      planId: action.payload.planId,
    };
  }
  if (action.type === CHECK_SUBSCRIPTION_FAILURE) {
    return {
      ...state,
      subscription: action.payload.subscriptionStatus,
      planId: action.payload.planId,
      showAlert: true,
      alertType: "danger",
      alertText: "No active Subscription",
    };
  }
  if (action.type === SUBSCRIPTION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_SIDEBAR) {
    return { ...state, showSidebar: !state.showSidebar };
  }
  if (action.type === TOGGLE_ALTERNATIVES) {
    const currentItem = state.items.find(
      (item) => item._id === state.editItemId
    );
    let updatedSelectedAlternatives = state.selectedAlternatives;

    if (currentItem && currentItem.selectedAlternatives) {
      updatedSelectedAlternatives = currentItem.selectedAlternatives;
    }

    return {
      ...state,
      isEditing: true,
      editItemId: action.payload,
      showAlternatives: !state.showAlternatives,
      selectedAlternatives: updatedSelectedAlternatives,
    };
  }
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
    };
  }
  if (action.type === UPDATE_USER_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === UPDATE_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      showAlert: true,
      alertType: "success",
      alertText: "User Profile Updated!",
    };
  }
  if (action.type === UPDATE_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === HANDLE_CHANGE) {
    return {
      ...state,
      [action.payload.name]: action.payload.value,
    };
  }
  if (action.type === CLEAR_VALUES) {
    const initialState = {
      isAdding: false,
      isEditing: false,
      isDeleting: false,
      isAddingProvider: false,
      isEditingProvider: false,
      isAddingLocation: false,
      isEditingLocation: false,
      isAddingCategory: false,
      isEditingCategory: false,
      isAddingType: false,
      isEditingType: false,
      isAddingKitchen: false,
      isEditingKitchen: false,
      isAddingMenu: false,
      isEditingMenu: false,
      isAddingMenuItem: false,
      isEditingMenuItem: false,
      isEditingView: false,
      isCreatingOrder: false,
      isEditingOrder: false,
      editMenuId: "",
      editItemId: "",
      editProviderId: "",
      editItemLocationId: "",
      editItemCategoryId: "",
      editItemTypeId: "",
      editItemKitchenId: "",
      editOrderId: "",
      itemName: "",
      itemProportions: "",
      itemProportionsType: "Default",
      itemProportionsAmount: 0,
      itemProportionsAmountType: "Other",
      isShowingProportions: false,
      price: 0,
      priceHistory: [],
      itemCode: "",
      itemAmount: 0,
      itemAmountType: "Default",
      provider: "Default",
      itemCategory: "Default",
      itemType: "Default",
      itemLocation: "Default",
      locationName: "",
      categoryName: "",
      typeName: "",
      kitchenName: "",
      itemNeeded: "",
      itemNeededType: "Default",
      itemLeftToOrder: "",
      itemLeftToOrderType: "Default",
      itemSupplier: "",
      itemQuantity: 0,
      itemInventoryQuantity: 0,
      itemCustomProportions: [],
      providerName: "",
      number: "",
      notes: "",
      website: "",
      deliveryDays: [],
      deliveryTime: "",
      holiday: "",
      email: "",
      providerId: "",
      passwordInfo: "",
      minimumOrder: 0,
      orderDate: "",
      representName: "",
      orderName: "",
      orderNotes: "",
      menuName: "",
      menuDescription: "",
      menuItemName: "",
      menuItemPrice: 0,
      menuItemNotes: "",
      menuItemImage: "",
      menuItemOrders: 1,
      menuItemError: 0,
      menuItemIngredients: [],
      //menuIngredients: [],
      searchAlternatives: "",
      searchIngredients: "",
      showAlternatives: false,
      selectedAlternatives: [],
    };
    return {
      ...state,
      ...initialState,
    };
  }
  if (action.type === CREATE_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Item Created!",
    };
  }
  if (action.type === CREATE_ITEM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEMS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEMS_SUCCESS) {
    const allItems = action.payload.items;
    //const itemsPerPage = state.itemsPerPage;
    //const numOfPages = Math.ceil(allItems.length / itemsPerPage);

    const isGeneralFetch =
      state.search === "" &&
      state.searchProvider === "all" &&
      state.searchLocation === "all" &&
      state.searchCategory === "all" &&
      state.searchType === "all" &&
      state.searchKitchen === "all";

    let alternativePairs = state.alternativePairs;

    if (isGeneralFetch) {
      alternativePairs = allItems.reduce((acc, mainItem) => {
        if (
          mainItem.selectedAlternatives &&
          mainItem.selectedAlternatives.length > 0
        ) {
          mainItem.selectedAlternatives.forEach((alt) => {
            if (!acc.some((a) => a.alternative._id === alt._id)) {
              acc.push({
                mainItem: {
                  _id: mainItem._id,
                  itemName: mainItem.itemName,
                  provider: mainItem.provider,
                  itemSupplier: mainItem.itemSupplier,
                },
                alternative: {
                  _id: alt._id,
                  itemName: alt.itemName,
                  provider: alt.provider,
                  itemSupplier: alt.itemSupplier,
                },
              });
            }
          });
        }
        return acc;
      }, []);
    }

    return {
      ...state,
      isLoading: false,
      items: allItems,
      totalItems: allItems.length,
      //numOfPages,
      alternativePairs,
      showSidebar: false,
    };
  }
  if (action.type === TOGGLE_SUBITEM_DETAILS) {
    return { ...state, isShowingProportions: !state.isShowingProportions };
  }

  if (action.type === SET_ADD_ITEM) {
    const { providerOptions } = state;
    const defaultProvider = Array.from(providerOptions)[0];

    return {
      ...state,
      isAdding: true,
      isEditingView: false,
      provider: defaultProvider,
      itemLocation: "Default",
      itemCategory: "Default",
      itemType: "Default",
      itemProportionsType: "Default",
      itemKitchen: "Default",
      itemAmountType: "Other",
    };
  }

  if (action.type === SET_EDIT_ITEM) {
    const item = state.items.find((item) => item._id === action.payload.id);
    const {
      itemLocations,
      itemCategories,
      itemTypes,
      itemKitchens,
      items,
      providers,
    } = state;

    const {
      _id,
      itemName,
      itemProportions,
      itemProportionsType,
      itemProportionsAmount,
      itemProportionsAmountType,
      provider,
      itemCategory,
      itemType,
      itemAmount,
      itemAmountType,
      price,
      priceHistory,
      itemCode,
      itemNeeded,
      itemLeftToOrder,
      itemLocation,
      itemSupplier,
      customProportions,
      originalProportions,
      selectedAlternatives,
    } = item;

    const newSelectedAlternatives = items.filter((item) =>
      selectedAlternatives.some(
        (alternative) => alternative._id === String(item._id)
      )
    );
    const selectedProvider =
      state.providers.find((p) => p._id === item.provider) ?? {};
    const { providerName } = selectedProvider;
    const myProviderName =
      providerName !== undefined ? providerName : "Default";
    const selectedLocation =
      itemLocations.find(
        (itemLocation) => itemLocation._id === item.itemLocation
      ) ?? {};
    const { locationName } = selectedLocation;
    const myLocationName =
      locationName !== undefined ? locationName : "Default";

    const selectedCategory =
      itemCategories.find(
        (itemCategory) => itemCategory._id === item.itemCategory
      ) ?? {};
    const { categoryName } = selectedCategory;
    const myCategoryName =
      categoryName !== undefined ? categoryName : "Default";

    const selectedType =
      itemTypes.find((itemType) => itemType._id === item.itemType) ?? {};
    const { typeName } = selectedType;
    const myTypeName = typeName !== undefined ? typeName : "Default";

    const selectedKitchen =
      itemKitchens.find(
        (itemKitchen) => itemKitchen._id === item.itemKitchen
      ) ?? {};
    const { kitchenName } = selectedKitchen ?? "Default";
    const myKitchenName = kitchenName !== undefined ? kitchenName : "Default";

    const itemProportionsTypeObject =
      itemTypes.find((type) => type._id === item.itemProportionsType) ?? {};
    // const itemProportionsTypeName =
    //    itemProportionsTypeObject.typeName ?? "Default";

    const itemNeededTypeObject =
      itemTypes.find((type) => type._id === item.itemNeededType) ?? {};
    const itemNeededTypeName = itemNeededTypeObject.typeName ?? "Default";

    const itemLeftToOrderTypeObject =
      itemTypes.find((type) => type._id === item.itemLeftToOrderType) ?? {};
    const itemLeftToOrderTypeName =
      itemLeftToOrderTypeObject.typeName ?? "Default";

    const transformedCustomProportions = customProportions.map((cp) => {
      const itemTypeObject = itemTypes.find(
        (it) => it._id === String(cp.itemType)
      );
      const itemProportionsTypeObject = itemTypes.find(
        (it) => it._id === String(cp.itemProportionsType)
      );

      return {
        ...cp,
        itemType: itemTypeObject ? itemTypeObject.typeName : "Unknown",
        itemProportionsType: itemProportionsTypeObject
          ? itemProportionsTypeObject.typeName
          : "Unknown",
      };
    });

    const isUsingCustomProportions = customProportions.some(
      (cp) =>
        cp.price === price &&
        cp.itemType === itemType &&
        cp.itemProportions === itemProportions &&
        cp.itemProportionsType === itemProportionsType &&
        cp.itemProportionsAmount === itemProportionsAmount &&
        cp.itemProportionsAmountType === itemProportionsAmountType &&
        cp.itemAmount === itemAmount &&
        cp.itemAmountType === itemAmountType
    );
    const proportionData = isUsingCustomProportions
      ? originalProportions
      : {
          itemProportions: item.itemProportions,
          itemProportionsType: item.itemProportionsType,
          itemProportionsAmount: item.itemProportionsAmount,
          itemProportionsAmountType: item.itemProportionsAmountType,
          itemType: item.itemType,
          itemAmount: item.itemAmount,
          itemAmountType: item.itemAmountType,
          price: item.price,
        };

    const getNameOrDefault = (typeId, defaultName = "Default") => {
      const typeObj = itemTypes.find(
        (type) => type._id.toString() === typeId?.toString()
      );
      return typeObj ? typeObj.typeName : defaultName;
    };

    const itemTypeName = getNameOrDefault(proportionData.itemType, "Default");
    const itemProportionsTypeName = getNameOrDefault(
      proportionData.itemProportionsType,
      "Default"
    );

    return {
      ...state,
      isAdding: true,
      isEditing: true,
      isEditingView: false,
      editItemId: _id,
      itemName,
      itemProportions: proportionData.itemProportions,
      itemProportionsType: itemProportionsTypeName,
      itemProportionsAmount: proportionData.itemProportionsAmount,
      itemProportionsAmountType: proportionData.itemProportionsAmountType,
      provider: myProviderName,
      itemLocation: myLocationName,
      itemCategory: myCategoryName,
      itemType: itemTypeName,
      itemKitchen: myKitchenName,
      itemAmount: proportionData.itemAmount,
      itemAmountType: proportionData.itemAmountType,
      price: proportionData.price,
      priceHistory: priceHistory,
      itemCode,
      itemNeeded,
      itemNeededType: itemNeededTypeName,
      itemLeftToOrder,
      itemLeftToOrderType: itemLeftToOrderTypeName,
      itemSupplier,
      itemCustomProportions: transformedCustomProportions,
      selectedAlternatives: newSelectedAlternatives,
    };
  }
  if (action.type === ADD_CUSTOM_PROPORTION) {
    if (state.itemCustomProportions.length < 3) {
      const newCustomProportion = {
        price: 0,
        itemType: "Default",
        itemProportions: "",
        itemProportionsType: "Default",
        itemProportionsAmount: 0,
        itemProportionsAmountType: "Other",
        itemAmount: 0,
        itemAmountType: "Other",
      };
      return {
        ...state,
        itemCustomProportions: [
          ...state.itemCustomProportions,
          newCustomProportion,
        ],
      };
    }
    return state;
  }
  if (action.type === HANDLE_CUSTOM_PROPORTION_CHANGE) {
    const { index, name, value } = action.payload;

    const updatedCustomProportions = [...state.itemCustomProportions];
    if (name in updatedCustomProportions[index]) {
      updatedCustomProportions[index][name] = value;
    }
    return {
      ...state,
      itemCustomProportions: updatedCustomProportions,
    };
  }
  if (action.type === REMOVE_CUSTOM_PROPORTION) {
    const updatedCustomProportions = state.itemCustomProportions.filter(
      (_, i) => i !== action.payload
    );
    return {
      ...state,
      itemCustomProportions: updatedCustomProportions,
    };
  }
  if (action.type === CHANGE_PROPORTIONS_BEGIN) {
    return { ...state, isLoading: true };
  }

  if (action.type === CHANGE_PROPORTIONS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Proportions Changed Successfully!",
    };
  }

  if (action.type === CHANGE_PROPORTIONS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === DELETE_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === DELETE_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item Deleted!",
    };
  }
  if (action.type === TOGGLE_DELETE_CONFIRMATION) {
    return { ...state, isDeleting: !state.isDeleting };
  }
  if (action.type === EDIT_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item Updated!",
    };
  }
  if (action.type === EDIT_ITEM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SOFT_DELETE_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SOFT_DELETE_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item moved to trash successfully",
    };
  }
  if (action.type === RESTORE_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === RESTORE_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item restored successfully",
    };
  }
  if (action.type === GET_TRASHED_ITEMS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_TRASHED_ITEMS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      trashedModels: action.payload.trashedModels,
      totalTrashedModels: action.payload.trashedModels.length,
      selectedTrashedModel: action.payload.model,
    };
  }

  if (action.type === CREATE_PROVIDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_PROVIDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Provider Created!",
    };
  }
  if (action.type === CREATE_PROVIDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_PROVIDERS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_PROVIDERS_SUCCESS) {
    const newProviderOptions = action.payload.providers.map((provider) => {
      return provider.providerName;
    });

    return {
      ...state,
      isLoading: false,
      providerOptions: newProviderOptions,
      providers: action.payload.providers,
      totalProviders: action.payload.totalProviders,
    };
  }

  if (action.type === SET_ADD_PROVIDER) {
    return {
      ...state,
      isAddingProvider: true,
    };
  }

  if (action.type === SET_EDIT_PROVIDER) {
    const provider = state.providers.find(
      (provider) => provider._id === action.payload.id
    );
    const {
      _id,
      providerName,
      number,
      notes,
      website,
      deliveryDay,
      deliveryTime,
      holiday,
      email,
      providerId,
      passwordInfo,
      minimumOrder,
      orderDate,
      representName,
    } = provider;

    return {
      ...state,
      isAddingProvider: true,
      isEditingProvider: true,
      editProviderId: _id,
      providerName,
      number,
      notes,
      website,
      deliveryDays: deliveryDay || [],
      deliveryTime,
      holiday,
      email,
      providerId,
      passwordInfo,
      minimumOrder,
      orderDate,
      representName,
    };
  }
  if (action.type === SET_DELIVERY_DAY) {
    const day = action.payload;
    const deliveryDays = state.deliveryDays || [];
    const exists = deliveryDays.includes(day);
    const newDays = exists
      ? deliveryDays.filter((d) => d !== day)
      : [...deliveryDays, day];
    return { ...state, deliveryDays: newDays };
  }

  if (action.type === DELETE_PROVIDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === DELETE_PROVIDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Provider Deleted!",
    };
  }
  if (action.type === EDIT_PROVIDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_PROVIDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Provider Updated!",
    };
  }
  if (action.type === EDIT_PROVIDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }

  if (action.type === CLEAR_FILTERS) {
    return {
      ...state,
      search: "",
      searchProvider: "all",
      searchType: "all",
      searchCategory: "all",
      searchLocation: "all",
      searchKitchen: "all",
      sort: "a-z",
    };
  }
  if (action.type === CREATE_ITEM_LOCATION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ITEM_LOCATION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Location Created!",
    };
  }
  if (action.type === CREATE_ITEM_LOCATION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEM_LOCATIONS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEM_LOCATIONS_SUCCESS) {
    const newItemLocationOptions = action.payload.itemLocations.map(
      (itemLocation) => {
        return itemLocation.locationName;
      }
    );
    return {
      ...state,
      isLoading: false,
      itemLocationOptions: newItemLocationOptions,
      itemLocations: action.payload.itemLocations,
      totalLocations: action.payload.totalLocations,
    };
  }

  if (action.type === SET_ADD_ITEM_LOCATION) {
    return {
      ...state,
      isAddingLocation: !state.isAddingLocation,
      locationName: "",
    };
  }

  if (action.type === DELETE_ITEM_LOCATION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_EDIT_ITEM_LOCATION) {
    const itemLocation = state.itemLocations.find(
      (itemLocation) => itemLocation._id === action.payload.id
    );
    const { _id, locationName } = itemLocation;
    return {
      ...state,
      isAddingLocation: true,
      isEditingLocation: true,
      editItemLocationId: _id,
      locationName,
    };
  }
  if (action.type === EDIT_ITEM_LOCATION_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ITEM_LOCATION_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "ItemLocation Updated!",
    };
  }
  if (action.type === EDIT_ITEM_LOCATION_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_ITEM_CATEGORY_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ITEM_CATEGORY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Category Created!",
    };
  }
  if (action.type === CREATE_ITEM_CATEGORY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEM_CATEGORIES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEM_CATEGORIES_SUCCESS) {
    const newItemCategoryOptions = action.payload.itemCategories.map(
      (itemCategory) => {
        return itemCategory.categoryName;
      }
    );
    return {
      ...state,
      isLoading: false,
      itemCategoryOptions: newItemCategoryOptions,
      itemCategories: action.payload.itemCategories,
      totalCategories: action.payload.totalCategories,
    };
  }

  if (action.type === SET_ADD_ITEM_CATEGORY) {
    return {
      ...state,
      isAddingCategory: !state.isAddingCategory,
      categoryName: "",
    };
  }
  if (action.type === DELETE_ITEM_CATEGORY_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_EDIT_ITEM_CATEGORY) {
    const itemCategory = state.itemCategories.find(
      (itemCategory) => itemCategory._id === action.payload.id
    );
    const { _id, categoryName } = itemCategory;
    return {
      ...state,
      isAddingCategory: true,
      isEditingCategory: true,
      editItemCategoryId: _id,
      categoryName,
    };
  }
  if (action.type === EDIT_ITEM_CATEGORY_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ITEM_CATEGORY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "ItemCategory Updated!",
    };
  }
  if (action.type === EDIT_ITEM_CATEGORY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_ITEM_TYPE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ITEM_TYPE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Type Created!",
    };
  }
  if (action.type === CREATE_ITEM_TYPE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEM_TYPES_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEM_TYPES_SUCCESS) {
    const newItemTypeOptions = action.payload.itemTypes.map((itemType) => {
      return itemType.typeName;
    });
    return {
      ...state,
      isLoading: false,
      itemTypeOptions: newItemTypeOptions,
      itemTypes: action.payload.itemTypes,
      totalTypes: action.payload.totalTypes,
    };
  }

  if (action.type === SET_ADD_ITEM_TYPE) {
    return {
      ...state,
      isAddingType: !state.isAddingType,
      typeName: "",
    };
  }
  if (action.type === DELETE_ITEM_TYPE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_EDIT_ITEM_TYPE) {
    const itemType = state.itemTypes.find(
      (itemType) => itemType._id === action.payload.id
    );
    const { _id, typeName } = itemType;
    return {
      ...state,
      isAddingType: true,
      isEditingType: true,
      editItemTypeId: _id,
      typeName,
    };
  }
  if (action.type === EDIT_ITEM_TYPE_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ITEM_TYPE_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "ItemType Updated!",
    };
  }
  if (action.type === EDIT_ITEM_TYPE_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_ITEM_KITCHEN_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ITEM_KITCHEN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Kitchen Created!",
    };
  }
  if (action.type === CREATE_ITEM_KITCHEN_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEM_KITCHENS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEM_KITCHENS_SUCCESS) {
    const newItemKitchenOptions = action.payload.itemKitchens.map(
      (itemKitchen) => {
        return itemKitchen.kitchenName;
      }
    );
    return {
      ...state,
      isLoading: false,
      itemKitchenOptions: newItemKitchenOptions,
      itemKitchens: action.payload.itemKitchens,
      totalKitchens: action.payload.totalKitchens,
    };
  }

  if (action.type === SET_ADD_ITEM_KITCHEN) {
    return {
      ...state,
      isAddingKitchen: !state.isAddingKitchen,
      kitchenName: "",
    };
  }
  if (action.type === DELETE_ITEM_KITCHEN_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_EDIT_ITEM_KITCHEN) {
    const itemKitchen = state.itemKitchens.find(
      (itemKitchen) => itemKitchen._id === action.payload.id
    );
    const { _id, kitchenName } = itemKitchen;
    return {
      ...state,
      isAddingKitchen: true,
      isEditingKitchen: true,
      editItemKitchenId: _id,
      kitchenName,
    };
  }
  if (action.type === EDIT_ITEM_KITCHEN_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ITEM_KITCHEN_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "ItemKitchen Updated!",
    };
  }
  if (action.type === EDIT_ITEM_KITCHEN_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_ORDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_ORDER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Order Created!",
    };
  }
  if (action.type === CREATE_ORDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ORDERS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ORDERS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      orders: action.payload.orders,
      totalOrders: action.payload.totalOrders,
    };
  }
  if (action.type === DELETE_ORDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SET_EDIT_ORDER) {
    const order = state.orders.find((order) => order._id === action.payload.id);
    const { _id } = order;
    return {
      ...state,
      isEditingOrder: true,
      editOrderId: _id,
    };
  }
  if (action.type === UPDATE_ORDER_ITEM_STATUS) {
    const newOrders = state.orders.map((order) => {
      if (order._id === action.payload.orderId) {
        const newItems = order.items.map((item) =>
          item._id === action.payload.itemId
            ? { ...item, status: action.payload.newStatus }
            : item
        );
        return { ...order, items: newItems };
      }
      return order;
    });
    return { ...state, orders: newOrders };
  }
  if (action.type === UPDATE_MISSING_NUMBER) {
    const newOrders = state.orders.map((order) => {
      if (order._id === action.payload.orderId) {
        const newItems = order.items.map((item) =>
          item._id === action.payload.itemId
            ? { ...item, missingNumber: action.payload.number }
            : item
        );
        return { ...order, items: newItems };
      }
      return order;
    });
    return { ...state, orders: newOrders };
  }
  if (action.type === EDIT_ORDER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_ORDER_SUCCESS) {
    return {
      ...state,
      isEditingOrder: false,
      editOrderId: "",
      orderName: "",
      orderNotes: "",
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Order Updated!",
    };
  }
  if (action.type === EDIT_ORDER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_CREATE_ORDER) {
    return { ...state, isCreatingOrder: !state.isCreatingOrder };
  }
  if (action.type === TOGGLE_SEND_ORDERS) {
    return { ...state, isSendingOrders: !state.isSendingOrders };
  }
  if (action.type === SEND_ORDERS_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SEND_ORDERS_SUCCESS) {
    const updatedOrders = state.orders.map((order) => {
      if (order._id === action.payload.orderId) {
        return { ...order, emailSentAt: action.payload.emailSentAt };
      }
      return order;
    });

    return {
      ...state,
      orders: updatedOrders,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SEND_ORDERS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_SCAN_ORDER_INVOICE) {
    return {
      ...state,
      isScanningOrderInvoice: !state.isAutoOrdering,
      scanningOrderId: action.payload.orderId,
    };
  }
  if (action.type === SCAN_INVOICE_BEGIN) {
    return { ...state, isScanningLoading: true };
  }
  if (action.type === SCAN_INVOICE_SUCCESS) {
    function extractNumber(str) {
      const numStr = String(str).replace(/[^0-9.-]/g, "");
      return parseFloat(numStr);
    }

    function parsePrice(price) {
      // Ensure the input is treated as a string to use string methods
      const priceStr = String(price);
      // Try to extract a floating-point number from the string
      const matches = priceStr.match(/[\d\.\-]+/);
      if (matches) {
        return parseFloat(matches[0]);
      }
      return NaN; // Return NaN if no number could be extracted
    }

    if (action.type === SCAN_INVOICE_SUCCESS) {
      const updatedItems = action.payload.items.map((item) => {
        const quantity = extractNumber(item.quantity);
        const totalPrice = parsePrice(item.total_price);
        let unitPrice = parsePrice(item.unit_price);

        // Check if the unit price includes non-numeric characters (indicating formatting) and is not a clean number
        if (isNaN(unitPrice) || /[^\d\.\-]/.test(String(item.unit_price))) {
          unitPrice = totalPrice / quantity; // Recalculate from total if needed
        }

        return {
          ...item,
          quantity: quantity,
          unit_price: unitPrice.toFixed(2),
          total_price: totalPrice.toFixed(2),
        };
      });

      return {
        ...state,
        isScanningLoading: false,
        invoiceItems: updatedItems,
        linkedItems: [],
      };
    }
  }
  if (action.type === SCAN_INVOICE_ERROR) {
    return {
      ...state,
      isScanningLoading: false,
      // showAlert: true,
      // alertType: "danger",
      // alertText: action.payload.msg,
    };
  }
  if (action.type === COMPARE_INVOICE_BEGIN) {
    return { ...state, isComparingLoading: true };
  }
  if (action.type === COMPARE_INVOICE_SUCCESS) {
    return {
      ...state,
      isComparingLoading: false,
      linkedItems: action.payload.linkedItems,
      //invoiceItems: action.payload.items,
      // showAlert: true,
      // alertType: "success",
      // alertText: "Order Updated!",
    };
  }

  if (action.type === COMPARE_INVOICE_ERROR) {
    return {
      ...state,
      isComparingLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === UPDATE_PRICES_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_PRICES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Items Updated!",
    };
  }
  if (action.type === UPDATE_PRICES_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_RESET_QUANTITIES) {
    return { ...state, isResettingQuantities: !state.isResettingQuantities };
  }
  if (action.type === TOGGLE_AUTO_ORDER) {
    return { ...state, isAutoOrdering: !state.isAutoOrdering };
  }
  if (action.type === UPDATE_ITEM_QUANTITY_BEGIN) {
    const item = state.items.find((item) => item._id === action.payload.id);
    const { itemQuantity } = item;
    return { ...state, isLoading: true, itemQuantity };
  }
  if (action.type === UPDATE_ITEM_QUANTITY_SUCCESS) {
    const newItems = [...state.items];
    const item = newItems.find((item) => item._id === action.payload.id);
    item.itemQuantity = action.payload.newItemQuantity;
    return {
      ...state,
      items: newItems,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Quantity Updated!",
    };
  }
  if (action.type === UPDATE_ITEM_QUANTITY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === UPDATE_ITEM_INVENTORY_QUANTITY_BEGIN) {
    const item = state.items.find((item) => item._id === action.payload.id);
    const { _id, itemInventoryQuantity } = item;
    return {
      ...state,
      isLoading: true,
      editItemId: _id,
      itemInventoryQuantity,
    };
  }
  if (action.type === UPDATE_ITEM_INVENTORY_QUANTITY_SUCCESS) {
    const newItems = [...state.items];
    const item = newItems.find((item) => item._id === action.payload.id);
    item.itemInventoryQuantity = action.payload.newItemInventoryQuantity;
    return {
      ...state,
      items: newItems,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Quantity Updated!",
    };
  }
  if (action.type === UPDATE_ITEM_INVENTORY_QUANTITY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === ADD_PENDING_CHANGE) {
    const existingChangeIndex = state.pendingChanges.findIndex(
      (change) =>
        change.item._id === action.payload.item._id &&
        change.type === action.payload.type
    );
    let updatedPendingChanges;
    if (existingChangeIndex !== -1) {
      updatedPendingChanges = [...state.pendingChanges];
      updatedPendingChanges[existingChangeIndex] = action.payload;
    } else {
      updatedPendingChanges = [...state.pendingChanges, action.payload];
    }
    return {
      ...state,
      pendingChanges: updatedPendingChanges,
    };
  }
  if (action.type === CLEAR_PENDING_CHANGES) {
    return {
      ...state,
      pendingChanges: [],
    };
  }
  if (action.type === SET_CONNECTION_STATUS) {
    return {
      ...state,
      connectionStatus: action.payload.status,
    };
  }

  // if (action.type === GET_ITEM_ALTERNATIVES_BEGIN) {
  //   return {
  //     ...state,
  //     isLoading: true,
  //     showAlert: false,
  //   };
  // }
  // if (action.type === GET_ITEM_ALTERNATIVES_SUCCESS) {
  //   const selectedAlternatives = state.selectedAlternatives;
  //   const fetchedAlternatives = action.payload.items;
  //   const updatedFetchedAlternatives = fetchedAlternatives.filter(
  //     (alternative) =>
  //       !selectedAlternatives.some(
  //         (selected) => selected._id === alternative._id
  //       )
  //   );

  //   return {
  //     ...state,
  //     isLoading: false,
  //     itemAlternatives: updatedFetchedAlternatives,
  //   };
  // }
  if (action.type === ADD_ITEM_ALTERNATIVE_BEGIN) {
    // Using a copied item to avoid direct reference issues
    const updatedSelectedAlternatives = [
      ...state.selectedAlternatives,
      action.payload.item,
    ];

    return {
      ...state,
      selectedAlternatives: updatedSelectedAlternatives,
    };
  }
  if (action.type === REMOVE_ITEM_ALTERNATIVE) {
    const selectedAlternatives = state.selectedAlternatives;
    const index = selectedAlternatives.indexOf(action.payload.item);
    if (index > -1) {
      selectedAlternatives.splice(index, 1);
    }
    return {
      ...state,
      selectedAlternatives: selectedAlternatives,
    };
  }
  if (action.type === TOGGLE_CONDENSED) {
    return { ...state, isCondensed: !state.isCondensed };
  }
  if (action.type === CREATE_MENU_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_MENU_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Menu Created!",
    };
  }
  if (action.type === CREATE_MENU_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_MENUS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_MENUS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      menus: action.payload.menus,
      totalMenus: action.payload.totalMenus,
    };
  }

  if (action.type === SET_ADD_MENU) {
    return {
      ...state,
      isAddingMenu: true,
    };
  }
  if (action.type === SET_EDIT_MENU) {
    const menu = state.menus.find((menu) => menu._id === action.payload.id);
    const { _id, menuName, menuDescription } = menu;
    return {
      ...state,
      isAddingMenu: true,
      isEditingMenu: true,
      editMenuId: _id,
      menuName,
      menuDescription,
    };
  }
  if (action.type === DELETE_MENU_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_MENU_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_MENU_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Menu Updated!",
    };
  }
  if (action.type === EDIT_MENU_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SET_MENU_ITEMS) {
    return {
      ...state,
      menuItemsId: action.payload.menuId,
    };
  }
  if (action.type === CREATE_MENU_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_MENU_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "MenuItem Created!",
    };
  }
  if (action.type === CREATE_MENU_ITEM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_MENU_ITEMS_BEGIN) {
    return {
      ...state,
      search: "",
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_MENU_ITEMS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      menuItems: action.payload.menuItems,
      totalMenuItems: action.payload.totalMenuItems,
    };
  }

  if (action.type === SET_ADD_MENU_ITEM) {
    return {
      ...state,
      isAddingMenuItem: true,
      menuItemError: state.errorPercentage || 0,
    };
  }
  if (action.type === SET_EDIT_MENU_ITEM) {
    const menuItem = state.menuItems.find(
      (menuItem) => menuItem._id === action.payload.id
    );

    const enrichedIngredients = menuItem.menuItemIngredients.map(
      (ingredient) => {
        if (ingredient.isMenuItem) {
          const menuItemDetail = state.menuItems.find(
            (mi) => mi._id === ingredient.menuItem
          );
          return {
            ...ingredient,
            name: menuItemDetail ? menuItemDetail.menuItemName : "",
          };
        } else {
          // Handle regular item ingredients
          const itemDetail = state.items.find(
            (item) => item._id === ingredient.item
          );
          return {
            ...ingredient,
            name: itemDetail ? itemDetail.itemName : "",
          };
        }
      }
    );

    const {
      _id,
      menuItemName,
      menuItemPrice,
      menuItemNotes,
      menuItemOrders,
      menuItemImage,
      menuItemError,
    } = menuItem;
    return {
      ...state,
      isAddingMenuItem: true,
      isEditingMenuItem: true,
      editMenuItemId: _id,
      menuItemName,
      menuItemPrice,
      menuItemNotes,
      menuItemImage,
      menuItemOrders,
      menuItemError,
      menuItemIngredients: enrichedIngredients,
    };
  }
  if (action.type === DELETE_MENU_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_MENU_ITEM_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === EDIT_MENU_ITEM_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "MenuItem Updated!",
    };
  }
  if (action.type === EDIT_MENU_ITEM_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === ADD_INGREDIENT_FIELD) {
    const { isMenuItem } = action.payload || { isMenuItem: false };
    return {
      ...state,
      menuItemIngredients: [
        ...state.menuItemIngredients,
        {
          item: null,
          menuItem: null,
          isMenuItem,
          ingredientQuantity: 0,
          ingredientQType: "Other",
          wastePercentage: 0,
        },
      ],
    };
  }

  if (action.type === REMOVE_INGREDIENT_FIELD) {
    const index = action.payload.index;
    return {
      ...state,
      menuItemIngredients: [
        ...state.menuItemIngredients.slice(0, index),
        ...state.menuItemIngredients.slice(index + 1),
      ],
    };
  }

  if (action.type === UPDATE_INGREDIENT_FIELD) {
    const { index, updatedField } = action.payload;
    const updatedIngredient = {
      ...state.menuItemIngredients[index],
      ...updatedField,
    };
    return {
      ...state,
      menuItemIngredients: [
        ...state.menuItemIngredients.slice(0, index),
        updatedIngredient,
        ...state.menuItemIngredients.slice(index + 1),
      ],
    };
  }
  // if (action.type === SEARCH_INGREDIENT_BEGIN) {
  //   return {
  //     ...state,
  //     isLoading: true,
  //     showAlert: false,
  //   };
  // }
  // if (action.type === SEARCH_INGREDIENT_SUCCESS) {
  //   return {
  //     ...state,
  //     isLoading: false,
  //     menuIngredients: action.payload.items,
  //   };
  // }
  // if (action.type === CLEAR_INGREDIENT_SEARCH_RESULTS) {
  //   return {
  //     ...state,
  //     menuIngredients: [],
  //   };
  // }
  if (action.type === SET_EDIT_VIEW) {
    return {
      ...state,
      isEditingView: true,
      isEditing: false,
      isAdding: false,
    };
  }

  if (action.type === CHANGE_PAGE) {
    return { ...state, page: action.payload.page };
  }
  if (action.type === CHANGE_TRASHED_PAGE) {
    return { ...state, trashedModelsPage: action.payload.page };
  }
  if (action.type === EDIT_VIEW) {
    const selectedToggle = action.payload;
    return {
      ...state,
      [selectedToggle]: !state[selectedToggle],
    };
  }
  if (action.type === RESET_VIEW) {
    return {
      ...state,
      viewItemName: true,
      viewItemPrice: true,
      viewItemNeeded: true,
      viewItemSupplier: true,
      viewItemAmount: true,
      viewItemCode: true,
      viewItemProvider: true,
      viewItemLocation: true,
      viewItemCategory: true,
      viewItemType: true,
      viewItemKitchen: true,
      viewItemTotal: true,
      viewItemInventoryQuantity: true,
    };
  }
  if (action.type === RESET_ITEMS_QUANTITY_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === RESET_ITEMS_QUANTITY_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item quantities have been reset",
    };
  }
  if (action.type === RESET_ITEMS_QUANTITY_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_ITEM_BACKUP_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_ITEM_BACKUP_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      itemBackup: action.payload.backup,
    };
  }
  if (action.type === RESTORE_ITEM_QUANTITIES_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === RESTORE_ITEM_QUANTITIES_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Item quantities have been restored",
    };
  }
  if (action.type === RESTORE_ITEM_QUANTITIES_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_EXPORT_DATA) {
    if (action.payload.exportContext === "orders") {
      return {
        ...state,
        isExportingData: true,
        exportContext: action.payload.exportContext,
        exportingOrderId: action.payload.data,
      };
    } else {
      return {
        ...state,
        isExportingData: !state.isExportingData,
        exportContext: action.payload.exportContext,
        exportingOrderId: "",
      };
    }
  }
  if (action.type === TOGGLE_EDIT_ERROR_PERCENTAGE) {
    return {
      ...state,
      isEditingErrorPercentage: !state.isEditingErrorPercentage,
    };
  }

  if (action.type === CREATE_USER_SETTINGS_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_USER_SETTINGS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "User Settings Created!",
    };
  }
  if (action.type === CREATE_USER_SETTINGS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_USER_SETTINGS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === GET_USER_SETTINGS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      userSettings: action.payload.settings,
      errorPercentage: action.payload.settings.errorPercentage,
    };
  }
  if (action.type === UPDATE_USER_SETTINGS_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_USER_SETTINGS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "User Settings Updated!",
      isEditingErrorPercentage: false,
    };
  }
  if (action.type === UPDATE_USER_SETTINGS_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === CREATE_LINKED_ACCOUNT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === CREATE_LINKED_ACCOUNT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Linked Account Created!",
    };
  }
  if (action.type === CREATE_LINKED_ACCOUNT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === GET_LINKED_ACCOUNTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === GET_LINKED_ACCOUNTS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      linkedAccounts: action.payload.linkedAccounts,
    };
  }
  if (action.type === UPDATE_LINKED_ACCOUNT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === UPDATE_LINKED_ACCOUNT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Linked Account Updated!",
    };
  }
  if (action.type === UPDATE_LINKED_ACCOUNT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === DELETE_LINKED_ACCOUNT_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === DELETE_LINKED_ACCOUNT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Linked Account Deleted!",
    };
  }
  if (action.type === DELETE_LINKED_ACCOUNT_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SET_LANGUAGE) {
    return { ...state, oauthToken: action.payload };
  }
  if (action.type === SET_OAUTH_TOKEN) {
    return { ...state, oauthToken: action.payload };
  }
  if (action.type === FETCH_REPORTS_BEGIN) {
    return {
      ...state,
      isLoading: true,
      showAlert: false,
    };
  }
  if (action.type === FETCH_REPORTS_SUCCESS) {
    return { ...state, isLoading: false, salesReports: action.payload };
  }
  if (action.type === FETCH_REPORTS_ERROR) {
    return {
      ...state,
      isLoading: false,
    };
  }

  throw new Error(`no such action :${action.type}`);
};
export default reducer;

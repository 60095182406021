import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const AddItemLocationContainer = () => {
  const { isAddingLocation, setAddItemLocation } = useAppContext();
  const { t } = useTranslation();
  return (
    <PermissionGuard permission="editItemLocations">
      <Wrapper>
        <button
          type="button"
          style={{ maxWidth: "10rem", marginLeft: "0.2rem" }}
          className={isAddingLocation ? `btn btn-danger` : `btn`}
          onClick={setAddItemLocation}
        >
          {t(isAddingLocation ? "Close" : "Add")}
        </button>
      </Wrapper>
    </PermissionGuard>
  );
};
export default AddItemLocationContainer;

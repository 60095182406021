//import main from "../assets/images/main.svg";
import Wrapper from "../assets/wrappers/LandingPage";
//import { Logo } from "../components";
import { Link } from "react-router-dom";
//import { Canvas } from "@react-three/fiber";
import logo from "../assets/images/logo_light.png";
import { useState, useEffect } from "react";
// import LandingImage from "../assets/images/landingStock.png";
// import featureImage from "../assets/images/Feature-image.png";
// import featureImage2 from "../assets/images/Feature-image2.png";
// import featureImage3 from "../assets/images/Feature-image3.png";
// import addItem from "../assets/images/addItem.png";
//import checkMark from "../assets/images/Checkmark.png";
import axios from "axios";
import { useTranslation } from "react-i18next";

//Landing Gif
//import frame from "../assets/images/frame.png";
import LandingVideo from "../assets/images/output.mp4";

// import aiKitchen from "../assets/images/aiKitchen.jpg";
// import aiKitchen2 from "../assets/images/aiKitchen2.jpg";

import { RxHamburgerMenu } from "react-icons/rx";
import { CgClose } from "react-icons/cg";

import { useInView } from "react-intersection-observer";

const FadeIn = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust threshold as needed
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
    >
      {children}
    </div>
  );
};

const Landing = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng") || "en"; // Default to English if no setting is found
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);

    const scrollToComponent = (id, offset = 0) => {
      const element = document.getElementById(id);
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };
    const switchLanguage = () => {
      const nextLng = i18n.language === "en" ? "fr" : "en";
      i18n.changeLanguage(nextLng);
      localStorage.setItem("i18nextLng", nextLng);
    };

    const currentLanguage = i18n.language === "en" ? "FR" : "EN";

    return (
      <div className="navbar-container">
        <div className={`navbar ${isOpen ? "open" : ""}`}>
          <img src={logo} alt="chumon" className="logo" />
          <div className="menu-icon" onClick={toggleMenu}>
            <div className={`icon ${isOpen ? "close" : "hamburger"}`}>
              {isOpen ? (
                <CgClose className="icon-style" />
              ) : (
                <RxHamburgerMenu className="icon-style" />
              )}
            </div>
          </div>
          <div className={`nav-links ${isOpen ? "open" : ""}`}>
            <button
              className="navbar-button"
              onClick={() => scrollToComponent("pricing", -50)}
            >
              {t("Pricing")}
            </button>
            <button
              className="navbar-button"
              onClick={() => scrollToComponent("contact", 100)}
            >
              {t("Contact")}
            </button>
            <Link to="/login" className="navbar-button-alt">
              {t("Login")}
            </Link>
            <button onClick={switchLanguage} className="navbar-button">
              {currentLanguage}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Feature = () => {
    const features = [
      {
        id: 1,
        name: t("Seamless Order Management"),
        image: "/assets/aiKitchen2.jpg",
        text: t("Seamless Order Management Text"),
      },
      {
        id: 2,
        name: t("Efficient Inventory Tracking"),
        image: "/assets/aiKitchen.jpg",
        text: t("Efficient Inventory Tracking Text"),
      },
      {
        id: 3,
        name: t("Automated Order Dispatch"),
        image: "/assets/Feature-image2.png",
        text: t("Automated Order Dispatch Text"),
      },
    ];

    const [selectedFeature, setSelectedFeature] = useState(features[0]);
    const [fade, setFade] = useState("fade-in");

    const handleFeatureClick = (feature) => {
      setFade("fade-out");
      setTimeout(() => {
        setSelectedFeature(feature);
        setFade("fade-in");
      }, 250);
    };

    return (
      <div className="feature-container">
        <div className="landing-text-header">
          <h3>{t("Effortless Kitchen Management")}</h3>
        </div>
        <div className="feature-buttons">
          {features.map((feature) => (
            <div
              key={feature.id}
              className={`feature-button ${
                selectedFeature.id === feature.id ? "active" : ""
              }`}
              onClick={() => handleFeatureClick(feature)}
            >
              {feature.name}
            </div>
          ))}
        </div>

        <div className={`feature-details ${fade}`}>
          <img
            src={`${selectedFeature.image}`}
            alt={selectedFeature.text}
            className="feature-image"
          />
          <p className="feature-text">{selectedFeature.text}</p>
        </div>
      </div>
    );
  };

  const Feature2 = () => {
    const features = [
      {
        id: 1,
        name: t("Flexible Item Management"),
        image: "/assets/Feature-image.png",
        text: t("Flexible Item Management Text"),
      },
      {
        id: 2,
        name: t("Cost Analysis Insights"),
        image: "/assets/Feature-image2.png",
        text: t("Cost Analysis Insights Text"),
      },
      {
        id: 3,
        name: t("Dynamic Dish Pricing"),
        image: "/assets/Feature-image3.png",
        text: t("Dynamic Dish Pricing Text"),
      },
    ];
    const [selectedFeature, setSelectedFeature] = useState(features[0]);
    const [fade, setFade] = useState("fade-in");

    const handleFeatureClick = (feature) => {
      setFade("fade-out");
      setTimeout(() => {
        setSelectedFeature(feature);
        setFade("fade-in");
      }, 250);
    };

    return (
      <div className="feature-container">
        <div className="landing-text-header">
          <h3>{t("Explore Our Advanced Capabilities")}</h3>
        </div>
        <div className="feature-buttons">
          {features.map((feature) => (
            <div
              key={feature.id}
              className={`feature-button ${
                selectedFeature.id === feature.id ? "active" : ""
              }`}
              onClick={() => handleFeatureClick(feature)}
            >
              {feature.name}
            </div>
          ))}
        </div>

        <div className={`feature-details ${fade}`}>
          <img
            src={`${selectedFeature.image}`}
            alt={selectedFeature.text}
            className="feature-image"
          />
          <p className="feature-text">{selectedFeature.text}</p>
        </div>
      </div>
    );
  };

  const Pricing = () => {
    const [pricing, setPricing] = useState("Monthly");
    const scrollToComponent = (id, offset = 0) => {
      const element = document.getElementById(id);
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };
    return (
      <div
        id="pricing"
        className="pricing-container"
        style={{ marginBottom: "10rem" }}
      >
        <div className="landing-text-header">
          <h3> {t("Flexible Pricing Plans")}</h3>
          <p>{t("flexible_pricing_description")}</p>
          {/* <h6 style={{ maxWidth: "768px" }} className="landing-description">
            {t("Flexible Pricing Description")}
          </h6> */}
          {/* <div className="toggle-buttons">
            <span className="pricing-type" style={{ marginRight: "1rem" }}>
              Monthly
            </span>
            <label className="switch">
              <input
                type="checkbox"
                onChange={() =>
                  setPricing(pricing === "Monthly" ? "Yearly" : "Monthly")
                }
                checked={pricing === "Yearly"}
              />
              <span className="slider"></span>
            </label>
            <span className="pricing-type">Yearly</span>
          </div> */}
        </div>

        <div className="pricing-table">
          <div className="pricing-group">
            <div className="pricing-plan">
              <h4>{t("starter_plan")}</h4>
              <p className="price">
                {pricing === "Monthly" ? "$54.99/mo" : "No yearly pricing"}
              </p>
              {pricing === "Yearly" && (
                <p className="billed-yearly">{t("Billed Yearly")}</p>
              )}
              <ul>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("starter_items")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("starter_orders")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("starter_providers")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("starter_alternatives")}
                </li>
              </ul>
              <button onClick={() => scrollToComponent("contact", 100)}>
                {t("get_started")}
              </button>
            </div>

            <div className="pricing-plan">
              <h4>{t("standard_plan")}</h4>
              <p className="price">
                {pricing === "Monthly" ? "$89.99/mo" : "$69.99/mo"}
              </p>
              {pricing === "Yearly" && (
                <p className="billed-yearly">Billed yearly</p>
              )}
              <ul>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("standard_items")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("standard_orders")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("standard_suggestive_ordering")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("standard_menu_costing")}
                </li>
              </ul>
              <button onClick={() => scrollToComponent("contact", 100)}>
                {t("get_started")}
              </button>
            </div>
          </div>
          <div className="pricing-group">
            <div className="pricing-plan">
              <h4>{t("advanced_plan")}</h4>
              <p className="price">
                {pricing === "Monthly" ? "$119.99/mo" : "$119.99/mo"}
              </p>
              {pricing === "Yearly" && (
                <p className="billed-yearly">Billed yearly</p>
              )}
              <ul>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("advanced_unlimited_items")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("advanced_pos_integration")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("advanced_ai_invoice_scanning")}
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  {t("advanced_automatic_inventory")}
                </li>
              </ul>
              <button style={{ width: "12rem" }} disabled>
                {t("coming_soon")}
              </button>
            </div>

            {/* <div className="pricing-plan">
              <h4>Premium Plan</h4>
              <p className="price">Contact us</p>
              <ul>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  Unlimited items
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  Unlimited Orders
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  All new integrations
                </li>
                <li>
                  <img
                    src="/assets/Checkmark.png"
                    alt="checkmark"
                    className="checkmark"
                  />
                  Modular Pricing
                </li>
              </ul>
              <button>Get started</button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const Contact = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
    });

    const [errors, setErrors] = useState({});
    const [emailSent, setEmailSent] = useState(false);

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value,
      });
      setErrors({
        ...errors,
        [e.target.id]: "",
      });
    };

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

    const validatePhone = (phone) => {
      const re = /^\d{3}-?\d{3}-?\d{4}$/;
      return re.test(String(phone));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      let validationErrors = {};
      if (!formData.name) {
        validationErrors.name = t("Name is required");
      }
      if (!formData.email) {
        validationErrors.email = t("Email is required");
      } else if (!validateEmail(formData.email)) {
        validationErrors.email = t("Invalid email format");
      }
      if (!formData.phone) {
        validationErrors.phone = t("Phone number is required");
      } else if (!validatePhone(formData.phone)) {
        validationErrors.phone = t("Invalid phone number format");
      }

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }

      try {
        await axios.post("/api/contact", formData);
        setEmailSent(true);
      } catch (error) {
        console.error("Error sending email", error);
        setErrors({ form: t("Error sending email. Please try again later.") });
      }
    };

    return (
      <div id="contact" style={{ padding: "0 0.5rem", paddingBottom: "8rem" }}>
        <div className="landing-text-header" style={{ marginTop: "10rem" }}>
          <h3>
            <span className="header-span">{t("Let's get you set up")}</span>
          </h3>
          <h6 style={{ margin: "0.25rem" }} className="landing-description">
            {t("Reach out to our experts for a seamless setup experience.")}
          </h6>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">{t("Your name or company?")}</label>
              <input
                type="text"
                id="name"
                placeholder={t("Your name or company?")}
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{t("Enter your email")}</label>
              <input
                type="email"
                id="email"
                placeholder={t("Enter your email")}
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t("Enter your phone number")}</label>
              <div className="phone-input-container">
                <select>
                  <option value="ca">Canada (+1)</option>
                  <option value="us">USA (+1)</option>
                </select>
                <input
                  type="tel"
                  id="phone"
                  placeholder={t("Enter your phone number")}
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              {errors.phone && (
                <span className="error-message">{errors.phone}</span>
              )}
            </div>

            {!emailSent ? (
              <button type="submit">{t("Talk to an expert")}</button>
            ) : (
              <h6 className="expert-reach-out landing-description">
                {t("Your message has been sent successfully.")}
              </h6>
            )}
          </form>
          <h6 className="expert-reach-out landing-description">
            {t("An expert will reach out to you shortly.")}
          </h6>
        </div>
      </div>
    );
  };

  const Footer = () => {
    const scrollToComponent = (id, offset = 0) => {
      const element = document.getElementById(id);
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };
    return (
      <footer className="footer">
        <div className="footer-top">
          <div className="footer-group">
            {/* <div className="footer-section">
              <ul className="footer-list">
                <li style={{ marginBottom: "0.25rem" }}>
                  <a href="/pricing" className="footer-link">
                    <span> Pricing</span>
                  </a>
                </li>
                <li>
                  <a href="/website-demo" className="footer-link">
                    Website Demo
                  </a>
                </li>
                <li>
                  <a href="/ordering-demo" className="footer-link">
                    Ordering Demo
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="footer-section">
              <ul className="footer-list">
                <li style={{ marginBottom: "0.25rem" }}>
                  <a className="footer-link">
                    <span>{t("Learn more")}</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => scrollToComponent("pricing", -50)}
                    className="footer-link"
                  >
                    {t("Pricing")}
                  </a>
                </li>
                {/* <li>
                  <a href="/support" className="footer-link">
                    Support
                  </a>
                </li> */}
                {/* <li>
                  <a href="/register" className="footer-link">
                    Register
                  </a>
                </li> */}
                <li>
                  <Link to="/login" className="footer-link">
                    {t("Login")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-section">
            <a
              onClick={() => scrollToComponent("contact", 100)}
              className="footer-button"
            >
              {t("Schedule a call")}
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <img src={logo} alt="chumon" className="logo" />
          <span className="footer-copyright">chumon.ca 2025©</span>
        </div>
      </footer>
    );
  };

  return (
    <Wrapper>
      <Navbar />
      <div className="landing-container">
        <FadeIn>
          <div className="landing-text-header">
            <h1>
              <span className="header-span">
                {t("Kitchen Management Tools")}{" "}
              </span>
              <br />
              {t("for Every Corner of Your Kitchen")}
            </h1>
            <h6 className="landing-description">{t("Chumon Description")}</h6>
            <video autoPlay loop muted playsInline className="landing-image">
              <source src={LandingVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </FadeIn>
        <FadeIn>
          <Feature />
        </FadeIn>
        <FadeIn>
          <div
            className="landing-text-header"
            style={{ marginTop: "10rem", padding: "0 0.5rem" }}
          >
            <div className="landing-group">
              <div className="landing-group-text">
                <h3>
                  <span className="header-span">
                    {t("Simple and Intuitive Interface")}
                  </span>
                </h3>
                <p>{t("Interface Text")}</p>
              </div>
              <div className="landing-group-images">
                <img
                  src="/assets/landingStock.png"
                  alt="landing-ui"
                  className="landing-image2-1"
                />
                <img
                  src="/assets/AddItem.png"
                  alt="landing-ui"
                  className="landing-image2"
                />
              </div>
            </div>
          </div>
        </FadeIn>
        <FadeIn>
          <Feature2 />
        </FadeIn>
        <FadeIn>
          <Pricing />
        </FadeIn>
        <FadeIn>
          <Contact />
        </FadeIn>
      </div>
      <Footer />
    </Wrapper>
  );
  //   return (
  //     <Wrapper>
  //       <nav>
  //         <Logo />
  //       </nav>
  //       <div className="container page">
  //         <div className="info">
  //           <h1>
  //             kitchen{" "}
  //             <span style={{ color: "#0055aa", fontWeight: "800" }}>
  //               managing
  //             </span>{" "}
  //             app
  //           </h1>
  //           <p>
  //             <span style={{ color: "#0055aa", fontWeight: "800" }}>Chumon</span>{" "}
  //             is a kitchen managing app, it allows to manage your restaurant's
  //             inventory and ordering process as well as many other parts of the
  //             kitchen's daily tasks
  //           </p>
  //           <Link to="/login" className="btn btn-hero">
  //             Login
  //           </Link>
  //         </div>
  //         <img src={main} alt="job hunt" className="img main-img" />
  //         {/* <div style={{ width: "20rem", height: "20rem", justifySelf: "center" }}>
  //            <Canvas flat>
  //              <Ramen />
  //            </Canvas>
  //          </div> */}
  //       </div>
  //     </Wrapper>
  //   );
  // };
};
export default Landing;

import styled from "styled-components";

const Wrapper = styled.section`
  background: var(--white);
  border-radius: 10px;
  box-shadow: var(--shadow-2);
  margin-bottom: 0.75rem;
  padding: 1rem;
  cursor: pointer;

  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--shadow-3);
  }

  .menu-item-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .menu-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 1rem;
    flex-wrap: wrap;
  }

  .title-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1; /* Takes remaining space */
  }

  h2 {
    font-weight: 800;
    font-size: 1.5rem;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu-metrics {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    align-items: center;
  }

  .menu-metrics h3,
  .menu-metrics h4,
  .menu-metrics h5,
  .menu-metrics h6 {
    margin: 0;
    font-size: 1rem;
    white-space: nowrap;
  }

  .menu-item-info {
    margin-top: 0.5rem;
  }

  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    background: var(--green-light);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin: 0.5rem;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:hover {
      background: var(--green-dark);
    }
  }

  .menu-item-content {
    padding: 0.75rem 0;
    border-top: 1px solid var(--grey-200);
    margin-top: 0.5rem;

    h4 {
      margin-bottom: 0.5rem;
    }
  }

  .ingredient-details {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 0.75rem;
  }

  .menuitem-ingredient {
    background-color: var(--primary-50);
    border-left: 3px solid var(--primary-500);
  }

  .ingredient {
    background: var(--grey-50);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    box-shadow: var(--shadow-1);
    display: flex;
    flex-direction: row; /* Align content horizontally inside each ingredient */
    justify-content: space-between; /* Space between elements inside */
    align-items: center;
    gap: 1rem;
    min-height: 3rem;
    transition: background 0.3s ease;
    cursor: pointer;
  }

  .ingredient:hover {
    background: var(--grey-100); /* Add a hover effect */
  }

  .ingredient span {
    font-size: 1rem;
    color: var(--grey-800);
    font-weight: 500;
    white-space: nowrap; /* Prevent text wrapping */
  }

  .ingredient span.waste-high {
    color: var(--red-dark);
    font-weight: bold;
  }

  .ingredient span.waste-low {
    color: var(--green-dark);
    font-weight: bold;
  }

  @media (max-width: 600px) {
    .ingredient {
      flex-wrap: wrap;
    }

    .ingredient span {
      white-space: normal; /* Allow text to wrap on smaller screens */
      text-align: left;
      font-size: 0.95rem; /* Slightly smaller font */
    }

    .menu-item-header {
      padding: 0.75rem; /* Reduce padding for compact layout */
    }
  }
`;

export default Wrapper;

import Wrapper from "../../assets/wrappers/AddItemContainer";
import { useAppContext } from "../../context/appContext";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const AddItemContainer = () => {
  const { t } = useTranslation();
  const { isEditing, clearValues, isAdding, setAddItem } = useAppContext();

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0.5rem",
        }}
      >
        <PermissionGuard permission="editItems">
          <button
            type="button"
            style={{
              maxWidth: "10rem",
              marginLeft: "0.2rem",
              marginRight: "1rem",
            }}
            className={isEditing || isAdding ? `btn btn-danger ` : `btn`}
            onClick={!isAdding ? () => setAddItem() : () => clearValues()}
          >
            {t(isEditing || isAdding ? "Close" : "Add")}
          </button>
        </PermissionGuard>
      </div>
    </Wrapper>
  );
};
export default AddItemContainer;

import React, { useState, useEffect, useRef } from "react";
import Wrapper from "../../assets/wrappers/Orders";
import OrderItemDetail from "./OrderItemDetail";
import { useAppContext } from "../../context/appContext";
import { FaEdit, FaCheck } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { LuFileScan } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../../components/PermissionGuard";

const OrderPreview = ({ order }) => {
  const { t } = useTranslation();
  const {
    softDeleteItem,
    editOrder,
    isEditingOrder,
    editOrderId,
    orderName,
    orderNotes,
    orderDeliveryDay,
    handleChange,
    toggleExportData,
    providers,
    toggleScanOrderInvoice,
  } = useAppContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const orderRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (orderRef.current && !orderRef.current.contains(event.target)) {
        handleChange({ name: "isEditingOrder", value: false });
        handleChange({ name: "editOrderId", value: null });
      }
    };

    if (isEditingOrder && editOrderId === order._id) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditingOrder, editOrderId, handleChange]);

  const toggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    if (isEditingOrder && editOrderId === order._id) {
      editOrder(order._id);
      handleChange({ name: "isEditingOrder", value: false });
      handleChange({ name: "editOrderId", value: null });
    } else {
      handleChange({ name: "orderName", value: order.orderName });
      handleChange({ name: "orderNotes", value: order.notes });
      handleChange({ name: "orderDeliveryDay", value: order.orderDeliveryDay });
      handleChange({ name: "isEditingOrder", value: true });
      handleChange({ name: "editOrderId", value: order._id });
    }
  };

  const handleOrderNameChange = (e) => {
    e.stopPropagation();
    handleChange({ name: "orderName", value: e.target.value });
  };

  const handleNotesChange = (e) => {
    e.stopPropagation();
    handleChange({ name: "orderNotes", value: e.target.value });
  };

  const handleChangeDeliveryDay = (day) => {
    handleChange({ name: "orderDeliveryDay", value: day });
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const formatDateForExport = (date) => {
    return new Date(date)
      .toLocaleDateString("en-CA", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");
  };

  const provider = providers.find((p) => p._id === order.providerId);
  const statusColor = order.status === "Completed" ? "#4CAF50" : "#FFC107";
  const backorderedItemsCount = order.items.filter(
    (item) => item.status === "backordered"
  ).length;
  const missingItemsCount = order.items.filter(
    (item) => item.status === "missing"
  ).length;

  return (
    <Wrapper>
      <div
        className={`order-card ${isExpanded ? "expanded" : ""}`}
        onClick={toggleExpand}
        ref={orderRef}
      >
        <div className="order-card-header">
          <div className="order-name-container">
            {isEditingOrder && editOrderId === order._id ? (
              <PermissionGuard
                permission="editOrders"
                fallback={
                  <h4 className="order-name-edit">{order.orderName}</h4>
                }
              >
                <input
                  type="text"
                  value={orderName}
                  onChange={handleOrderNameChange}
                  onClick={handleInputClick}
                  className="order-name-edit"
                />
              </PermissionGuard>
            ) : (
              <h4 className="order-name-edit">{order.orderName}</h4>
            )}
            <PermissionGuard permission="editOrders" fallback={null}>
              <button
                type="button"
                style={{ background: "none", border: "none" }}
                onClick={handleEditClick}
              >
                {isEditingOrder && editOrderId === order._id ? (
                  <FaCheck className="order-edit-icon" />
                ) : (
                  <FaEdit className="order-edit-icon" />
                )}
              </button>
            </PermissionGuard>
          </div>
          {order.emailSentAt && (
            <div className="subscription-status" style={{ color: "green" }}>
              <IoMdCheckmarkCircleOutline style={{ verticalAlign: "middle" }} />
              {t("order sent on")}
              {formatDateForExport(order.emailSentAt)}
            </div>
          )}
          <div className="order-card-details">
            <PermissionGuard
              permission="viewItemPrices"
              fallback={
                <p>
                  {t("Items")}: {order.items.length}
                </p>
              }
            >
              <p>
                {t("Total Cost")}: $
                {typeof order.totalCost === "number"
                  ? order.totalCost.toFixed(2)
                  : order.totalCost}
              </p>
              <p>
                {t("Items")}: {order.items.length}
              </p>
            </PermissionGuard>
            {backorderedItemsCount > 0 && (
              <p style={{ color: "#FFA07A" }}>
                {t("Backordered")}: {backorderedItemsCount}
              </p>
            )}
            {missingItemsCount > 0 && (
              <p style={{ color: "#DC143C" }}>
                {t("Missing")}: {missingItemsCount}
              </p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "20rem",
            }}
          >
            {!isEditingOrder || editOrderId !== order._id
              ? order.orderDeliveryDay &&
                order.orderDeliveryDay !== "None" && (
                  <div className="delivery-day-icon">
                    {t(order.orderDeliveryDay)}
                  </div>
                )
              : provider && (
                  <PermissionGuard
                    permission="editOrders"
                    fallback={
                      <div className="delivery-day-icon">
                        {t(order.orderDeliveryDay || "None")}
                      </div>
                    }
                  >
                    <div className="day-selector-container">
                      {provider &&
                        provider.deliveryDay.map((day) => (
                          <button
                            key={day}
                            type="button"
                            className={`day-selector-button ${
                              orderDeliveryDay === day ? "selected" : ""
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChangeDeliveryDay(day);
                            }}
                          >
                            {t(day)}
                          </button>
                        ))}
                    </div>
                  </PermissionGuard>
                )}
            <span>{new Date(order.createdAt).toLocaleDateString()}</span>
            <PermissionGuard permission="viewOrders" fallback={null}>
              <button
                className="no-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleExportData("orders", order._id);
                }}
              >
                <TbFileExport style={{ height: "1.65rem", width: "1.65rem" }} />
              </button>
            </PermissionGuard>
            <PermissionGuard permission="viewItemPrices" fallback={null}>
              <button
                className="no-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleScanOrderInvoice(order._id);
                }}
              >
                <LuFileScan style={{ height: "1.5rem", width: "1.5rem" }} />
              </button>
            </PermissionGuard>
            <h5 style={{ color: statusColor, margin: "0" }}>
              {t(order.status)}
            </h5>
          </div>
        </div>

        <div className="order-card-items">
          {isExpanded &&
            order.items.map((item, index) => (
              <OrderItemDetail key={index} item={item} orderId={order._id} />
            ))}
          {isEditingOrder && editOrderId === order._id ? (
            <PermissionGuard
              permission="editOrders"
              fallback={
                <p style={{ margin: "0" }}>
                  {t("Notes")}: {order.notes}
                </p>
              }
            >
              <input
                type="text"
                value={orderNotes}
                onChange={handleNotesChange}
                onClick={handleInputClick}
                className="order-name-edit"
                placeholder={t("Add notes here")}
              />
            </PermissionGuard>
          ) : (
            <p style={{ margin: "0" }}>
              {t("Notes")}: {order.notes}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <PermissionGuard permission="editOrders" fallback={null}>
              <button
                type="button"
                className="btn delete-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  softDeleteItem("order", order._id);
                }}
              >
                {t("Move to Trash")}
              </button>
            </PermissionGuard>
            <h5 style={{ margin: "0 0.5rem" }}>
              {t("Last updated on")}:{" "}
              {new Date(order.updatedAt).toLocaleDateString()}
            </h5>
            <PermissionGuard permission="editOrders" fallback={null}>
              <button
                type="button"
                className="btn btn-green"
                onClick={(e) => {
                  e.stopPropagation();
                  editOrder(order._id);
                }}
              >
                {t("Save changes")}
              </button>
            </PermissionGuard>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default OrderPreview;

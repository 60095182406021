import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Provider";
import {
  FaExternalLinkAlt,
  FaEnvelope,
  FaPhone,
  FaRegStickyNote,
} from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard";

const Provider = ({ provider }) => {
  const { t } = useTranslation();
  const { setEditProvider, deliveryDaysOptions } = useAppContext();

  return (
    <Wrapper>
      <div className="provider-card">
        <div className="provider-header">
          <h2>{provider.providerName}</h2>
          <div className="provider-contact">
            {provider.website && (
              <div>
                <FaPhone /> {provider.number}
                <a
                  href={provider.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaExternalLinkAlt
                    style={{ marginLeft: "0.5rem" }}
                    className="external-link-icon"
                  />
                </a>
              </div>
            )}
          </div>

          <div className="title-container">
            <PermissionGuard permission="editProviders">
              <button
                type="button"
                className="edit-btn"
                onClick={() => setEditProvider(provider._id)}
              >
                <MdEdit />
              </button>
            </PermissionGuard>
          </div>
        </div>

        <div className="provider-details">
          <div className="delivery-days">
            {deliveryDaysOptions.map((day) => (
              <span
                key={day}
                className={`day ${
                  provider.deliveryDay.includes(day) ? "selected" : ""
                }`}
              >
                {t(day)}
              </span>
            ))}
          </div>

          {provider.deliveryTime && (
            <p className="highlighted">
              {t("Delivery Time")}: {provider.deliveryTime}
            </p>
          )}
          {provider.email && (
            <p>
              <FaEnvelope /> {provider.email}
            </p>
          )}
          {provider.providerId && (
            <p>
              {t("ID")}: {provider.providerId}
            </p>
          )}
          {provider.passwordInfo && (
            <p>
              {t("Password Info")}: {provider.passwordInfo}
            </p>
          )}
          {provider.minimumOrder > 0 && (
            <p>
              {t("Minimum Order")}: ${provider.minimumOrder}
            </p>
          )}
          {provider.orderDate && (
            <p>
              {t("Order Date")}: {provider.orderDate}
            </p>
          )}
          {provider.representName && (
            <p>
              {t("Representative Name")}: {provider.representName}
            </p>
          )}
          {provider.holiday && (
            <p>
              {t("Closed on")}: {provider.holiday}
            </p>
          )}
          {provider.notes && (
            <p>
              <FaRegStickyNote /> {t("Notes")}: {provider.notes}
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Provider;

import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.5rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: var(--shadow-2);
  overflow: hidden;

  h4,
  h5 {
    margin-bottom: 0.5rem;
    text-transform: none;
  }

  .reset-quantities-container {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.25rem -1rem 1.25rem;
  }

  .reset-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .title-icon {
    color: var(--primary-500);
    font-size: 1.2rem;
  }

  .reset-title {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-500);
  }

  .close-reset {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background: var(--red-light);
    transition: var(--transition);

    &:hover {
      background: var(--red-dark);
    }
  }

  .reset-section,
  .backup-section {
    background-color: var(--grey-50);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 1rem;
    color: var(--primary-600);
    margin-top: 0;
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .backup-date {
    font-size: 0.85rem;
    color: var(--grey-600);
  }

  .backup-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
  }

  .backup-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .info-label {
    font-size: 0.875rem;
    color: var(--grey-600);
  }

  .info-value {
    font-weight: 600;
    color: var(--grey-800);
    margin-left: 0.5rem;
  }

  .export-checks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin: 1rem 0 0.5rem 0;
  }

  .check-option {
    background-color: var(--white);
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    transition: var(--transition);

    &:hover:not(.disabled) {
      border-color: var(--primary-300);
      background-color: var(--primary-50);
    }

    &.disabled {
      opacity: 0.7;
      background-color: var(--grey-100);
    }
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    input[type="checkbox"] {
      margin: 0;
      cursor: pointer;
      accent-color: var(--primary-500);
    }

    span {
      font-size: 0.9rem;
    }
  }

  .restore-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--green-light);

    &:hover {
      background: var(--green-dark);
      color: var(--white);
    }
  }

  .no-backup-message {
    color: var(--grey-500);
    font-style: italic;
    margin: 0.5rem 0;
  }

  .export-confirm-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 0.5rem;
    border-top: 1px solid var(--grey-200);
  }

  .item-count {
    font-size: 0.875rem;
    color: var(--grey-600);

    span {
      font-weight: 600;
      color: var(--grey-800);
    }
  }

  .export-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--white);
    padding: 0.5rem 1rem;
    font-weight: 500;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background: var(--grey-400);
    }
  }
`;

export default Wrapper;

import styled from "styled-components";

const Wrapper = styled.main`
  background: var(--black-background);

  .navbar-container {
    display: flex;
    justify-content: center;

    position: fixed;

    min-width: 100vw;
    background: var(--black-background);
    height: 5rem;
    z-index: 1000;
  }
  .navbar {
    font-family: "Inter", sans-serif;

    top: 0;
    width: 100%;
    max-width: 1120px;
    background: var(--black-background);
    padding: 0.75rem 1rem;
    //box-shadow: var(--shadow-1);

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 5rem;
    transition: background 0.5s ease-in-out;
  }
  .navbar.open {
    background: var(--brand-blue);
  }
  .navbar-button {
    background-color: transparent;
    border: 1px solid var(--brand-light-blue);
    color: var(--brand-light-blue);
    border-radius: 8px;
    margin-right: 1rem;
    padding: 0.5rem 0.75rem;
  }
  .navbar-button:hover {
    background-color: var(--brand-light-blue);
    color: #1a1a1a;
    cursor: pointer;
  }

  .navbar-button-alt {
    background-color: var(--brand-light-blue);
    border: 1px solid var(--brand-light-blue);
    color: #ffffff;
    border-radius: 8px;
    margin-right: 1rem;
    padding: 0.5rem 0.75rem;
  }

  .logo {
    width: 10rem;
    height: auto;
    margin-top: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  .logo.open {
    justify-self: center;
  }
  .menu-icon {
    display: none;
    cursor: pointer;
    height: 1.75rem;
    transition: transform 0.3s ease-in-out;
  }

  .icon-style {
    width: 2rem;
    height: 2rem;
    color: white;
  }
  .icon {
    transition: transform 0.3s ease-in-out;
  }

  .icon.hamburger {
    transform: rotate(0deg);
  }

  .icon.close {
    transform: rotate(90deg);
    margin: -0.25rem 0.25rem 0 0;
  }

  .nav-links {
    display: flex;
    align-items: center;
  }

  .nav-link {
    color: black;
    text-decoration: none;
  }
  .landing-login {
    padding: 0.375rem 0.75rem;

    width: 4.5rem;
    height: 2rem;
  }

  @media (max-width: 768px) {
    .navbar {
      height: 6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .menu-icon {
      display: block;
    }

    .nav-links {
      display: block;
      position: absolute;
      top: 5rem;
      left: 0;
      width: 100%;
      background: var(--brand-blue);
      overflow: hidden;
      height: 0;
      transition: height 0.5s ease-in-out;
    }

    .nav-links.open {
      height: 12.5rem;
      //height: 9.5rem;
    }

    .navbar .logo {
      transition: left 0.5s ease-in-out;
    }
    .navbar.open .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .navbar.open .menu-icon {
      position: absolute;
      right: 1rem;
    }

    .navbar-button,
    .navbar-button-alt {
      display: block;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #ccc;
      border-radius: 0px;
      border: none;
      color: #ffffff;
    }
    .navbar-button-alt {
      font-weight: 700;
    }
  }
  .landing-container {
    padding-top: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    @media (max-width: 768px) {
      padding-top: 7rem;
    }
  }

  .landing-text-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 6rem;

    width: 100%;

    color: #ffffff;
    padding: 0 0.5rem;
    h1 {
      color: #ffffff;
      font-size: 2.25rem;
      font-weight: 700;
      text-transform: none;
    }
    h3 {
      color: #ffffff;
      font-size: 2.25rem;
      font-weight: 700;
      text-transform: none;
    }
    p {
      color: #ffffff;
      font-family: "Inter", sans-serif;
    }
    @media (min-width: 768px) {
      padding: 2rem;

      h1 {
        font-size: 3rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
  .header-span {
    color: var(--brand-light-blue);
  }
  .landing-description {
    margin: 0.25rem;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .landing-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0 12rem;
    }
  }
  .landing-group-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      line-height: 2rem;
    }
    @media (min-width: 992px) {
      text-align: left;
      max-width: 25rem;
      // margin-left: 4rem;
    }
  }

  .landing-group-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    @media (min-width: 992px) {
      //margin: 0 -2rem;
    }
  }
  .landing-image {
    margin-top: 2rem;
    max-width: 100%;
    border-radius: 10px;

    z-index: 10;
    @media (min-width: 992px) {
      max-width: 60%;
    }
  }
  .landing-image2 {
    margin-top: 1rem;
    width: 75%;

    border-radius: 10px;

    @media (min-width: 768px) {
      margin-top: -1rem;

      width: 60%;
    }
    @media (min-width: 992px) {
      margin-top: -1rem;

      width: 40%;
    }
  }
  .landing-image2-1 {
    margin-top: 2rem;
    width: 95%;
    border-radius: 10px;

    z-index: 10;

    @media (min-width: 992px) {
      width: 75%;
    }
  }
  .feature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    color: #ffffff;
    text-align: center;
    max-width: 100%;
  }
  .feature-buttons {
    max-width: 100%;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
  }
  .feature-button {
    background-color: rgba(28, 91, 152, 0.5);
    white-space: nowrap;
    font-family: "Inter", sans-serif;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.25rem 1.25rem;
    margin: 0.5rem;
    transition: background-color 0.3s;
    flex-shrink: 0;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }
  .feature-button.active,
  .feature-button:hover {
    background-color: #1c5b98;
  }
  .feature-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    text-align: center;
    margin-top: 0.5rem;
    max-width: 1000px;
    height: auto;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;

      flex-wrap: nowrap;
      height: 300px;
    }
  }
  .feature-details.fade-out {
    opacity: 0;
  }

  .feature-details.fade-in {
    opacity: 1;
  }

  .feature-image {
    width: 100%;
    max-width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 10px;
    border-radius: 15px;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 350px;
      height: 300px;
      border-radius: 15px;
    }
  }

  .feature-text {
    font-family: "Inter", sans-serif;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.125rem;

    color: #ffffff;
    width: auto;
    max-width: 400px;
    word-wrap: break-word;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      max-width: fit-content;
    }
  }

  .feature-title {
    color: #333;
  }

  .feature-description {
    color: #666;
  }

  //PRICING
  .pricing-container {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .toggle-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .toggle-buttons .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 1rem;
  }

  .toggle-buttons .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-buttons .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .toggle-buttons .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  .toggle-buttons input:checked + .slider {
    background-color: #007bff;
  }

  .toggle-buttons input:checked + .slider:before {
    transform: translateX(26px);
  }
  .pricing-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .pricing-group {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .pricing-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: rgba(28, 91, 152, 0.5);
    padding: 1.5rem;
    border-radius: 20px;
    margin: 1rem 1rem;
    width: 100%;
    max-width: 400px;
    min-width: 360px;
    text-align: center;
    box-shadow: var(--shadow-2);
    @media (max-width: 768px) {
      margin: 1rem 0;
    }
  }

  .pricing-plan h4 {
    font-family: "Inter", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
  }

  .pricing-plan p.price {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--brand-light-blue);
    font-weight: 700;
    margin-top: 0.5rem;
    line-height: 2rem;
  }

  .pricing-plan p.billed-yearly {
    font-size: 1rem;
    margin: 0;
    color: white;
  }

  .pricing-plan ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    align-self: auto;
  }

  .pricing-plan ul li {
    font-family: "Inter", sans-serif;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .pricing-plan button {
    background-color: var(--brand-light-blue);
    font-weight: 600;
    color: #fff;
    padding: 0.5rem 2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 10rem;

    align-self: center;
  }
  .price-text {
    color: #ffffff;
  }
  .checkmark {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  //CONTACT FORM
  .contact-form {
    width: 25rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .form-group {
    margin: 1.75rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .form-group label {
    display: block;
    margin-bottom: 0.25rem;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 0.75rem;
    height: 3.5rem;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  .error-message {
    color: red;
  }

  .phone-input-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .phone-input-container select {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .phone-input-container input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  button[type="submit"] {
    padding: 0.75rem 1.5rem;
    background-color: #50bfff;
    border: none;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  .expert-reach-out {
    margin-top: 1.5rem;
  }
  //Footer
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    border-top: 1px solid rgb(145, 145, 145);
    color: #fff;
    font-family: "Inter", sans-serif;
    @media (min-width: 768px) {
      padding: 1rem 2rem;
    }
    @media (min-width: 992px) {
      padding: 1rem 5rem;
    }
  }
  .footer-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    @media (min-width: 768px) {
      padding: 0rem 2rem;
    }
    @media (min-width: 992px) {
      padding: 1rem 0rem;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .footer-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    @media (min-width: 992px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .footer-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 1rem;
  }

  .footer-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      color: #919191;
      font-weight: 900;
    }
    li {
      font-weight: 300;
    }
    @media (min-width: 992px) {
      padding: 1rem 0rem;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .footer-link {
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .footer-link:hover {
    text-decoration: underline;
  }

  .footer-button {
    padding: 10px 20px;
    border: 1px solid var(--brand-light-blue);
    color: #fff;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    max-width: 14rem;
    font-weight: 700;
  }

  .footer-button:hover {
    background-color: #fff;
    color: #1a1a1a;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 1rem;
    height: 10rem;

    @media (min-width: 768px) {
      padding: 0;
      height: 8rem;
    }
    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 1.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .footer-logo {
    font-size: 20px;
    font-weight: bold;
  }

  .footer-copyright {
    font-size: 14px;
  }

  //OLD COMPONENT
  nav {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
    height: var(--nav-height);
    display: flex;
    align-items: center;
  }
  .page {
    min-height: calc(100vh - var(--nav-height));
    display: grid;
    align-items: center;
  }
  h1 {
    font-weight: 700;
    span {
      color: var(--primary-500);
    }
  }
  p {
    color: var(--grey-600);
  }
  .main-img {
    display: none;
  }
  @media (min-width: 992px) {
    .page {
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
    }
    .main-img {
      display: block;
      width: 20rem;
    }
  }
  .fade-in-section {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    width: 100%;
  }

  .fade-in-section.is-visible {
    opacity: 1;
  }

  .feature-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .feature-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .feature-exit {
    opacity: 1;
    transform: scale(1);
  }
  .feature-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;
export default Wrapper;

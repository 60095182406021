import { FormRow } from "..";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/AddItem";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { FaTimes } from "react-icons/fa";

const AddMenu = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAddingMenu,
    isEditingMenu,
    displayAlert,
    menuName,
    menuDescription,
    handleChange,
    createMenu,
    editMenu,
    deleteMenu,
    editMenuId,
    getMenuItems,
    menuItems,
    clearValues,
  } = useAppContext();

  const [canDeleteMenu, setCanDeleteMenu] = useState(true);
  const [deleteReason, setDeleteReason] = useState("");
  const [linkedMenuItemsCount, setLinkedMenuItemsCount] = useState(0);

  useEffect(() => {
    getMenuItems();
  }, []);

  useEffect(() => {
    const checkMenuDeletionEligibility = () => {
      const linkedMenuItems = menuItems.filter(
        (item) => item.menu.toString() === editMenuId
      );

      setLinkedMenuItemsCount(linkedMenuItems.length);

      if (linkedMenuItems.length > 0) {
        setCanDeleteMenu(false);
        setDeleteReason(t("Menu has linked menu items."));
      } else {
        setCanDeleteMenu(true);
        setDeleteReason("");
      }
    };

    if (isEditingMenu) {
      checkMenuDeletionEligibility();
    }
  }, [isEditingMenu, menuItems, editMenuId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!menuName) {
      displayAlert();
      return;
    }
    if (isEditingMenu) {
      editMenu();
      return;
    }
    createMenu();
  };

  const handleMenuInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  const handleDeleteMenu = () => {
    if (canDeleteMenu) {
      deleteMenu(editMenuId);
    } else {
      console.log("Deletion not allowed due to linked menu items.");
    }
  };

  const tooltipContent = !canDeleteMenu
    ? t("menuDeletionWarning", {
        count: linkedMenuItemsCount,
      })
    : "";

  return (
    <Wrapper>
      {isAddingMenu && (
        <div className="edit-backdrop" onClick={clearValues}></div>
      )}
      <form className={`${isAddingMenu ? "form_active form_fixed" : "form"}`}>
        <div className="addItem-group">
          <h3>{isEditingMenu ? t("edit menu") : t("add menu")}</h3>
          <button
            className="btn btn-danger"
            type="button"
            style={{
              width: "2rem",
              height: "2rem",
              padding: "0.5rem 0.5rem",
              alignSelf: "flex-start",
            }}
            onClick={(e) => {
              e.preventDefault();
              clearValues();
            }}
          >
            <FaTimes />
          </button>
        </div>
        <div className="form-center">
          <FormRow
            type="text"
            name="menuName"
            value={menuName}
            handleChange={handleMenuInput}
            labelText={t("Menu Name")}
          />
          <FormRow
            type="text"
            name="menuDescription"
            value={menuDescription}
            handleChange={handleMenuInput}
            labelText={t("Menu Description")}
          />
          <div className="btn-container">
            {isEditingMenu && (
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={handleDeleteMenu}
                  disabled={!canDeleteMenu}
                  style={{
                    backgroundColor: canDeleteMenu
                      ? "var(--red-light)"
                      : "var(--grey-600)",
                    color: "white",
                    cursor: canDeleteMenu ? "pointer" : "not-allowed",
                    opacity: canDeleteMenu ? 1 : 0.7,
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {t("Delete")}
                </button>
                {!canDeleteMenu && (
                  <Tippy content={tooltipContent}>
                    <div style={{ width: "2rem" }}>
                      <BsQuestionCircle
                        style={{ marginTop: "1.25rem", marginLeft: "0.5rem" }}
                        className="info-icon"
                      />
                    </div>
                  </Tippy>
                )}
              </div>
            )}
            <button
              type="submit"
              className="btn btn-block submit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t("submit")}
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddMenu;

import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/EditView";
import { useTranslation } from "react-i18next";
import { FaEye, FaUndoAlt } from "react-icons/fa";

const EditView = () => {
  const { t } = useTranslation();
  const {
    isEditingView,
    editView,
    isCondensed,
    viewItemPrice,
    viewItemNeeded,
    viewItemSupplier,
    viewItemAmount,
    viewItemCode,
    viewItemProvider,
    viewItemLocation,
    viewItemCategory,
    viewItemType,
    viewItemInventoryQuantity,
    viewItemKitchen,
    viewItemTotal,
    resetView,
  } = useAppContext();

  // Group view options by category for better organization
  const viewOptionGroups = {
    itemDetails: [
      { label: "Type", viewKey: "viewItemType" },
      { label: "Category", viewKey: "viewItemCategory" },
      { label: "Code", viewKey: "viewItemCode" },
      { label: "Location", viewKey: "viewItemLocation" },
    ],
    supplierInfo: [
      { label: "Supplier", viewKey: "viewItemSupplier" },
      { label: "Provider", viewKey: "viewItemProvider" },
    ],
    quantityInfo: [
      { label: "Amount", viewKey: "viewItemAmount" },
      { label: "Inventory", viewKey: "viewItemInventoryQuantity" },
      { label: "Needed", viewKey: "viewItemNeeded" },
    ],
    priceInfo: [
      { label: "Price", viewKey: "viewItemPrice" },
      { label: "Total", viewKey: "viewItemTotal" },
    ],
  };

  const getStateValue = (viewKey) => {
    const stateValues = {
      viewItemType,
      viewItemSupplier,
      viewItemProvider,
      viewItemCode,
      viewItemPrice,
      viewItemLocation,
      viewItemCategory,
      viewItemAmount,
      viewItemInventoryQuantity,
      viewItemNeeded,
      viewItemTotal,
    };
    return stateValues[viewKey];
  };

  if (isEditingView && isCondensed) {
    return (
      <Wrapper>
        <div className="edit-view-header">
          <div className="title-container">
            <FaEye className="title-icon" />
            <h4 className="view-title">{t("Customize Table View")}</h4>
          </div>
        </div>

        <div className="edit-view-content">
          {Object.entries(viewOptionGroups).map(([groupKey, options]) => (
            <div className="view-option-group" key={groupKey}>
              <h5 className="group-title">
                {t(groupNameMap[groupKey] || groupKey)}
              </h5>

              <div className="export-checks-container">
                {options.map(({ label, viewKey }) => (
                  <div
                    className={`check-option ${
                      getStateValue(viewKey) ? "selected" : ""
                    }`}
                    key={viewKey}
                    onClick={() => editView(viewKey)}
                  >
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={getStateValue(viewKey)}
                        onChange={() => editView(viewKey)}
                      />
                      <span>{t(label)}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="view-actions">
            <button className="btn reset-button" onClick={resetView}>
              <FaUndoAlt /> {t("Reset to Default")}
            </button>
          </div>
        </div>
      </Wrapper>
    );
  }

  return null;
};

// Map for group display names
const groupNameMap = {
  itemDetails: "Item Details",
  supplierInfo: "Supplier Information",
  quantityInfo: "Quantity Fields",
  priceInfo: "Price Information",
};

export default EditView;

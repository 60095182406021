import { FaTimes } from "react-icons/fa";
import { FormRow, FormRowSelect } from "..";
import { useTranslation } from "react-i18next";

const MenuItemIngredientList = ({
  items,
  menuItemIngredients,
  menuItems,
  activeIngredientIndex,
  setActiveIngredientIndex,
  filteredMenuIngredients,
  updateIngredientField,
  handleSearchChange,
  handleIngredientChange,
  handleSelectIngredient,
  removeIngredientField,
  defaultIngredientAmountTypeOptions,
  isMenuItemValid,
  currentEditMenuItemId, // Add this prop
}) => {
  const { t } = useTranslation();

  const convertToBaseUnit = (quantity, type) => {
    switch (type) {
      case "L":
        return quantity * 1000;
      case "ml":
        return quantity;
      case "Kg":
        return quantity * 1000;
      case "g":
        return quantity;
      case "Oz":
        return quantity * 28.35;
      case "lb":
        return quantity * 453.592;
      case "unit":
        return quantity;
      default:
        return quantity;
    }
  };

  const getTotalMenuItemCost = (menuItem, visitedIds = new Set()) => {
    if (!menuItem || !menuItem.menuItemIngredients) return 0;

    // Cache computed costs to avoid recalculation due to recursive calls
    if (!window.menuItemCostCache) {
      window.menuItemCostCache = {};
    }

    // Check for circular references
    if (visitedIds.has(menuItem._id)) {
      console.warn(
        `Circular reference detected in menu item: ${menuItem.menuItemName}`
      );
      return 0;
    }

    // Create new set of visited IDs to track the path
    const newVisitedIds = new Set(visitedIds);
    newVisitedIds.add(menuItem._id);

    // If already computed and not in a circular reference path, return the cached result
    const cacheKey = menuItem._id;
    if (window.menuItemCostCache[cacheKey] && visitedIds.size === 0) {
      return window.menuItemCostCache[cacheKey];
    }

    const totalCost = menuItem.menuItemIngredients.reduce((acc, ingredient) => {
      let cost = 0;
      if (ingredient.isMenuItem) {
        const subMenuItem = menuItems.find(
          (mi) => mi._id === ingredient.menuItem
        );
        if (subMenuItem) {
          // For MenuItem ingredients, calculate cost based on quantity
          const subMenuCost = computeIngredientCost(
            ingredient,
            subMenuItem,
            newVisitedIds
          );
          // Remove the $ sign and convert to number - use parseFloat for higher precision
          cost = Number(subMenuCost.replace("$", ""));
        }
      } else {
        const item = items.find((item) => item._id === ingredient.item);
        if (!item) return acc;

        const ingredientBaseQuantity = convertToBaseUnit(
          ingredient.ingredientQuantity,
          ingredient.ingredientQType
        );
        const itemBaseAmount = convertToBaseUnit(
          item.itemAmount,
          item.itemAmountType
        );

        if (itemBaseAmount === 0) return acc;

        const factor = ingredientBaseQuantity / itemBaseAmount;
        cost = item.price * factor;

        // Apply waste factor directly here
        const wasteFactor = 1 + ingredient.wastePercentage / 100;
        cost *= wasteFactor;
      }

      // Use full precision for addition
      return acc + cost;
    }, 0);

    // Only cache if not part of a circular reference path
    if (visitedIds.size === 0) {
      window.menuItemCostCache[cacheKey] = totalCost;
    }

    return totalCost;
  };

  const computeIngredientCost = (
    ingredient,
    menuItem,
    visitedIds = new Set()
  ) => {
    if (!menuItem || !menuItem.menuItemIngredients) return "0.000$";

    if (ingredient.ingredientQType === "portion") {
      const menuItemTotalCost = getTotalMenuItemCost(menuItem, visitedIds);
      const perOrderCost =
        menuItem.menuItemOrders > 0
          ? menuItemTotalCost / menuItem.menuItemOrders
          : 0;

      const wasteFactor = 1 + ingredient.wastePercentage / 100;
      const finalCost =
        perOrderCost * ingredient.ingredientQuantity * wasteFactor;

      return finalCost.toFixed(3) + "$";
    }

    const menuItemTotalCost = getTotalMenuItemCost(menuItem, visitedIds);

    const quantityIngredients = menuItem.menuItemIngredients.filter(
      (ing) => ing.ingredientQType !== "unit"
    );

    if (quantityIngredients.length === 0) {
      if (ingredient.ingredientQType === "unit") {
        const totalUnits = menuItem.menuItemIngredients.reduce(
          (sum, ing) => sum + (ing.ingredientQuantity || 0),
          0
        );
        const costPerUnit = menuItemTotalCost / Math.max(totalUnits, 1);

        const wasteFactor = 1 + ingredient.wastePercentage / 100;
        const finalCost =
          costPerUnit * ingredient.ingredientQuantity * wasteFactor;

        return finalCost.toFixed(3) + "$";
      }
      return "0.000$";
    }

    if (ingredient.ingredientQType === "unit") {
      return "0.000$";
    }

    const totalQuantity = quantityIngredients.reduce(
      (sum, ing) =>
        sum + convertToBaseUnit(ing.ingredientQuantity, ing.ingredientQType),
      0
    );
    const requestedQuantity = convertToBaseUnit(
      ingredient.ingredientQuantity,
      ingredient.ingredientQType
    );

    const proportion =
      totalQuantity > 0 ? requestedQuantity / totalQuantity : 0;

    // Multiply full cost by that proportion
    const baseCost = menuItemTotalCost * proportion;

    const wasteFactor = 1 + ingredient.wastePercentage / 100;
    // Removed errorFactor
    const finalCost = baseCost * wasteFactor;

    return finalCost.toFixed(3) + "$";
  };

  const calculateTotalQuantities = (ingredients, items) => {
    let totalGrams = 0;
    let totalMl = 0;
    let totalUnits = 0;
    let totalPortions = 0;

    // Instead of recursively processing ingredients, we'll only show direct ingredients
    ingredients.forEach((ing) => {
      if (ing.ingredientQType === "portion") {
        totalPortions += ing.ingredientQuantity;
        return;
      }

      const baseQuantity = convertToBaseUnit(
        ing.ingredientQuantity,
        ing.ingredientQType
      );

      // Group by type (no recursion)
      if (["g", "Kg", "lb", "Oz"].includes(ing.ingredientQType)) {
        totalGrams += baseQuantity;
      } else if (["ml", "L"].includes(ing.ingredientQType)) {
        totalMl += baseQuantity;
      } else if (ing.ingredientQType === "unit") {
        totalUnits += ing.ingredientQuantity;
      }
    });

    // Convert ml to g (1ml = 1g approximately)
    const totalMass = totalGrams + totalMl;

    const results = [];

    if (totalMass > 0) {
      if (totalMass >= 1000) {
        results.push(`${(totalMass / 1000).toFixed(2)}Kg`);
      } else {
        results.push(`${totalMass.toFixed(2)}g`);
      }
    }

    if (totalUnits > 0) {
      results.push(`${totalUnits} ${totalUnits === 1 ? "unit" : "units"}`);
    }

    if (totalPortions > 0) {
      results.push(
        `${totalPortions} ${totalPortions === 1 ? "portion" : "portions"}`
      );
    }

    return results.join(", ");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {menuItemIngredients.filter((ing) => ing.isMenuItem).length > 0 && (
        <h5 style={{ margin: "0", marginTop: "0.25rem" }}>
          {t("MenuItem Ingredients:")}
        </h5>
      )}
      {menuItemIngredients
        .filter((ing) => ing.isMenuItem)
        .map((ingredient, index) => {
          const selectedMenuItem = menuItems.find(
            (menuItem) => menuItem._id === ingredient.menuItem
          );

          return (
            <div key={index} className="ingredient-row">
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    margin: "1rem 1rem 0 0",
                    fontWeight: "600",
                  }}
                >
                  {index + 1}
                </span>
                <div className="search-container">
                  <FormRow
                    type="text"
                    name={`MenuItem ingredient`}
                    labelText={t("MenuItem Ingredient")}
                    value={ingredient.name || ""}
                    className={
                      isMenuItemValid(ingredient.name) ? "valid" : "invalid"
                    }
                    handleChange={(e) => {
                      updateIngredientField(index, {
                        name: e.target.value,
                        isMenuItem: true,
                      });
                      setActiveIngredientIndex(index);
                      handleSearchChange(e);
                    }}
                  />

                  {activeIngredientIndex === index &&
                    filteredMenuIngredients?.length > 0 && (
                      <div className="menuIngredients-list">
                        {/* Filter out the current menu item to prevent circular reference */}
                        {filteredMenuIngredients
                          .filter(
                            (menuItem) => menuItem._id !== currentEditMenuItemId
                          )
                          .slice(0, 5)
                          .map((menuItem) => (
                            <div
                              key={menuItem._id}
                              className="search-item"
                              onClick={() => {
                                handleSelectIngredient(menuItem._id, index);
                                updateIngredientField(index, {
                                  menuItem: menuItem._id,
                                  name: menuItem.menuItemName,
                                  isMenuItem: true,
                                });
                              }}
                            >
                              <span>{menuItem.menuItemName}</span>
                              <span
                                style={{
                                  color: "var(--primary-400)",
                                  marginLeft: "8px",
                                }}
                              >
                                {menuItem.menuItemPrice}$
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                </div>
              </div>
              {selectedMenuItem && (
                <div className="item-details">
                  <p>
                    {calculateTotalQuantities(
                      selectedMenuItem.menuItemIngredients,
                      items
                    )}{" "}
                    - {getTotalMenuItemCost(selectedMenuItem).toFixed(3)}$
                  </p>
                </div>
              )}

              <div className="ingredient-quantity-row">
                <FormRow
                  type="number"
                  name="Quantity"
                  labelText={t("Quantity")}
                  value={ingredient.ingredientQuantity || 1}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "ingredientQuantity",
                      e.target.value
                    )
                  }
                  className="ingredient-formRow"
                />
                <FormRowSelect
                  labelText={t("Type")}
                  name="QuantityType"
                  value={ingredient.ingredientQType || "unit"}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "ingredientQType",
                      e.target.value
                    )
                  }
                  list={defaultIngredientAmountTypeOptions}
                  className="ingredient-formRow"
                />
                <FormRow
                  type="number"
                  labelText={t("Waste %")}
                  name={`wastePercentage-${index}`}
                  value={ingredient.wastePercentage || 0}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "wastePercentage",
                      e.target.value
                    )
                  }
                  className="ingredient-formRow"
                />
                <h5
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    marginLeft: "0.25rem",
                    marginBottom: "0.25rem",
                    width: "auto",
                    maxWidth: "12rem",
                    alignSelf: "end",
                  }}
                >
                  {selectedMenuItem
                    ? computeIngredientCost(ingredient, selectedMenuItem)
                    : "Select MenuItem"}
                </h5>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={() => removeIngredientField(index)}
                  style={{
                    width: "2rem",
                    paddingLeft: "0.5rem",
                    alignSelf: "flex-end",
                  }}
                >
                  <FaTimes style={{ width: "1rem" }} />
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MenuItemIngredientList;

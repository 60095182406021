import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing, Error, Register, ProtectedRoute } from "./pages";
import PermissionRoute from "./components/PermissionRoute";
import {
  Order,
  Provider,
  Menu,
  Dashboard,
  SharedLayout,
  Profile,
  MenuItems,
  Trash,
  Orders,
  Sales,
  MissingInfo,
  Support,
} from "./pages/dashboard";
import { OAuthCallback } from "./components";
import "./i18n";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <PermissionRoute permission="viewDashboard">
                <Dashboard />
              </PermissionRoute>
            }
          />
          <Route
            path="orders"
            element={
              <PermissionRoute permission="viewOrders">
                <Orders />
              </PermissionRoute>
            }
          />
          <Route
            path="missing-info"
            element={
              <PermissionRoute permission="viewDashboard">
                <MissingInfo />
              </PermissionRoute>
            }
          />
          <Route
            path="sales"
            element={
              <PermissionRoute permission="viewDashboard">
                <Sales />
              </PermissionRoute>
            }
          />
          <Route
            path="stock"
            element={
              <PermissionRoute permission="viewItems">
                <Order />
              </PermissionRoute>
            }
          />
          <Route path="provider" element={<Provider />} />
          <Route
            path="menu"
            element={
              <PermissionRoute permission="viewMenus">
                <Menu />
              </PermissionRoute>
            }
          />
          <Route
            path="menu/:id"
            element={
              <PermissionRoute permission="viewMenus">
                <MenuItems />
              </PermissionRoute>
            }
          />
          <Route
            path="trash"
            element={
              <PermissionRoute permission="editItems">
                <Trash />
              </PermissionRoute>
            }
          />
          <Route path="profile" element={<Profile />} />
          <Route path="support" element={<Support />} />
          <Route path="/oauth-callback" element={<OAuthCallback />} />
        </Route>
        <Route path="/login" element={<Register />}></Route>
        <Route path="/landing" element={<Landing />}></Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import styled from "styled-components";

const Wrapper = styled.article`
  background: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-2);
  margin: 0.5rem;
  overflow: hidden;

  .active {
    background-color: var(--grey-100);
    border-radius: 10px;
  }
  .tooltip {
    position: fixed;
    z-index: 1000;
    background-color: #333;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 0.875rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden; /* Start with tooltip not visible */
    transition: opacity 0.3s, visibility 0.3s; /* Smooth transitions for fade-in/out */
    transform: translate(-50%, -100%);
    pointer-events: none;
  }

  .tooltip.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0s;
  }

  header {
    padding: 0.5rem;
    font-weight: 900;
    border: none;
    border-bottom: 1px solid var(--grey-100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h5 {
      letter-spacing: 0;
      font-size: 1.25rem;

      @media (min-width: 768px) {
        font-size: 1.4rem;
      }
    }
    p {
      font-size: 1rem;
      padding: 0;
      @media (min-width: 768px) {
        font-size: 0.85rem;
      }
    }
    @media (min-width: 768px) {
      padding: 0.5rem;
    }
  }
  .header-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    h6 {
      margin: 0rem 0.5rem 0rem 0.5rem;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
    h5,
    p:hover {
      cursor: pointer;
    }
    h5 {
      margin-bottom: 0rem;
      font-weight: 800;
      margin-right: 0.5rem;
    }
    p {
      text-transform: capitalize;
      color: var(--grey-400);
      letter-spacing: var(--letterSpacing);

      margin: 0rem 0rem;
    }
    .itemProportions {
      color: var(--grey-600);
      margin-right: 0.5rem;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .main-icon {
    min-width: 30px;
    min-height: 30px;
    padding: 0.1rem;
    display: grid;
    place-items: center;
    background: var(--primary-500);
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
  }
  .loop-prop {
    height: 1.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .loop-prop:hover {
    cursor: pointer;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;
    margin: 0rem 0rem 0rem 0.25rem;
  }

  .info23 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    width: 17.5rem;

    @media (min-width: 768px) {
      width: auto;
      justify-content: flex-end;
    }
  }
  .info2 {
    border-radius: 5px;

    p {
      font-weight: 900;
      color: var(--primary-400);
    }
  }
  .info3 {
    display: flex;
    flex-direction: flex;
    justify-content: space-between;
    width: auto;
    p {
      color: var(--green-light);
      margin-left: 0.25rem;
    }

    @media (min-width: 768px) {
      p {
        margin-left: 0.75rem;
      }

      justify-self: flex-end;
    }
  }

  .pending {
    background: #fcefc7;
    color: #e9b949;
  }
  .interview {
    background: #e0e8f9;
    color: #647acb;
  }
  .declined {
    color: #d66a6a;
    background: #ffeeee;
  }
  .content {
    padding: 0.5rem;
    padding-left: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 576px) {
      margin: 0rem 1rem;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: 768px) {
      margin: 0;
      padding-left: 0.5rem;
    }
    @media (min-width: 992px) {
      margin: 0rem 0.75rem;
      padding: 0.2rem;
    }
  }

  .content-center {
    display: flex;
    flex-direction: row;
    margin: 0rem 0.5rem;

    @media (min-width: 576px) {
      margin: 0;
      min-width: 10rem;
    }
    @media (min-width: 768px) {
      min-width: 34rem;
    }

    @media (min-width: 992px) {
      min-width: 52rem;
    }
    @media (min-width: 1200px) {
      min-width: 47.5rem;
    }
    @media (min-width: 1400px) {
      min-width: 68rem;
    }
  }
  .content-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    font-weight: 500;

    @media (min-width: 576px) {
    }

    @media (min-width: 768px) {
      min-width: 12.5rem;
    }
    @media (min-width: 992px) {
      flex-direction: row;
      min-width: 25rem;
      justify-content: space-evenly;
      align-items: center;
      max-width: 25rem;
    }
    @media (min-width: 1400px) {
      justify-content: flex-start;
      min-width: 30rem;
    }
  }
  .content-checkbox {
    margin: auto;
    flex-wrap: wrap;
    p {
      padding: 0;
      margin: 0;
      inset-inline: initial;
    }
    @media (min-width: 576px) {
      margin-left: 0.5rem;
    }
    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: 1rem;
    }

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .checkbox-padder {
    @media (min-width: 1200px) {
      width: 1rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0.5rem;

    @media (min-width: 576px) {
      margin-left: 0.5rem;
    }
    @media (min-width: 768px) {
      margin-left: auto;

      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .edit-btn,
  .delete-btn {
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
    height: 30px;
  }
  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    background: var(--green-light);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin: 0.5rem;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:hover {
      background: var(--green-dark);
    }
  }
  .delete-btn {
    color: var(--white);
    background: var(--red-light);
    @media (min-width: 576px) {
      margin-left: 0.75rem;
    }
    :hover {
      background: var(--red-dark);
    }
  }
  .item-footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.75rem;
    margin: 0.25rem;
  }
  .item-total {
    margin-right: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    @media (min-width: 992px) {
      margin-right: 0.5rem;
    }
    @media (min-width: 1312px) {
      margin-right: 1.5rem;
    }
  }
  .item-needed-quantity {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: 576px) {
      flex-direction: column;
    }
  }
  .item-needed {
    margin-right: 0.5rem;

    @media (min-width: 768px) {
      margin-right: 0;
    }
    @media (min-width: 992px) {
      margin-right: 0;
    }
  }
  .item-inventory-quantity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 992px) {
      margin-left: auto;
      justify-content: flex-end;
      align-items: right;
    }
  }

  .item-quantity {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: 992px) {
      margin-left: auto;
      justify-content: flex-end;
      align-items: right;
    }
  }
  .item-actions-alternative {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-left: auto;
    justify-content: center;
    align-items: right;
  }
  .item-quantity-alternative {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (min-width: 768px) {
      margin-left: 0.5rem;
    }
  }
  .item-alternative-container {
    padding: 0.5rem;
    width: 100%;
    font-weight: 900;
    border: none;
    border-bottom: 1px solid var(--grey-100);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .item-deleted-message {
    color: var(--red-light);
  }
  .quantity {
    margin: 0rem 0.5rem;
    justify-content: center;
  }

  &:hover .actions {
    visibility: visible;
  }
  .deleted-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;

    border-radius: 4px;

    h4,
    h5,
    h6,
    p {
      margin: 0;
      margin-right: 0.5rem;
    }
    h5 {
      font-weight: 900;
      color: var(--primary-400);
    }

    p {
      font-weight: 900;
      color: var(--green-light);
    }
    h6 {
      font-size: 1rem;
    }
    @media (min-width: 576px) {
      flex-wrap: nowrap;
    }
  }
  .deleted-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }
`;

export default Wrapper;

import { AiFillEdit } from "react-icons/ai";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Location";
import PermissionGuard from "../PermissionGuard";

const ItemLocation = ({ itemLocation }) => {
  const { setEditItemLocation } = useAppContext();

  return (
    <Wrapper>
      <div className="item-location">
        <b>{itemLocation.locationName}</b>
        <PermissionGuard permission="editItemLocations">
          {itemLocation.locationName !== "Default" && (
            <button
              className="item-location-button"
              onClick={() => setEditItemLocation(itemLocation._id)}
            >
              <AiFillEdit />
            </button>
          )}
        </PermissionGuard>
      </div>
    </Wrapper>
  );
};

export default ItemLocation;

import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.5rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: var(--shadow-2);
  overflow: hidden;

  h4,
  h5 {
    margin-bottom: 0.5rem;
    text-transform: none;
  }
  div {
    text-transform: none;
  }

  .export-container {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    padding-bottom: 0;
  }

  .export-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .export-title {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-500);
  }

  .close-export {
    width: 2rem;
    height: 2rem;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background: var(--red-light);
    transition: var(--transition);

    &:hover {
      background: var(--red-dark);
    }
  }

  .export-name-section {
    margin-bottom: 1.25rem;
  }

  .form-label {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    color: var(--grey-700);
  }

  .export-name-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--grey-300);
    border-radius: 4px;
    transition: var(--transition);

    &:focus {
      outline: none;
      border-color: var(--primary-500);
      box-shadow: 0 0 0 1px var(--primary-500);
    }
  }

  .export-section {
    background-color: var(--grey-50);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 1rem;
    color: var(--primary-600);
    margin-top: 0;
  }

  .section-subtitle {
    font-size: 0.9rem;
    color: var(--grey-700);
    margin: 1rem 0 0.5rem 0;
  }

  .export-format-selection {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }

  .format-option-label {
    margin-right: 1rem;
    font-weight: 500;
  }

  .format-options {
    display: flex;
    gap: 0.75rem;
  }

  .format-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 0.75rem;
    background: var(--grey-200);
    color: var(--grey-700);
    border-radius: 4px;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      background: var(--grey-300);
    }

    &.selected {
      background: var(--primary-500);
      color: white;
    }
  }

  .item-filtering-section {
    margin: 1rem 0;
    padding-top: 0.5rem;
    border-top: 1px solid var(--grey-200);
  }

  .export-checks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  .check-option {
    background-color: var(--white);
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    transition: var(--transition);

    &:hover:not(.disabled) {
      border-color: var(--primary-300);
      background-color: var(--primary-50);
    }

    &.disabled {
      opacity: 0.7;
      background-color: var(--grey-100);
    }
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    input[type="checkbox"] {
      margin: 0;
      cursor: pointer;
    }
  }

  .export-confirm-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 0.5rem;
    border-top: 1px solid var(--grey-200);
  }

  .item-count {
    font-size: 0.875rem;
    color: var(--grey-600);

    span {
      font-weight: 600;
      color: var(--grey-800);
    }
  }

  .export-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--white);
    background: var(--primary-500);
    padding: 0.5rem 1rem;
    font-weight: 500;
    transition: var(--transition);

    &:hover {
      background: var(--primary-600);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background: var(--grey-400);
    }
  }
`;

export default Wrapper;

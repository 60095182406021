import styled from "styled-components";

const Wrapper = styled.section`
  background: var(--white);
  border-radius: 10px;
  box-shadow: var(--shadow-2);
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--shadow-3);
  }

  .provider-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-200);
    padding-bottom: 0.5rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
    }

    .provider-contact {
      display: flex;
      align-items: center;

      gap: 0.5rem;

      a {
        color: var(--primary-500);
        transition: color 0.3s;

        &:hover {
          color: var(--primary-700);
        }
      }
    }
  }
  .title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between title and edit button */
  }

  .provider-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two-column layout */
    gap: 0.25rem;
    margin-top: 0.75rem;

    p {
      margin: 0.25rem 0;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-size: 0.9rem;
    }

    .highlighted {
      color: var(--primary-600);
      font-weight: 600;
    }
  }

  .delivery-days {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.75rem;

    .day {
      padding: 0.4rem 0.6rem;
      border: 1px solid var(--grey-300);
      border-radius: 5px;
      background: var(--grey-100);
      transition: background 0.3s, color 0.3s;
      cursor: pointer;
    }

    .day.selected {
      background: var(--primary-500);
      color: white;
    }
  }

  .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    background: var(--green-light);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin: 0.5rem;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:hover {
      background: var(--green-dark);
    }
  }

  @media (max-width: 992px) {
    .provider-details {
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 1rem;

    .provider-details {
      grid-template-columns: 1fr; /* Single column for mobile */
    }

    .actions {
      justify-content: center;
    }
  }

  /* Responsive for smaller screens */
  @media (max-width: 600px) {
    .provider-details {
      grid-template-columns: 1fr; /* Single column */
    }
  }
  @media (max-width: 480px) {
    .provider-header {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
    }

    .title-container {
      display: flex;
      justify-content: space-between; /* Space out title and button */
      flex-wrap: wrap; /* Allow wrapping if needed */
      gap: 0.25rem; /* Smaller gap on mobile */
    }

    .provider-contact {
      margin-top: 0.5rem; /* Add spacing below the title */
      flex-wrap: wrap; /* Ensure phone and link don't overflow */
    }

    h2 {
      font-size: 1.25rem; /* Slightly smaller font for mobile */
      word-break: break-word; /* Break long words if needed */
      max-width: 85%; /* Prevent title from taking too much space */
    }

    .edit-btn {
      flex-shrink: 0; /* Prevent the button from shrinking */
    }
  }
`;

export default Wrapper;

import React from "react";
import { useState, useMemo, useEffect, useLayoutEffect, useRef } from "react";
import { useAppContext } from "../context/appContext.js";
import Wrapper from "../assets/wrappers/ExportSheets.js";

import { exportToExcel } from "react-json-to-excel";
import { FaTimes, FaFileExport, FaFileAlt, FaTable } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import FormRowSelect from "./FormRowSelect.js";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
import PermissionGuard from "./PermissionGuard";

const ExportData = ({ customItems }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    isExportingData,
    orders,
    exportingOrderId,
    exportContext,
    itemTypes,
    handleChange,
    searchProvider,
    toggleExportData,
    searchKitchen,
  } = useAppContext();
  const location = useLocation();

  const [exportName, setExportName] = useState("ExportData");
  const [exportFormat, setExportFormat] = useState("pdf");
  const [isExportingPrice, setExportPrice] = useState(true);
  const [isExportingSupplier, setExportSupplier] = useState(true);
  const [isExportingCode, setExportCode] = useState(true);
  const [isExportingType, setExportType] = useState(true);
  const [isExportingTotal, setExportTotal] = useState(true);
  const [isExportingProportions, setExportingProportions] = useState(true);
  const [isExportingAmount, setExportingAmount] = useState(true);
  const [exportAllItems, setExportAllItems] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const determineVisibility = () => {
      if (!isExportingData) return false;
      if (
        exportContext === "customItems" &&
        location.pathname.includes("/stock")
      ) {
        return true;
      } else if (
        exportContext === "orders" &&
        location.pathname.includes("/orders")
      ) {
        return true;
      }
      toggleExportData();
      return false;
    };

    setVisible(determineVisibility());
  }, [isExportingData, exportContext, location.pathname]);

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString("en-CA");
    if (exportContext === "orders") {
      const order = orders.find((order) => order._id === exportingOrderId);
      if (order) {
        setExportName(`${order.orderName} - ${currentDate}`);
      }
    } else if (exportContext === "customItems") {
      let name = "";
      if (searchKitchen && searchKitchen !== "all") {
        name += `${searchKitchen} - `;
      }
      if (searchProvider && searchProvider !== "all") {
        name += `${searchProvider} - `;
      }
      name += currentDate;
      setExportName(name);
    }
  }, [exportContext, orders, exportingOrderId, searchProvider, searchKitchen]);

  const dataToExport = useMemo(() => {
    if (exportContext === "orders") {
      return (
        orders.find((order) => order._id === exportingOrderId)?.items || []
      );
    } else if (exportContext === "customItems") {
      return customItems || [];
    }
    return [];
  }, [exportContext, orders, exportingOrderId, customItems]);

  const preparedData = useMemo(() => {
    const filteredItems = exportAllItems
      ? dataToExport
      : dataToExport.filter((item) => item.itemQuantity > 0);

    return filteredItems.map((item) => {
      const itemName = item.itemName;

      let itemType;
      const itemTypeObject = itemTypes.find(
        (type) => type._id === item.itemType
      );

      if (itemTypeObject) {
        itemType = itemTypeObject.typeName;
      } else {
        itemType = item.itemType;
      }

      let itemProportions = item.itemProportions || "";
      if (isExportingProportions && item.itemProportionsAmount > 0) {
        itemProportions += `x${item.itemProportionsAmount}`;
        if (
          item.itemProportionsAmountType &&
          item.itemProportionsAmountType !== "Other"
        ) {
          itemProportions += item.itemProportionsAmountType;
        }
      }

      let itemAmountDescription = "";
      if (isExportingAmount && item.itemAmount > 0) {
        itemAmountDescription = `${item.itemAmount}${item.itemAmountType}`;
      }

      let total = undefined;
      if (isExportingTotal && item.price && item.itemQuantity) {
        // Check if price is a number before calculating
        const numericPrice =
          typeof item.price === "number"
            ? item.price
            : typeof item.price === "string" && !isNaN(parseFloat(item.price))
            ? parseFloat(item.price)
            : 0;

        total = (numericPrice * item.itemQuantity).toFixed(2);
      }

      // For item price, only call toFixed if it's a number
      let formattedPrice = undefined;
      if (isExportingPrice && item.price !== undefined) {
        formattedPrice =
          typeof item.price === "number" ? item.price.toFixed(2) : item.price; // If it's a string (like '***'), keep it as is
      }

      return {
        itemName,
        itemProportions: itemProportions,
        itemAmount: itemAmountDescription,
        supplier: isExportingSupplier ? item.itemSupplier : undefined,
        itemCode: isExportingCode ? item.itemCode : undefined,
        price: formattedPrice, // Use the new safely formatted price
        quantity: item.itemQuantity,
        itemType,
        total,
      };
    });
  }, [
    dataToExport,
    exportAllItems,
    isExportingProportions,
    isExportingAmount,
    isExportingTotal,
    isExportingSupplier,
    isExportingCode,
    isExportingPrice,
    itemTypes,
  ]);

  const handleExport = () => {
    if (exportFormat === "pdf") {
      handleExportPDF(preparedData, exportName);
    } else if (exportFormat === "sheets") {
      exportToExcel(preparedData, exportName);
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const columns = [
      { title: "Item Name", dataKey: "itemName" },
      isExportingProportions && {
        title: "Proportions",
        dataKey: "itemProportions",
      },
      isExportingAmount && { title: "Amount", dataKey: "itemAmount" },
      isExportingSupplier && { title: "Supplier", dataKey: "supplier" },
      isExportingCode && { title: "Code", dataKey: "itemCode" },
      isExportingPrice && { title: "Price", dataKey: "price" },
      { title: "Quantity", dataKey: "quantity" },
      isExportingType && { title: "Item Type", dataKey: "itemType" },
      isExportingTotal && { title: "Total", dataKey: "total" },
    ].filter(Boolean);

    const tableData = preparedData.map((item) => ({
      itemName: item.itemName,
      itemType: item.itemType,
      supplier: item.supplier,
      itemCode: item.itemCode,
      price: item.price
        ? typeof item.price === "number"
          ? `$${item.price}`
          : item.price
        : "",
      quantity: item.quantity,
      total: item.total ? `$${item.total}` : "",
      itemProportions: item.itemProportions,
      itemAmount: item.itemAmount,
    }));

    doc.autoTable(columns, tableData, { startY: 20 });

    doc.save(`${exportName}.pdf`);
  };

  const handleExportFormatChange = (e) => {
    setExportFormat(e.target.value);
  };

  // Optional: Add animation for selection, if you want a nice touch
  const handleFormatSelection = (format) => {
    setExportFormat(format);

    // Optional animation with GSAP
    if (gsap) {
      gsap.to(`.format-option.${format}`, {
        scale: 1.05,
        duration: 0.2,
        yoyo: true,
        repeat: 1,
      });
    }
  };

  const handleExportClose = () => {
    if (isLoading) return;
    handleChange({ name: "isExportingData", value: !isExportingData });
  };

  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 25);
    }

    const tl = gsap.timeline();

    if (visible) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "1rem 1rem 0 1rem",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [visible, contentHeight]);

  if (!isVisible && !visible) return null;

  // Get title based on context
  const getExportTitle = () => {
    if (exportContext === "orders") {
      const order = orders.find((order) => order._id === exportingOrderId);
      return t("Export Order") + (order ? `: ${order.providerName}` : "");
    } else {
      return t("Export Custom Items");
    }
  };

  return (
    <Wrapper>
      <div ref={contentRef} className="export-container">
        <div className="export-header">
          <h4 className="export-title">{getExportTitle()}</h4>
          <button
            className="btn delete-btn close-export"
            onClick={handleExportClose}
            title={t("Close")}
          >
            <FaTimes />
          </button>
        </div>

        <div className="export-name-section">
          <div className="form-label">{t("Export File Name")}:</div>
          <input
            type="text"
            placeholder={t("Enter export name")}
            value={exportName}
            onChange={(e) => setExportName(e.target.value)}
            className="export-name-input"
          />
        </div>

        <div className="export-section">
          <h5 className="section-title">{t("Export Options")}</h5>

          {/* Format selection with icons */}
          <div className="export-format-selection">
            <div className="format-option-label">{t("Format")}:</div>
            <div className="format-options">
              <div
                className={`format-option ${
                  exportFormat === "pdf" ? "selected" : ""
                }`}
                onClick={() => handleFormatSelection("pdf")}
              >
                <FaFileAlt /> PDF
              </div>
              <div
                className={`format-option ${
                  exportFormat === "sheets" ? "selected" : ""
                }`}
                onClick={() => handleFormatSelection("sheets")}
              >
                <FaTable /> Excel
              </div>
            </div>
          </div>

          {/* Only show when exporting custom items */}
          {exportContext === "customItems" && (
            <div className="item-filtering-section">
              <h5 className="section-subtitle">{t("Items to Include")}</h5>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={exportAllItems}
                  onChange={() => setExportAllItems(!exportAllItems)}
                />
                <span>{t("Include items with zero quantity")}</span>
              </label>
            </div>
          )}

          <h5 className="section-subtitle">{t("Columns to Include")}</h5>
          <div className="export-checks-container">
            {[
              {
                label: "Name",
                state: true, // Always include name
                setState: () => {}, // Cannot disable name
                disabled: true,
                icon: null,
              },
              {
                label: "Quantity",
                state: true, // Always include quantity
                setState: () => {}, // Cannot disable quantity
                disabled: true,
                icon: null,
              },
              {
                label: "Proportions",
                state: isExportingProportions,
                setState: setExportingProportions,
                icon: null,
              },
              {
                label: "Amount",
                state: isExportingAmount,
                setState: setExportingAmount,
                icon: null,
              },
              {
                label: "Supplier",
                state: isExportingSupplier,
                setState: setExportSupplier,
                icon: null,
              },
              {
                label: "Code",
                state: isExportingCode,
                setState: setExportCode,
                icon: null,
              },
              {
                label: "Price",
                state: isExportingPrice,
                setState: setExportPrice,
                icon: null,
                needsPermission: "viewItemPrices", // Add this property
              },
              {
                label: "Type",
                state: isExportingType,
                setState: setExportType,
                icon: null,
              },
              {
                label: "Total",
                state: isExportingTotal,
                setState: setExportTotal,
                icon: null,
                needsPermission: "viewItemPrices", // Add this property
              },
            ].map(
              ({ label, state, setState, disabled, icon, needsPermission }) => {
                // If the checkbox needs a permission, wrap it in PermissionGuard
                const checkboxContent = (
                  <div
                    key={label}
                    className={`check-option ${disabled ? "disabled" : ""}`}
                    onClick={(e) => {
                      if (e.target === e.currentTarget && !disabled) {
                        setState(!state);
                      }
                    }}
                  >
                    <label
                      className="checkbox-label"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input
                        type="checkbox"
                        checked={state}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (!disabled) {
                            setState(!state);
                          }
                        }}
                        disabled={disabled}
                      />
                      <span>{t(label)}</span>
                    </label>
                  </div>
                );

                // If this option needs permission, wrap it in PermissionGuard
                if (needsPermission) {
                  return (
                    <PermissionGuard
                      key={label}
                      permission={needsPermission}
                      fallback={null}
                    >
                      {checkboxContent}
                    </PermissionGuard>
                  );
                }

                // Otherwise return it directly
                return checkboxContent;
              }
            )}
          </div>
        </div>

        <div className="export-confirm-container">
          <div className="item-count">
            {exportContext === "orders" ? (
              <span>
                {t("Export order with {{count}} items", {
                  count: preparedData.length,
                })}
              </span>
            ) : exportAllItems ? (
              <span>
                {t("Export all {{count}} items", {
                  count: preparedData.length,
                })}
              </span>
            ) : (
              <span>
                {t("Export {{count}} items", {
                  count: preparedData.length,
                })}
              </span>
            )}
          </div>
          <button
            className="btn export-button"
            onClick={() => handleExport()}
            disabled={preparedData.length === 0}
          >
            <FaFileExport /> {t("Export")}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default ExportData;

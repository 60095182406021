import styled from "styled-components";

const Wrapper = styled.section`
  h3 {
    margin-top: 0;
  }
  .reset-btn {
    font-size: 1.15rem;

    height: 3rem;

    margin-top: 1rem;
  }
  .form {
    border-radius: 10px;
    width: 100%;
    background: var(--white);
    padding: 1rem 1rem 1rem;
    box-shadow: var(--shadow-2);
    margin: 0;

    box-shadow: var(--shadow-2);

    max-width: 100%;
    width: 100%;
  }

  .profile-location {
    border-radius: 10px;
    width: 100%;
    background: var(--white);
    padding: 0.5rem 1rem 0.75rem;
    box-shadow: var(--shadow-2);
    margin-top: 1rem;

    box-shadow: var(--shadow-2);
    @media (min-width: 768px) {
      padding: 1rem 1rem 1rem;
    }
    h3 {
      margin-bottom: 0.25rem;
    }
  }
  .profile-location-add {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .form-div {
  }

  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    row-gap: 0.5rem;
  }
  .form-center button {
    align-self: end;
    height: 35px;
    margin-top: 1rem;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;

    button {
      height: 35px;
    }
  }
  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 1rem;
    }
    .btn-container {
      margin-top: 0;
    }
  }
  @media (min-width: 1120px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .form-center button {
      margin-top: 0;
    }
  }

  .subscription-section {
    position: relative;
  }

  .section-header {
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--grey-100);
    padding-bottom: 0.5rem;
  }

  .section-header h4 {
    margin: 0;
    color: var(--primary-500);
  }

  .subscription-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .subscription-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .subscription-status {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  .status-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    min-width: 100px;
  }

  .status-label {
    font-size: 0.85rem;
    color: var(--grey-500);
    font-weight: 500;
  }

  .status-value {
    font-weight: 600;
    font-size: 1.1rem;
  }

  .status-active {
    color: var(--green-dark);
  }

  .status-inactive,
  .status-canceled,
  .status-incomplete {
    color: var(--red-dark);
  }

  .status-pending {
    color: var(--yellow-dark);
  }

  .plan-selection {
    flex: 1;
  }

  .plan-selection h5 {
    margin: 0 0 0.75rem 0;
    font-size: 1rem;
    color: var(--grey-700);
  }

  .plan-options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .plan-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.6rem 0.75rem;
    border: 1px solid var(--grey-200);
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .plan-option:hover {
    background-color: var(--primary-50);
    border-color: var(--primary-300);
  }

  .plan-option.selected {
    background-color: var(--primary-50);
    border-color: var(--primary-500);
  }

  .plan-radio {
    accent-color: var(--primary-500);
    width: 1rem;
    height: 1rem;
  }

  .plan-label {
    font-weight: 500;
    cursor: pointer;
    flex: 1;
  }

  .subscription-actions {
    display: flex;
    margin-top: 0.75rem;
  }

  .subscription-btn {
    padding: 0.4rem 0.75rem;
    margin-top: 0.5rem;

    font-size: 0.9rem;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    .subscription-details {
      flex-direction: row;
      align-items: center;
    }

    .subscription-info {
      flex-direction: column;
    }

    .subscription-actions {
      margin-top: 0;
    }
  }

  @media (min-width: 992px) {
    .plan-options {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.75rem;
    }

    .plan-option {
      flex: 1 1 200px;
      max-width: 300px;
    }

    .subscription-actions {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export default Wrapper;

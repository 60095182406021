import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
} from "react";
import { useAppContext } from "../context/appContext";
import Wrapper from "../assets/wrappers/AutoOrder";
import { gsap } from "gsap";
import { FaRobot, FaExclamationTriangle, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AutoOrder = ({ filteredItems }) => {
  const { t } = useTranslation();
  const {
    isAutoOrdering,
    autoOrderItemQuantities,
    updateItemQuantity,
    toggleAutoOrder,
  } = useAppContext();
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  const { typeMismatchItems, orderItems, noUpdateNeededItems } = useMemo(() => {
    const typeMismatchItems = [];
    const orderItems = [];
    const noUpdateNeededItems = [];

    filteredItems.forEach((item) => {
      const {
        _id,
        itemName,
        itemQuantity,
        itemNeededType,
        itemLeftToOrderType,
        selectedInventoryType,
        itemType,
        itemProportionsType,
        itemProportions,
        itemNeeded,
        itemLeftToOrder,
        itemInventoryQuantity,
      } = item;

      if (
        ![itemType, itemProportionsType].includes(itemNeededType) ||
        (itemLeftToOrder &&
          ![itemType, itemProportionsType].includes(itemLeftToOrderType))
      ) {
        typeMismatchItems.push({ itemName, reason: "Mismatched Types inputs" });
        return;
      }

      const proportion = parseInt(itemProportions, 10);
      const proportionInverse = 1 / proportion;
      const getProportionalValue = (type, amount) =>
        parseFloat(amount) *
        (type === itemProportionsType ? proportionInverse : 1);

      const neededValue = getProportionalValue(itemNeededType, itemNeeded);
      const leftToOrderValue = itemLeftToOrder
        ? getProportionalValue(itemLeftToOrderType, itemLeftToOrder)
        : Infinity;
      const inventoryQuantityValue = getProportionalValue(
        selectedInventoryType,
        itemInventoryQuantity
      );

      if (
        inventoryQuantityValue < neededValue &&
        (itemLeftToOrder === null || inventoryQuantityValue < leftToOrderValue)
      ) {
        const orderQuantity = Math.ceil(neededValue - inventoryQuantityValue);
        if (itemQuantity === orderQuantity) {
          noUpdateNeededItems.push({ _id, itemName, orderQuantity });
        } else {
          orderItems.push({ _id, itemName, orderQuantity });
        }
      }
    });

    return { typeMismatchItems, orderItems, noUpdateNeededItems };
  }, [filteredItems]);

  const handleAutoOrderItemQuantities = () => {
    if (orderItems.length > 0) {
      orderItems.forEach((item) => {
        updateItemQuantity(item, item.orderQuantity);
      });
      toggleAutoOrder();
    }
  };

  //ANIMATIONS
  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 25);
    }

    const tl = gsap.timeline();

    if (isAutoOrdering) {
      setIsVisible(true);
      tl.set(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
      }).to(contentRef.current, {
        height: contentHeight,
        padding: "0.75rem",
        paddingBottom: "0",
        autoAlpha: 1,
        duration: 0.5,
      });
    } else if (contentRef.current) {
      tl.to(contentRef.current, {
        height: 0,
        padding: 0,
        autoAlpha: 0,
        duration: 0.5,
      }).then(() => setIsVisible(false));
    }

    return () => tl.kill();
  }, [isAutoOrdering, contentHeight]);

  if (!isVisible && !isAutoOrdering) {
    return null;
  }

  return (
    <Wrapper>
      <div ref={contentRef} className="auto-order-container">
        <div className="auto-order-header">
          <h4 className="auto-order-title">{t("Auto Order Quantities")}</h4>
        </div>

        <div className="stats-container">
          <div className="stat-item info">
            <span className="stat-count">{orderItems.length}</span>
            <span className="stat-label">{t("items to update")}</span>
          </div>

          {typeMismatchItems.length > 0 && (
            <div className="stat-item warning">
              <FaExclamationTriangle
                style={{ marginRight: "5px", color: "var(--red-dark)" }}
              />
              <span className="stat-count">{typeMismatchItems.length}</span>
              <span className="stat-label">{t("invalid items")}</span>
            </div>
          )}

          {noUpdateNeededItems.length > 0 && (
            <div className="stat-item success">
              <FaCheckCircle
                style={{ marginRight: "5px", color: "var(--green-dark)" }}
              />
              <span className="stat-count">{noUpdateNeededItems.length}</span>
              <span className="stat-label">{t("already set")}</span>
            </div>
          )}

          <div className="action-container">
            <button
              className="btn auto-order-btn"
              onClick={handleAutoOrderItemQuantities}
              disabled={orderItems.length === 0}
            >
              <FaRobot /> {t("Auto-Order")}
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AutoOrder;

import styled from "styled-components";

const Wrapper = styled.aside`
  margin-top: 0.75rem;
  border-radius: 10px;
  background: var(--white);
  box-shadow: var(--shadow-2);
  overflow: hidden;

  .edit-view-header {
    padding: 1rem 1.25rem;

    border-bottom: 1px solid var(--grey-100);
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .title-icon {
    color: var(--primary-500);
    font-size: 1.2rem;
  }

  .view-title {
    margin: 0;
    color: var(--primary-600);
    font-size: 1.1rem;
    font-weight: 500;
  }

  .edit-view-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.75rem 1.25rem 1.25rem;
  }

  .view-option-group {
    margin-bottom: 1.25rem;

    &:last-child {
      margin-bottom: 1.5rem;
    }
  }

  .group-title {
    font-size: 0.9rem;
    color: var(--grey-600);
    margin: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  .export-checks-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  .check-option {
    background-color: var(--white);
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    transition: var(--transition);

    &:hover:not(.disabled) {
      border-color: var(--primary-300);
      background-color: var(--primary-50);
    }

    &.disabled {
      opacity: 0.7;
      background-color: var(--grey-100);
    }
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    input[type="checkbox"] {
      margin: 0;
      cursor: pointer;
      accent-color: var(--primary-500);
    }

    span {
      color: var(--grey-800);
      font-size: 0.9rem;
    }
  }

  .view-actions {
    display: flex;
    justify-content: flex-end;

    padding-top: 0.75rem;
  }

  .reset-button {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 0.5rem;
    height: 2rem;
    background-color: var(--grey-500);
    color: var(--white);
    font-weight: 500;
    padding: 0.375rem 0.75rem;

    &:hover {
      background-color: var(--grey-700);
    }
  }
`;

export default Wrapper;

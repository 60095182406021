import { IoBarChartSharp } from "react-icons/io5";
import { MdQueryStats } from "react-icons/md";
import { FaWpforms, FaIdCard, FaListOl, FaTrashAlt } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { TfiHelpAlt } from "react-icons/tfi";

const links = [
  {
    id: 1,
    text: "Dashboard",
    path: "/",
    icon: <IoBarChartSharp />,
    permission: "viewDashboard",
  },
  {
    id: 2,
    text: "Orders",
    path: "/orders",
    icon: <FaListOl />,
    permission: "viewOrders",
  },
  {
    id: 3,
    text: "Stock",
    path: "stock",
    icon: <MdQueryStats />,
    permission: "viewItems",
  },
  {
    id: 4,
    text: "Providers",
    path: "provider",
    icon: <FaIdCard />,
    permission: null,
  },
  {
    id: 5,
    text: "Menus",
    path: "menu",
    icon: <FaWpforms />,
    permission: "viewMenus",
  },
  {
    id: 6,
    text: "Trash",
    path: "trash",
    icon: <FaTrashAlt />,
    permission: "editItems",
  },
  {
    id: 7,
    text: "Settings",
    path: "profile",
    icon: <ImProfile />,
    permission: null,
  },
  {
    id: 8,
    text: "Support",
    path: "support",
    icon: <TfiHelpAlt />,
    permission: null,
  },
];

export default links;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormRow, Alert, Logo } from "../components";

import Wrapper from "../assets/wrappers/RegisterPage";
import { useAppContext } from "../context/appContext";
import { useTranslation } from "react-i18next";

const initialState = {
  name: "",
  email: "",
  password: "",
  isMember: true,
  showAlert: false,
};

function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { user, isLoading, showAlert, displayAlert, setupUser } =
    useAppContext();
  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember } = values;
    if (!email || !password || (!isMember && !name)) {
      displayAlert();
      return;
    }
    const currentUser = { name, email, password };
    if (isMember) {
      setupUser({
        currentUser,
        endPoint: "login",
        alertText: "Login Successful! Redirecting...",
      });
    } else {
      setupUser({
        currentUser,
        endPoint: "register",
        alertText: "User Created! Redirecting...",
      });
    }
  };

  const loginDemo = () => {
    const currentUser = {
      name: "demo",
      email: "demo@demo.demo",
      password: "demodemo",
    };
    setupUser({
      currentUser,
      endPoint: "login",
      alertText: "Login Successful! Redirecting...",
    });
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/stock");
      }, 2500);
    }
  }, [user, navigate]);

  return (
    <Wrapper>
      <div className="login-container">
        <div className="login-logo">
          <Logo />
        </div>
        <form className="form" onSubmit={onSubmit}>
          {/*<Logo />*/}
          <h3 style={{ fontWeight: "500", textAlign: "left" }}>
            {t(values.isMember ? "Login" : "Register")}
          </h3>
          {showAlert && <Alert />}
          {/* name input */}
          {!values.isMember && (
            <FormRow
              type="text"
              name="name"
              value={values.name}
              handleChange={handleChange}
              labelText={t("Name")}
              placeholder={t("Name")}
            />
          )}
          {/* email input */}
          <FormRow
            type="email"
            name="email"
            value={values.email}
            handleChange={handleChange}
            labelText={t("Email")}
            placeholder={t("Email")}
          />
          {/* password input */}
          <FormRow
            type="password"
            name="password"
            value={values.password}
            handleChange={handleChange}
            labelText={t("Password")}
            placeholder={t("Password")}
          />
          <button type="submit" className="btn btn-block" disabled={isLoading}>
            {t("Login Button")}
          </button>
          {/* <button type="button" onClick={loginDemo} className="member-btn">
            demo
          </button> */}
          {/* <p>
            Contact
            <a
              href="https://felixsp.com"
              target="_blank"
              className="member-btn"
            >
              {` Félix `}
            </a>
            for more info
          </p> */}

          {/* <p>
            {values.isMember ? "Not a member yet?" : "Already a member?"}
            <button type="button" onClick={toggleMember} className="member-btn">
              {values.isMember ? "Register" : "Login"}
            </button>
          </p> */}
        </form>
      </div>
    </Wrapper>
  );
}

export default Register;

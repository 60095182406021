import React, { useState, useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import Wrapper from "../../assets/wrappers/Item";
import { useTranslation } from "react-i18next";

const DeletedOrder = ({ order }) => {
  const { t } = useTranslation();
  const { restoreItem, deleteOrder } = useAppContext();
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  useEffect(() => {
    let timeout;
    if (isConfirmingDelete) {
      timeout = setTimeout(() => {
        setIsConfirmingDelete(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isConfirmingDelete]);

  const handleDeleteClick = () => {
    if (isConfirmingDelete) {
      deleteOrder(order._id);
    } else {
      setIsConfirmingDelete(true);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <Wrapper>
      <div className="deleted-item">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <h4>{order.orderName}</h4>
          <div style={{ display: "flex", marginTop: "0.25rem" }}>
            {order.totalCost && (
              <h5>
                {t("Total Cost")}: ${order.totalCost.toFixed(2)}
              </h5>
            )}
            {order.items && (
              <p>
                {t("Number of Items")}: {order.items.length}
              </p>
            )}
          </div>
        </div>
        <div className="deleted-actions">
          <h6>
            {t("Deleted")}: {formatDate(order.deletedAt)}
          </h6>
          <button
            className="btn"
            onClick={() => restoreItem("order", order._id)}
          >
            {t("Restore")}
          </button>
          <button
            style={{ marginLeft: "0.5rem" }}
            className="btn delete-btn"
            onClick={handleDeleteClick}
          >
            {isConfirmingDelete ? t("Confirm Delete") : t("Delete Permanently")}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default DeletedOrder;

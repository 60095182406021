import styled from "styled-components";

const Wrapper = styled.section`
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--shadow-2);
  padding: 1.25rem;
  margin-top: 1.5rem;

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid var(--grey-100);
    padding-bottom: 0.75rem;
  }

  .section-title {
    margin: 0;

    color: var(--primary-500);
  }

  .add-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.4rem 0.75rem;
    font-size: 0.9rem;
    background-color: var(--primary-500);
    border-radius: 4px;
  }

  .empty-state {
    background-color: var(--grey-50);
    border-radius: 8px;
    padding: 1.25rem;
    text-align: center;
  }

  .accounts-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .account-card {
    border: 1px solid var(--grey-200);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;

    &:hover {
      border-color: var(--primary-200);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .account-info {
    padding: 0.75rem;
  }

  .account-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }

  .account-identity {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .account-icon {
    color: var(--primary-500);
    font-size: 1.5rem;
    background: var(--primary-50);
    padding: 0.35rem;
    border-radius: 50%;
  }

  h5 {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
  }

  .account-email {
    color: var(--grey-500);
    font-size: 0.85rem;
    margin-top: 0.15rem;
  }
  .account-language {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.8rem;
    color: var(--black);
    background-color: var(--primary-200);
    padding: 0.15rem 0.5rem;
    border-radius: 10px;

    svg {
      font-size: 0.9rem;
    }
  }

  .account-actions {
    display: flex;
    gap: 0.5rem;
  }

  .icon-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
      background: var(--grey-100);
    }
  }

  .edit-btn {
    color: var(--primary-500);
  }

  .delete-btn {
    color: var(--red-dark);
  }

  .account-permissions {
    background-color: var(--grey-50);
    border-radius: 6px;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  .permission-category {
    flex: 1;
    min-width: 160px;
  }

  .category-name {
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--grey-600);
    margin-bottom: 0.35rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .permission-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.35rem;
  }

  .permission-pill {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    gap: 0.25rem;

    &.active {
      background-color: var(--primary-200);
      color: var(--primary-700);
    }

    &.inactive {
      background-color: var(--grey-200);
      color: var(--grey-600);
    }
  }

  .pill-icon {
    font-size: 0.65rem;
  }

  /* Form Styling */
  .account-form {
    border-radius: 8px;
    padding: 1.25rem;
    margin-top: 1rem;
    border: 1px solid var(--grey-200);
  }

  .form-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: var(--grey-800);
  }

  /* Updated form layout */
  .account-details {
    margin-bottom: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;

    .form-row {
      flex: 1 1 250px;
      min-width: 250px;
      margin-bottom: 0.75rem;
    }
  }

  .permissions-panel {
    background-color: var(--grey-50);
    border-radius: 6px;
    padding: 1.25rem;
    border: 1px solid var(--grey-200);
  }

  .permissions-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: var(--primary-500);
    text-align: center;
    font-weight: 600;
  }

  .permissions-grid {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .permission-group {
    background-color: var(--white);
    border-radius: 8px;
    padding: 0.75rem;
    border: 1px dashed var(--grey-200);
  }

  .group-title {
    margin: 0;
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
    color: var(--grey-700);
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--grey-100);
  }

  .permission-options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .permission-toggle {
    position: relative;
    display: flex;
    align-items: center;
  }

  .toggle-input {
    opacity: 0;
    position: absolute;

    &:checked + .toggle-label .toggle-switch {
      background-color: var(--primary-500);

      &:before {
        transform: translateX(16px);
      }
    }
  }

  .toggle-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin: 0;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    background-color: var(--grey-300);
    border-radius: 20px;
    transition: all 0.3s;

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
      top: 2px;
      left: 2px;
      transition: all 0.3s;
    }
  }

  .toggle-text {
    font-size: 0.85rem;
    color: var(--grey-700);
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .btn-secondary {
    background-color: var(--grey-300);
    color: var(--grey-700);

    &:hover {
      background-color: var(--grey-400);
    }
  }

  .btn-primary {
    background-color: var(--primary-500);

    &:hover:not(:disabled) {
      background-color: var(--primary-600);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .permissions-grid {
      grid-template-columns: 1fr;
    }
  }

  // Add these styles to your wrapper:
  .dependency-note {
    font-size: 0.7rem;
    font-style: italic;
    color: var(--grey-500);
    margin-left: 0.3rem;
  }

  .toggle-input:disabled + .toggle-label {
    opacity: 0.8;
    cursor: not-allowed;
  }

  .toggle-input:checked:disabled + .toggle-label .toggle-switch {
    background-color: var(--primary-300);
  }

  /* Delete Confirmation Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .delete-confirmation-modal {
    background-color: var(--white);
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: var(--shadow-3);
    overflow: hidden;
    animation: slideIn 0.3s ease-out forwards;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .modal-header {
    background-color: var(--red-light);
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .modal-header h4 {
    color: var(--white);
    margin: 0;
    font-weight: 500;
  }

  .warning-icon {
    color: var(--white);
    font-size: 1.5rem;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .modal-body p {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    color: var(--grey-700);
  }

  .modal-body strong {
    color: var(--grey-900);
    font-weight: 600;
  }

  .warning-text {
    color: var(--grey-600);
    font-style: italic;
    margin-top: 1rem;
    font-size: 0.9rem;
    background-color: var(--red-50);
    border-radius: 4px;
    padding: 0.75rem;
  }

  .modal-actions {
    padding: 1rem;
    background-color: var(--grey-50);
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    border-top: 1px solid var(--grey-100);
  }

  .btn-danger {
    background-color: var(--red-light);
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .btn-danger:hover {
    background-color: var(--red-dark);
    color: white;
  }

  /* Media query for mobile responsiveness */
  @media (max-width: 576px) {
    .delete-confirmation-modal {
      width: 95%;
      max-width: none;
      margin: 0 10px;
    }

    .modal-body {
      padding: 1rem;
    }

    .modal-actions {
      flex-direction: column-reverse;
      gap: 0.5rem;
    }

    .modal-actions button {
      width: 100%;
      justify-content: center;
    }
  }

  // Add these styles to your Wrapper

  .title-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .accounts-limit {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.25rem 0.5rem;
    background-color: var(--primary-200);
    border-radius: 20px;
    font-size: 0.8rem;
    color: var(--black);
  }

  .info-icon {
    font-size: 0.85rem;
  }

  .limit-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--yellow-light);
    color: var(--yellow-dark);
    padding: 0.75rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    border: 1px solid var(--yellow-dark);
  }

  .limit-message p {
    margin: 0;
    font-size: 0.9rem;
  }

  .limit-message .warning-icon {
    color: var(--yellow-dark);
    font-size: 1rem;
  }

  .add-btn:disabled {
    background-color: var(--grey-300);
    cursor: not-allowed;
    opacity: 0.7;
  }

  .add-btn:disabled:hover {
    background-color: var(--grey-300);
  }
`;
export default Wrapper;

import { FaTimes } from "react-icons/fa";
import { FormRow, FormRowSelect } from "..";
import { useTranslation } from "react-i18next";

const IngredientList = ({
  menuItemIngredients,
  items,
  alternativePairs,
  providers,
  defaultIngredientAmountTypeOptions,
  currentDisplayValues,
  activeIngredientIndex,
  setActiveIngredientIndex,
  filteredMenuIngredients,
  updateIngredientField,
  handleSearchChange,
  handleIngredientChange,
  handleSelectIngredient,
  removeIngredientField,
  calculateIngredientPrice,
  isItemValid,
  isBaseItemTypeLocked,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {menuItemIngredients.filter((ing) => !ing.isMenuItem).length > 0 && (
        <h5 style={{ margin: "0" }}>{t("Item Ingredients:")}</h5>
      )}
      {menuItemIngredients
        .filter((ing) => ing.isMenuItem === false)
        .map((ingredient, index) => {
          const selectedItem = items.find(
            (item) => item._id === ingredient.item
          );
          const isOtherType =
            selectedItem && selectedItem.itemAmountType === "Other";
          const ingredientAmountTypeOptions = isOtherType
            ? ["Other"]
            : selectedItem && selectedItem.itemAmountType === "unit"
            ? ["unit"]
            : defaultIngredientAmountTypeOptions;

          return (
            <div key={index} className="ingredient-row">
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    margin: "1rem 1rem 0 0",
                    fontWeight: "600",
                  }}
                >
                  {index + 1}
                </span>
                <div className="search-container">
                  <FormRow
                    type="text"
                    name={`Item ingredient`}
                    labelText={t("Item Ingredient")}
                    value={ingredient.name || ""}
                    className={
                      isItemValid(currentDisplayValues[index])
                        ? "valid"
                        : "invalid"
                    }
                    handleChange={(e) => {
                      updateIngredientField(index, { name: e.target.value });
                      setActiveIngredientIndex(index);
                      handleSearchChange(e);
                    }}
                  />

                  {activeIngredientIndex === index &&
                    filteredMenuIngredients &&
                    filteredMenuIngredients.length > 0 && (
                      <div className="menuIngredients-list">
                        {filteredMenuIngredients.slice(0, 5).map((menuIng) => {
                          const selectedProvider = providers.find(
                            (provider) => provider._id === menuIng.provider
                          );
                          const providerName = selectedProvider
                            ? selectedProvider.providerName
                            : "";

                          const isAlternative = alternativePairs.some(
                            (pair) => pair.alternative._id === menuIng._id
                          );
                          const isMainItem = alternativePairs.some(
                            (pair) => pair.mainItem._id === menuIng._id
                          );

                          const itemClass = isAlternative
                            ? "invalid"
                            : isMainItem
                            ? "valid"
                            : "";

                          return (
                            <div
                              key={menuIng._id}
                              className={`search-item ${itemClass}`}
                              onClick={() =>
                                handleSelectIngredient(menuIng._id, index)
                              }
                            >
                              <span>{menuIng.itemName}</span>
                              {menuIng.itemSupplier && (
                                <span
                                  style={{
                                    color: "var(--primary-400)",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {menuIng.itemSupplier}
                                </span>
                              )}
                              {providerName && (
                                <span
                                  className="provider"
                                  style={{ marginLeft: "8px" }}
                                >
                                  {providerName}
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>
              </div>
              {selectedItem && (
                <div className="item-details">
                  <p>
                    {selectedItem.itemAmount}
                    {selectedItem.itemAmountType} - {selectedItem.price}$
                  </p>
                </div>
              )}
              <div className="ingredient-quantity-row">
                <FormRow
                  type="number"
                  name="Quantity"
                  labelText={t("Quantity")}
                  value={ingredient.ingredientQuantity}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "ingredientQuantity",
                      e.target.value
                    )
                  }
                  className="ingredient-formRow"
                  disabled={isOtherType}
                />
                <FormRowSelect
                  labelText={t("Type")}
                  name="QuantityType"
                  value={ingredient.ingredientQType}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "ingredientQType",
                      e.target.value
                    )
                  }
                  list={ingredientAmountTypeOptions}
                  className="ingredient-formRow"
                  disabled={
                    isBaseItemTypeLocked(ingredient.item) || isOtherType
                  }
                />
                <FormRow
                  type="number"
                  labelText={t("Waste %")}
                  name={`wastePercentage${index}`}
                  value={ingredient.wastePercentage || 0}
                  handleChange={(e) =>
                    handleIngredientChange(
                      index,
                      "wastePercentage",
                      e.target.value
                    )
                  }
                  className="ingredient-formRow"
                  disabled={isOtherType}
                />
                <h5
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    marginLeft: "0.25rem",
                    marginBottom: "0.25rem",
                    width: "auto",
                    maxWidth: "12rem",
                    alignSelf: "end",
                  }}
                >
                  {calculateIngredientPrice(ingredient)}
                </h5>
                <button
                  type="button"
                  className="btn delete-btn"
                  onClick={() => removeIngredientField(index)}
                  style={{
                    width: "2rem",
                    paddingLeft: "0.5rem",
                    alignSelf: "flex-end",
                  }}
                >
                  <FaTimes style={{ width: "1rem" }} />
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default IngredientList;

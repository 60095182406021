import React from "react";
import Wrapper from "../../assets/wrappers/Orders";
import { useAppContext } from "../../context/appContext";
import { BsQuestionCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import PermissionGuard from "../PermissionGuard"; // Add this import

const OrderItemDetail = ({ item, orderId }) => {
  const { t } = useTranslation();
  const { updateOrderItemStatus, updateMissingNumber } = useAppContext();

  const handleStatusChange = (newStatus) => {
    const currentStatus = item.status;
    const statusToSet =
      currentStatus === newStatus ? "not received" : newStatus;
    updateOrderItemStatus(orderId, item._id, statusToSet);
    if (statusToSet === "received" || statusToSet === "not received") {
      updateMissingNumber(orderId, item._id, 0);
    }
  };

  const handleMissingNumberChange = (e) => {
    let newMissingNumber = parseInt(e.target.value, 10);
    newMissingNumber = newMissingNumber < 0 ? 0 : newMissingNumber;
    updateMissingNumber(orderId, item._id, newMissingNumber);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <Wrapper onClick={stopPropagation}>
      <div className="order-item-detail">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <h4>{item.itemQuantity}</h4>
          <h5>{item.itemType}</h5>
          <h2>{item.itemName}</h2>
          <h5 className="itemProportions">
            {item.itemProportions &&
            item.itemProportionsAmount &&
            item.itemProportionsAmountType
              ? `${item.itemProportions}x${item.itemProportionsAmount}${item.itemProportionsAmountType}`
              : item.itemProportions || ""}
          </h5>
          <h6>{item.itemSupplier}</h6>
          <p style={{ marginRight: "0.5rem" }}>
            {item.itemAmount} {item.itemAmountType}
          </p>
          <p>{item.itemCategory}</p>
          <h3>{item.itemCode}</h3>

          {/* Replace the price display with a permission-guarded version */}
          <PermissionGuard permission="viewItemPrices" fallback={null}>
            <p>
              $
              {typeof item.totalItemCost === "number"
                ? item.totalItemCost.toFixed(2)
                : item.totalItemCost}
            </p>
          </PermissionGuard>

          {/* <p>
            Price: {item.price}
          </p> */}
          {/* <p>
            Provider: {item.provider}
          </p> */}
        </div>
        <PermissionGuard permission="editOrders" fallback={null}>
          <div className="order-item-status">
            <label className="order-item-status-container">
              <input
                type="checkbox"
                checked={item.status === "received"}
                onChange={() => handleStatusChange("received")}
              />
              <p>{t("Received")}</p>
            </label>
            <span style={{ margin: "0 0.75rem 0 1rem" }}>|</span>
            {(item.status === "not received" || item.status === "received") && (
              <label className="order-item-status-container">
                <input
                  type="checkbox"
                  onChange={() => handleStatusChange("missing")}
                />
                <p>{t("Missing Items")}</p>
              </label>
            )}
            {(item.status === "backordered" || item.status === "missing") && (
              <div style={{ display: "flex" }}>
                <label className="order-item-status-container">
                  <input
                    type="checkbox"
                    checked={item.status === "backordered"}
                    onChange={() => handleStatusChange("backordered")}
                  />
                  <p>{t("Backordered")}</p>
                </label>
                <label className="order-item-status-container">
                  <input
                    type="checkbox"
                    checked={item.status === "missing"}
                    onChange={() => handleStatusChange("missing")}
                  />
                  <p>{t("Missing")}</p>
                </label>
                <input
                  style={{
                    maxWidth: "3rem",
                    height: "1.5rem",
                    marginTop: "0.25rem",
                  }}
                  type="number"
                  value={item.missingNumber || 0}
                  onChange={handleMissingNumberChange}
                />
                <Tippy content={t("How many items are missing?")}>
                  <div style={{ marginLeft: "-0.5rem" }}>
                    <BsQuestionCircle
                      style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}
                      className="info-icon"
                    />
                  </div>
                </Tippy>
              </div>
            )}
          </div>
        </PermissionGuard>
      </div>
    </Wrapper>
  );
};

export default OrderItemDetail;
